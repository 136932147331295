import { EcSite } from '../apis/type/ec_site_pb'

export enum Environment {
  production,
  development,
  local,
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

// Hostに紐づくConfig
// siteTypeに紐づくconfigはmodels/partner_config.tsで定義する
export interface SiteConfig {
  siteType: EcSite.SiteType
  baseUrlRe: RegExp
  firebaseConfig: FirebaseConfig
}

export type siteType = 'itoyokado' | 'fresta' | 'yakuodo' | 'life'

export interface Config {
  siteConfigMapping: { [domain: string]: SiteConfig }
  apiBaseUrl: string
  environment: Environment
}

export default {} as Config
