// source: type/bundle_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.type.BundleType', null, global);
/**
 * @enum {number}
 */
proto.type.BundleType = {
  BUNDLE_TYPE_UNSPECIFIED: 0,
  BUNDLE_TYPE_BUNDLE: 1,
  BUNDLE_TYPE_MIX_MATCH: 2
};

goog.object.extend(exports, proto.type);
