import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import { ExternalAdminUserServiceClient } from '@apis/stailer/externaladminrpc/External_admin_user_serviceServiceClientPb'
import {
  GetExternalAdminUserRequest,
  GetExternalAdminUserResponse,
} from '@apis/stailer/externaladminrpc/external_admin_user_service_pb'
import * as pb from '@apis/stailer/externaladminrpc/external_admin_user_pb'
import config, { Environment } from 'config'
import firebase from 'firebase'
import { EcSite } from '@apis/type/ec_site_pb'
import firebaseApp from '~/firebase/defaultApp'
import { User, UserRole } from '~/types/user'
import { getPartnerConfig, PartnerConfig } from '~/models/partner_config'
import { LaunchDarklyPlugin } from './launchDarkly'
import { LDContext } from 'launchdarkly-js-client-sdk'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

type SessionPlugin = {
  isSignedIn: boolean
  signedInUser: User | null
  updateStatus: () => Promise<void>
  clearStatus: () => Promise<void>
  selectShop: (shopId: string) => Promise<void>
  roleIncludedIn: (roles: UserRole[]) => boolean
  changedChannel: BroadcastChannel
  getFeatureFlag: (key: keyof PartnerConfig['featureFlags']) => boolean | string
}

declare module 'vue/types/vue' {
  interface Vue {
    $session: SessionPlugin
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $session: SessionPlugin
  }

  interface Context {
    $session: SessionPlugin
  }
}

const getUser: () => Promise<pb.ExternalAdminUser> = async () => {
  const service = new ExternalAdminUserServiceClient(config.apiBaseUrl, {}, null)
  const request = new GetExternalAdminUserRequest()
  const metadata = await Vue.prototype.$getMetadata()
  return new Promise((resolve, reject) => {
    service.get(request, metadata, (err, response: GetExternalAdminUserResponse) => {
      if (err) {
        reject(err)
        return
      }
      const user = response.getExternalAdminUser()!
      resolve(user)
    })
  })
}
const changedChannel =
  typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel('sessionChanged') : null

const sessionPlugin: Plugin = (
  { $ld, $datadogLogger }: { $ld: LaunchDarklyPlugin; $datadogLogger: typeof datadogLogs.logger },
  inject,
) => {
  const updateStatus = () => {
    if (process.env.server) {
      return
    }
    return new Promise<void>((resolve, reject) => {
      let unsubscribe: firebase.Unsubscribe | null = firebaseApp
        .auth()
        .onAuthStateChanged(async (firebaseUser) => {
          if (unsubscribe) {
            unsubscribe()
            unsubscribe = null
          } else {
            return
          }

          if (firebaseUser) {
            try {
              const user = await getUser()
              session.isSignedIn = true
              const role = (() => {
                switch (user.getRole()!) {
                  case pb.ExternalAdminUser.Role.ADMIN:
                    return UserRole.Admin
                  case pb.ExternalAdminUser.Role.MANAGER:
                    return UserRole.Manager
                  case pb.ExternalAdminUser.Role.MEMBER:
                    return UserRole.Member
                  case pb.ExternalAdminUser.Role.DEVELOPER:
                    return UserRole.Developer
                  default:
                    throw new Error('unsupported role')
                }
              })()
              const companyName = (() => {
                switch (user.getSiteType()) {
                  case EcSite.SiteType.ITOYOKADO:
                    return 'イトーヨーカドー'
                  case EcSite.SiteType.ALBIS:
                    // 外部に見せるようにアルビスを消している
                    if (config.environment === Environment.development) {
                      return 'デモ'
                    }
                    return 'アルビス'
                  case EcSite.SiteType.YAKUODO:
                    return '薬王堂'
                  case EcSite.SiteType.FRESTA:
                    return 'フレスタ'
                  case EcSite.SiteType.DEMO:
                    return 'Stailer'
                  case EcSite.SiteType.LIFE:
                    return 'ライフ'
                  case EcSite.SiteType.CREATESD:
                    return 'クリエイト'
                  case EcSite.SiteType.HEIWADO:
                    return '平和堂'
                  case EcSite.SiteType.SUGI:
                    return 'スギ薬局'
                  case EcSite.SiteType.DELICIA:
                    return 'デリシア'
                  case EcSite.SiteType.LADYDRUG:
                    return 'レデイ薬局'
                  case EcSite.SiteType.OGINO:
                    return 'オギノ'
                  case EcSite.SiteType.TORISEN:
                    return 'とりせん'
                  case EcSite.SiteType.SEISENICHIBATOP:
                    return '生鮮市場TOP！'
                  case EcSite.SiteType.ARUK:
                    return 'アルク'
                  case EcSite.SiteType.FUJI:
                    return 'フジ'
                  default:
                    throw new Error('unsupported site type')
                }
              })()
              const companyLogo = (() => {
                switch (user.getSiteType()) {
                  case EcSite.SiteType.ITOYOKADO:
                    return '/images/iy.png'
                  case EcSite.SiteType.ALBIS:
                    console.log(config)
                    console.log(Environment)
                    // 外部に見せるようにアルビスを消している
                    if (config.environment === Environment.development) {
                      return '/images/white.png'
                    }
                    return '/images/albis.png'
                  case EcSite.SiteType.YAKUODO:
                    return '/images/yakuodo.png'
                  case EcSite.SiteType.FRESTA:
                    return '/images/fresta.png'
                  case EcSite.SiteType.DEMO:
                    return '/images/demo.png'
                  case EcSite.SiteType.LIFE:
                    return '/images/life.png'
                  case EcSite.SiteType.CREATESD:
                  case EcSite.SiteType.HEIWADO:
                  case EcSite.SiteType.SUGI:
                  case EcSite.SiteType.DELICIA:
                  case EcSite.SiteType.LADYDRUG:
                  case EcSite.SiteType.OGINO:
                  case EcSite.SiteType.TORISEN:
                  case EcSite.SiteType.SEISENICHIBATOP:
                  case EcSite.SiteType.ARUK:
                  case EcSite.SiteType.FUJI:
                    // アイコンが決まるまでは仮でwhite.pngを返しておく
                    return '/images/white.png'
                  default:
                    throw new Error('unsupported site type')
                }
              })()
              const shopId = user.getShopId() || null
              const shopName = user.getShopName() || null
              const lastSelectedShopId = window.localStorage.getItem('shopId') || null
              session.signedInUser = {
                id: user.getId(),
                name: user.getName(),
                email: user.getAccountTypeEmail() ? user.getAccountTypeEmail()!.getEmail() : '',
                role,
                siteType: user.getSiteType(),
                shopId,
                shopName,
                lastSelectedShopId,
                companyName,
                companyLogo,
              }
              changedChannel?.postMessage(user.getId())

              // Datadog RUMのpropertyを更新する
              datadogRum.setGlobalContextProperty('siteType', session.signedInUser.siteType)
              datadogRum.setGlobalContextProperty('userId', session.signedInUser.id)

              // Datadogに送るログのpropertyを更新する
              datadogLogs.setUser({
                siteType: session.signedInUser.siteType,
                userId: session.signedInUser.id,
              })

              if (lastSelectedShopId != null) {
                datadogLogs.setUserProperty('shopId', lastSelectedShopId)
                datadogRum.setGlobalContextProperty('shopId', shopId)
              }

              // ログインしたタイミングでLaunchDarklyのContextが特定できるためidentifyする
              const ldContext: LDContext = {
                kind: 'multi',
                site_type: { key: Vue.prototype.$getSiteTypeName(session.signedInUser!.siteType) },
                user: { key: session.signedInUser.id },
              }
              if (session.signedInUser.lastSelectedShopId) {
                ldContext.ec_site_shop = { key: session.signedInUser.lastSelectedShopId }
              }
              try {
                await $ld.identify(ldContext)
              } catch (e) {
                $datadogLogger.error(`Failed to identify LaunchDarkly context ${e}`)
              }

              resolve()
            } catch (e) {
              clearStatus()
              reject(e)
            }
          } else {
            clearStatus()
            reject(new Error('not signed in'))
          }
        })
    })
  }

  const clearStatus = () => {
    session.isSignedIn = false
    session.signedInUser = null
    changedChannel?.postMessage(null)
    window.localStorage.removeItem('shopId')
    window.localStorage.removeItem('lastSelectedDisplayUnitInPickingList')
  }

  const selectShop = async (shopId: string) => {
    // TODO: 権限管理の機能を実装したときに本部アカウントだけ呼べるメソッドにする
    if (!session.isSignedIn) {
      return
    }
    session.signedInUser!.lastSelectedShopId = shopId
    window.localStorage.setItem('shopId', shopId)

    // Datadog RUMのpropertyを更新する
    datadogRum.setGlobalContextProperty('siteType', session.signedInUser!.siteType)
    datadogRum.setGlobalContextProperty('userId', session.signedInUser!.id)
    datadogRum.setGlobalContextProperty('shopId', shopId)

    // Datadogに送るログのpropertyを更新する
    datadogLogs.setUser({
      siteType: session.signedInUser!.siteType,
      userId: session.signedInUser!.id,
    })

    datadogLogs.setUserProperty('shopId', shopId)

    // 店舗選択したタイミングでLaunchDarklyのContextが変わるためidentifyする
    const ldContext: LDContext = {
      kind: 'multi',
      site_type: { key: Vue.prototype.$getSiteTypeName(session.signedInUser!.siteType) },
      user: { key: session.signedInUser!.id },
      ec_site_shop: { key: session.signedInUser!.lastSelectedShopId },
    }
    try {
      await $ld.identify(ldContext)
    } catch (e) {
      $datadogLogger.error(`Failed to identify LaunchDarkly context ${e}`)
    }
  }

  const roleIncludedIn = (roles: UserRole[]): boolean => {
    if (!session.isSignedIn) {
      return false
    }
    return roles.includes(session.signedInUser!.role)
  }

  const getFeatureFlag = (key: keyof PartnerConfig['featureFlags']): boolean | string | number => {
    if (!session.isSignedIn || !session.signedInUser) {
      return false
    }

    const siteType = session.signedInUser.siteType
    return getPartnerConfig(siteType).featureFlags[key]
  }

  const session = Vue.observable({
    isSignedIn: false,
    signedInUser: null,
    updateStatus,
    clearStatus,
    selectShop,
    roleIncludedIn,
    changedChannel,
    getFeatureFlag,
  } as SessionPlugin)

  inject('session', session)
}

export default sessionPlugin
