import { datadogRum, RumActionEvent, RumEvent } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { LaunchDarklyPlugin } from './launchDarkly'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { Inject } from '@nuxt/types/app'

declare module 'vue/types/vue' {
  interface Vue {
    $datadogLogger: typeof datadogLogs.logger
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $datadogLogger: typeof datadogLogs.logger
  }

  interface Context {
    $datadogLogger: typeof datadogLogs.logger
  }
}

export default (
  { $config, $ld }: { $config: NuxtRuntimeConfig; $ld: LaunchDarklyPlugin },
  inject: Inject,
) => {
  const sessionSampleRate =
    $ld.flags['stailer-external-admin-frontend-datadog-rum-session-sampling-rate'] ?? 10
  const sessionReplaySampleRate =
    $ld.flags['stailer-external-admin-frontend-datadog-rum-session-replay-sampling-rate'] ?? 10

  // ログ取得を一時的に停止するためのフラグ
  // 基本はログ全取得だが、エスケープハッチとしてログを止められるようにしている。
  // 例えば再帰的にログを送信するようになってしまっててすぐに止めないと破産まっしぐらなとき。
  const isLoggingSuspended =
    $ld.flags['stailer-external-admin-frontend-datadog-logs-suspend'] ?? false

  datadogRum.init({
    applicationId: $config.datadog.applicationId,
    clientToken: $config.datadog.clientToken,
    site: 'datadoghq.com',
    service: 'stailer-external-admin-frontend',
    env: $config.datadog.env,
    sessionSampleRate,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask', // 画面上に表示される全てのテキストをDatadog上では `XXX` という文字列でマスクして保存する
    beforeSend: (event) => {
      // 自動計装されたクリックイベントの action.target.name には画面上に表示される個人情報が含まれる可能性があるためマスクする
      // see: https://docs.datadoghq.com/ja/real_user_monitoring/browser/tracking_user_actions/
      if (isRumActionEvent(event) && event.action.target !== undefined) {
        event.action.target.name = 'XXX'
      }
      return true
    },
  })

  datadogLogs.init({
    clientToken: $config.datadog.clientToken,
    service: 'external-admin-frontend',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: isLoggingSuspended ? 0 : 100,
    env: $config.datadog.env,
    beforeSend() {
      if (isLoggingSuspended) {
        console.log('datadog logging suspended')
        return false
      }
      return true
    },
  })

  inject('datadogLogger', datadogLogs.logger)

  datadogRum.startSessionReplayRecording()
}

function isRumActionEvent(event: RumEvent): event is RumActionEvent {
  return (event as RumActionEvent).type === 'action'
}
