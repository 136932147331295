const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['only_admin_and_manager'] = require('../middleware/only_admin_and_manager.ts')
middleware['only_admin_and_manager'] = middleware['only_admin_and_manager'].default || middleware['only_admin_and_manager']

middleware['only_admin'] = require('../middleware/only_admin.ts')
middleware['only_admin'] = middleware['only_admin'].default || middleware['only_admin']

export default middleware
