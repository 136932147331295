import { EcSite } from '@apis/type/ec_site_pb'

export enum UserRole {
  Admin,
  Manager,
  Member,
  Developer,
}

// 管理画面にログインしているユーザ自身です。
export interface User {
  id: string
  name: string
  email: string
  role: UserRole
  siteType: EcSite.SiteType
  companyName: string
  companyLogo: string
  shopName: string | null
  shopId: string | null
  // 管理画面で最後に選択している店舗のIDです。
  // さまざまな画面で店舗ごとの情報を出す際にはクエリパラメータに店舗IDが入っているので
  // そこから抜き取った値がセットされます。
  lastSelectedShopId: string | null
}
