import Vue from 'vue'
import { EcProduct } from '@apis/stailer/externaladminrpc/ec_product_pb'

Vue.filter('stockDisplayStatusText', (value: EcProduct.Stock.DisplayStatus) => {
  switch (value) {
    case EcProduct.Stock.DisplayStatus.FOR_SALE:
      return '購入可能'
    case EcProduct.Stock.DisplayStatus.SOLD_OUT:
      return '品切れ'
    case EcProduct.Stock.DisplayStatus.HIDDEN:
      return '非表示'
    default:
      return '選択なし'
  }
})

Vue.filter('formatInTaxPrice', function (value: number) {
  return value.toFixed(2)
})
