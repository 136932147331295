// source: stailer/externaladminrpc/external_admin_user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_ec_site_pb = require('../../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser', null, global);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser.AccountTypeCase', null, global);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail', null, global);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername', null, global);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings', null, global);
goog.exportSymbol('proto.stailer_external_admin.ExternalAdminUser.Role', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ExternalAdminUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.stailer_external_admin.ExternalAdminUser.oneofGroups_);
};
goog.inherits(proto.stailer_external_admin.ExternalAdminUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ExternalAdminUser.displayName = 'proto.stailer_external_admin.ExternalAdminUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.displayName = 'proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.displayName = 'proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.displayName = 'proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.stailer_external_admin.ExternalAdminUser.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeCase = {
  ACCOUNT_TYPE_NOT_SET: 0,
  ACCOUNT_TYPE_EMAIL: 3,
  ACCOUNT_TYPE_USERNAME: 4
};

/**
 * @return {proto.stailer_external_admin.ExternalAdminUser.AccountTypeCase}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getAccountTypeCase = function() {
  return /** @type {proto.stailer_external_admin.ExternalAdminUser.AccountTypeCase} */(jspb.Message.computeOneofCase(this, proto.stailer_external_admin.ExternalAdminUser.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ExternalAdminUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ExternalAdminUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountTypeEmail: (f = msg.getAccountTypeEmail()) && proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.toObject(includeInstance, f),
    accountTypeUsername: (f = msg.getAccountTypeUsername()) && proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.toObject(includeInstance, f),
    role: jspb.Message.getFieldWithDefault(msg, 5, 0),
    siteType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    shopId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pushNotificationSettings: (f = msg.getPushNotificationSettings()) && proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ExternalAdminUser as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ExternalAdminUser.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.accountTypeEmail;

  /** @type {?|undefined} */
  this.accountTypeUsername;

  /** @type {?|undefined} */
  this.role;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.shopName;

  /** @type {?|undefined} */
  this.pushNotificationSettings;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.ExternalAdminUser.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ExternalAdminUser();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.name != null && jspb.Message.setField(msg, 2, obj.name);
  obj.accountTypeEmail && jspb.Message.setWrapperField(
      msg, 3, proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.fromObject(obj.accountTypeEmail));
  obj.accountTypeUsername && jspb.Message.setWrapperField(
      msg, 4, proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.fromObject(obj.accountTypeUsername));
  obj.role != null && jspb.Message.setField(msg, 5, obj.role);
  obj.siteType != null && jspb.Message.setField(msg, 6, obj.siteType);
  obj.shopId != null && jspb.Message.setField(msg, 7, obj.shopId);
  obj.shopName != null && jspb.Message.setField(msg, 8, obj.shopName);
  obj.pushNotificationSettings && jspb.Message.setWrapperField(
      msg, 9, proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.fromObject(obj.pushNotificationSettings));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.ExternalAdminUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ExternalAdminUser;
  return proto.stailer_external_admin.ExternalAdminUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ExternalAdminUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ExternalAdminUser}
 */
proto.stailer_external_admin.ExternalAdminUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail;
      reader.readMessage(value,proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.deserializeBinaryFromReader);
      msg.setAccountTypeEmail(value);
      break;
    case 4:
      var value = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername;
      reader.readMessage(value,proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.deserializeBinaryFromReader);
      msg.setAccountTypeUsername(value);
      break;
    case 5:
      var value = /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 6:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 9:
      var value = new proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings;
      reader.readMessage(value,proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.deserializeBinaryFromReader);
      msg.setPushNotificationSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ExternalAdminUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ExternalAdminUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountTypeEmail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.serializeBinaryToWriter
    );
  }
  f = message.getAccountTypeUsername();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPushNotificationSettings();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.stailer_external_admin.ExternalAdminUser.Role = {
  MEMBER: 0,
  ADMIN: 1,
  MANAGER: 2,
  DEVELOPER: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of AccountTypeEmail as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.email;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail();
  obj.email != null && jspb.Message.setField(msg, 1, obj.email);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail;
  return proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of AccountTypeUsername as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.username;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername();
  obj.username != null && jspb.Message.setField(msg, 1, obj.username);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername;
  return proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    isInStoreArrangedDeliveryEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PushNotificationSettings as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.isInStoreArrangedDeliveryEnabled;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings();
  obj.isInStoreArrangedDeliveryEnabled != null && jspb.Message.setField(msg, 1, obj.isInStoreArrangedDeliveryEnabled);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings;
  return proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInStoreArrangedDeliveryEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsInStoreArrangedDeliveryEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_in_store_arranged_delivery_enabled = 1;
 * @return {boolean}
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.prototype.getIsInStoreArrangedDeliveryEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings.prototype.setIsInStoreArrangedDeliveryEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountTypeEmail account_type_email = 3;
 * @return {?proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getAccountTypeEmail = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail, 3));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser.AccountTypeEmail|undefined} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
*/
proto.stailer_external_admin.ExternalAdminUser.prototype.setAccountTypeEmail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.stailer_external_admin.ExternalAdminUser.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.clearAccountTypeEmail = function() {
  return this.setAccountTypeEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.hasAccountTypeEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AccountTypeUsername account_type_username = 4;
 * @return {?proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getAccountTypeUsername = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername, 4));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser.AccountTypeUsername|undefined} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
*/
proto.stailer_external_admin.ExternalAdminUser.prototype.setAccountTypeUsername = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.stailer_external_admin.ExternalAdminUser.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.clearAccountTypeUsername = function() {
  return this.setAccountTypeUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.hasAccountTypeUsername = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Role role = 5;
 * @return {!proto.stailer_external_admin.ExternalAdminUser.Role}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getRole = function() {
  return /** @type {!proto.stailer_external_admin.ExternalAdminUser.Role} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.stailer_external_admin.ExternalAdminUser.Role} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional type.EcSite.SiteType site_type = 6;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string shop_id = 7;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string shop_name = 8;
 * @return {string}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional PushNotificationSettings push_notification_settings = 9;
 * @return {?proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.getPushNotificationSettings = function() {
  return /** @type{?proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings, 9));
};


/**
 * @param {?proto.stailer_external_admin.ExternalAdminUser.PushNotificationSettings|undefined} value
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
*/
proto.stailer_external_admin.ExternalAdminUser.prototype.setPushNotificationSettings = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.ExternalAdminUser} returns this
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.clearPushNotificationSettings = function() {
  return this.setPushNotificationSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.ExternalAdminUser.prototype.hasPushNotificationSettings = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.stailer_external_admin);
