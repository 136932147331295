import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import { DialogProgrammatic } from 'buefy'

declare type DialogConfig = {
  /**
   * Dialog title
   */
  title?: string

  /**
   * Message text
   */
  message: string | any[]

  /**
   * Adds an icon on the left side depending on the <code>type</code> or <code>icon</code>
   */
  hasIcon?: boolean

  /**
   * Icon name if <code>hasIcon</code>, optional
   */
  icon?: string

  /**
   * Text of the confirm button
   */
  confirmText?: string

  /**
   * Text of the cancel button
   */
  cancelText?: string

  /**
   * Callback function when the confirm button is clicked
   */
  onConfirm?: (value: string, dialog: any) => any

  /**
   * Callback function when the dialog is canceled (cancel button is clicked / pressed escape / clicked outside)
   */
  onCancel?: () => any

  /**
   * Type (color) of the confirm button
   */
  type?: 'is-primary' | 'is-danger' | 'is-success'

  /**
   * Focus on confirm or cancel button (when dialog is not prompt)
   */
  focusOn?: 'confirm' | 'cancel'
}
type PromptDialogConfig = DialogConfig & {
  /**
   * Prompt only: input's attributes
   */
  inputAttrs?: any
}

type DialogsPlugin = {
  alert: (params: DialogConfig | string) => Promise<void>
  confirm: (params: DialogConfig) => Promise<boolean>
  prompt: (params: PromptDialogConfig) => Promise<string | null>
}

declare module 'vue/types/vue' {
  interface Vue {
    $dialogs: DialogsPlugin
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $dialogs: DialogsPlugin
  }

  interface Context {
    $dialogs: DialogsPlugin
  }
}

/**
 * buefyのalertをawaitできるようにしたもの
 * [Dialog | Buefy](https://buefy.org/documentation/dialog#alert)
 */
const alert = (params: DialogConfig | string): Promise<void> => {
  const p = params
  return new Promise((resolve) => {
    const params: DialogConfig = typeof p === 'string' ? { message: p } : p
    const onConfirm = params.onConfirm
    const onCancel = params.onCancel
    params.onConfirm = (value: string, dialog: any) => {
      resolve()
      return onConfirm ? onConfirm(value, dialog) : null
    }
    params.onCancel = () => {
      resolve()
      return onCancel ? onCancel() : null
    }
    DialogProgrammatic.alert(params)
  })
}

/**
 * buefyのconfirmをawaitできるようにしたもの
 * [Dialog | Buefy](https://buefy.org/documentation/dialog#confirm)
 */
const confirm = (params: DialogConfig): Promise<boolean> => {
  return new Promise((resolve) => {
    const onConfirm = params.onConfirm
    const onCancel = params.onCancel
    params.onConfirm = (value: string, dialog: any) => {
      resolve(true)
      return onConfirm ? onConfirm(value, dialog) : null
    }
    params.onCancel = () => {
      resolve(false)
      return onCancel ? onCancel() : null
    }
    DialogProgrammatic.confirm(params)
  })
}

/**
 * buefyのpromptをawaitできるようにしたもの
 * [Dialog | Buefy](https://buefy.org/documentation/dialog#prompt)
 */
const prompt = (params: PromptDialogConfig): Promise<string | null> => {
  return new Promise((resolve) => {
    const onConfirm = params.onConfirm
    const onCancel = params.onCancel
    params.onConfirm = (value: string, dialog: any) => {
      resolve(value)
      return onConfirm ? onConfirm(value, dialog) : null
    }
    params.onCancel = () => {
      resolve(null)
      return onCancel ? onCancel() : null
    }
    DialogProgrammatic.prompt(params)
  })
}

const dialogs = Vue.observable({
  alert,
  confirm,
  prompt,
} as DialogsPlugin)

const dialogsPlugin: Plugin = (_, inject) => {
  inject('dialogs', dialogs)
}

export default dialogsPlugin
