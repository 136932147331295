import { Middleware } from '@nuxt/types'
import { UserRole } from '~/types/user'

const OnlyAdminMiddleware: Middleware = (context) => {
  if (process.server) {
    return
  }
  // Developerはすべての機能を確認する想定であるため、Adminと同じページにアクセスできるようにする。
  // フロントエンドは基本的に同等だが、バックエンドでは招待の実行が拒否される点が異なる。
  if (!context.app.$session.roleIncludedIn([UserRole.Admin, UserRole.Developer])) {
    context.redirect('/')
  }
}

export default OnlyAdminMiddleware
