import { Plugin } from '@nuxt/types'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import {
  initialize,
  type LDClient,
  type LDContext,
  type LDFlagSet,
} from 'launchdarkly-js-client-sdk'
import { reactive } from 'vue'

export type LaunchDarklyPlugin = {
  ldClient: LDClient
  identify: (
    newContext: LDContext,
    hash?: string,
    callback?: ((err: Error | null, flags: LDFlagSet | null) => void) | undefined,
  ) => Promise<void>
  flags: LDFlagSet
  ready: boolean
  error: Error | null
}

declare module 'vue/types/vue' {
  interface Vue {
    $ld: LaunchDarklyPlugin
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $ld: LaunchDarklyPlugin
  }

  interface Context {
    $ld: LaunchDarklyPlugin
  }
}

export const ldInit = (clientSideId: string, context: LDContext) => {
  const ldClient = initialize(clientSideId, context)
  const $ld = reactive({
    ldClient,
    identify(
      newContext: LDContext,
      hash?: string,
      callback?: ((err: Error | null, flags: LDFlagSet | null) => void) | undefined,
    ) {
      return new Promise<void>((resolve, reject) => {
        this.ready = false
        ldClient
          .identify(newContext, hash, callback)
          .then(() => {
            this.ready = true
            resolve()
          })
          .catch((e) => {
            this.error = e
            reject(e)
          })
      })
    },
    flags: {} as LDFlagSet,
    ready: false,
    error: null,
  })

  ldClient.on('ready', () => {
    $ld.flags = ldClient.allFlags()
    $ld.ready = true
  })

  ldClient.on('change', (changes) => {
    const flattenedFlags = Object.fromEntries(
      Object.keys(changes).map((key) => [key, changes[key].current]),
    )
    $ld.flags = {
      ...$ld.flags,
      ...flattenedFlags,
    }
  })

  ldClient.on('error', (e) => {
    $ld.error = e
    throw e
  })

  return $ld
}

const launchDarklyPlugin: Plugin = ({ $config }: { $config: NuxtRuntimeConfig }, inject) => {
  // この処理が呼び出される時点ではfirebaseAppのログイン状態が確定していないため、anonymous-userとして初期化する
  // ログイン状態が確定後、ログインしていればsession plugin側でidentifyを呼び出してContextを更新することを期待している
  // LaunchDarklyはClient Side MAUs単位の課金形態のため、ログイン前は一括してanonymous-userとして扱うことで課金を抑えている
  const ldContext = { kind: 'user', key: 'anonymous-user' }
  const $ld = ldInit($config.launchDarkly.clientSideId, ldContext)
  inject('ld', $ld)
}

export default launchDarklyPlugin
