// source: type/address.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.type.Address', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Address.displayName = 'proto.type.Address';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    postalCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prefecture: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 3, ""),
    town: jspb.Message.getFieldWithDefault(msg, 4, ""),
    area: jspb.Message.getFieldWithDefault(msg, 5, ""),
    number: jspb.Message.getFieldWithDefault(msg, 6, ""),
    building: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Address as accepted by the `fromObject` method.
 * @record
 */
proto.type.Address.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.postalCode;

  /** @type {?|undefined} */
  this.prefecture;

  /** @type {?|undefined} */
  this.city;

  /** @type {?|undefined} */
  this.town;

  /** @type {?|undefined} */
  this.area;

  /** @type {?|undefined} */
  this.number;

  /** @type {?|undefined} */
  this.building;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Address.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Address}
 */
proto.type.Address.fromObject = function(obj) {
  var msg = new proto.type.Address();
  obj.postalCode != null && jspb.Message.setField(msg, 1, obj.postalCode);
  obj.prefecture != null && jspb.Message.setField(msg, 2, obj.prefecture);
  obj.city != null && jspb.Message.setField(msg, 3, obj.city);
  obj.town != null && jspb.Message.setField(msg, 4, obj.town);
  obj.area != null && jspb.Message.setField(msg, 5, obj.area);
  obj.number != null && jspb.Message.setField(msg, 6, obj.number);
  obj.building != null && jspb.Message.setField(msg, 7, obj.building);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Address}
 */
proto.type.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Address;
  return proto.type.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Address}
 */
proto.type.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefecture(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTown(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuilding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrefecture();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTown();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBuilding();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string postal_code = 1;
 * @return {string}
 */
proto.type.Address.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prefecture = 2;
 * @return {string}
 */
proto.type.Address.prototype.getPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.type.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string town = 4;
 * @return {string}
 */
proto.type.Address.prototype.getTown = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setTown = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string area = 5;
 * @return {string}
 */
proto.type.Address.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string number = 6;
 * @return {string}
 */
proto.type.Address.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string building = 7;
 * @return {string}
 */
proto.type.Address.prototype.getBuilding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Address} returns this
 */
proto.type.Address.prototype.setBuilding = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


goog.object.extend(exports, proto.type);
