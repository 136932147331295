// source: type/delivery_time.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_delivery_fee_range_pb = require('../type/delivery_fee_range_pb.js');
goog.object.extend(proto, type_delivery_fee_range_pb);
var type_order_receive_type_pb = require('../type/order_receive_type_pb.js');
goog.object.extend(proto, type_order_receive_type_pb);
var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_purchasable_price_pb = require('../type/purchasable_price_pb.js');
goog.object.extend(proto, type_purchasable_price_pb);
var type_receive_method_pb = require('../type/receive_method_pb.js');
goog.object.extend(proto, type_receive_method_pb);
goog.exportSymbol('proto.type.DeliveryTime', null, global);
goog.exportSymbol('proto.type.DeliveryTime.DeliveryDeadlineDuration', null, global);
goog.exportSymbol('proto.type.DeliveryTime.DeliveryFeeListItem', null, global);
goog.exportSymbol('proto.type.DeliveryTime.DeliveryTimeRange', null, global);
goog.exportSymbol('proto.type.DeliveryTime.Status', null, global);
goog.exportSymbol('proto.type.DeliveryTime.UnavailableReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.DeliveryTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.DeliveryTime.repeatedFields_, null);
};
goog.inherits(proto.type.DeliveryTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.DeliveryTime.displayName = 'proto.type.DeliveryTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.DeliveryTime.DeliveryFeeListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.DeliveryTime.DeliveryFeeListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.DeliveryTime.DeliveryFeeListItem.displayName = 'proto.type.DeliveryTime.DeliveryFeeListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.DeliveryTime.DeliveryDeadlineDuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.DeliveryTime.DeliveryDeadlineDuration.displayName = 'proto.type.DeliveryTime.DeliveryDeadlineDuration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.DeliveryTime.DeliveryTimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.DeliveryTime.DeliveryTimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.DeliveryTime.DeliveryTimeRange.displayName = 'proto.type.DeliveryTime.DeliveryTimeRange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.DeliveryTime.repeatedFields_ = [9,19,25,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.DeliveryTime.prototype.toObject = function(opt_includeInstance) {
  return proto.type.DeliveryTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.DeliveryTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deadline: jspb.Message.getFieldWithDefault(msg, 4, 0),
    noTaxDeliveryFee: jspb.Message.getFieldWithDefault(msg, 5, 0),
    inTaxDeliveryFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    inTaxDeliveryFreePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    isStoreReceipt: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    deliveryFeeListList: jspb.Message.toObjectList(msg.getDeliveryFeeListList(),
    proto.type.DeliveryTime.DeliveryFeeListItem.toObject, includeInstance),
    deliveryFrom: (f = msg.getDeliveryFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryTo: (f = msg.getDeliveryTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orderDeadline: (f = msg.getOrderDeadline()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDropOff: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isContactlessDelivery: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    receiptPointId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    deliveryTimeId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    deliveryApplicationBegin: (f = msg.getDeliveryApplicationBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryFeeRangesList: jspb.Message.toObjectList(msg.getDeliveryFeeRangesList(),
    type_delivery_fee_range_pb.DeliveryFeeRange.toObject, includeInstance),
    deliveryFeeSubtotalCalculationType: jspb.Message.getFieldWithDefault(msg, 20, 0),
    deliveryFeeType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    deliveryOrder: jspb.Message.getFieldWithDefault(msg, 22, 0),
    name: jspb.Message.getFieldWithDefault(msg, 23, ""),
    exclusiveUserId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    orderReceiveTypesList: jspb.Message.toObjectList(msg.getOrderReceiveTypesList(),
    type_order_receive_type_pb.OrderReceiveType.toObject, includeInstance),
    purchasablePrice: (f = msg.getPurchasablePrice()) && type_purchasable_price_pb.PurchasablePrice.toObject(includeInstance, f),
    orderReceiveKindsList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    isPublished: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    receiveMethod: (f = msg.getReceiveMethod()) && type_receive_method_pb.ReceiveMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliveryTime as accepted by the `fromObject` method.
 * @record
 */
proto.type.DeliveryTime.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.from;

  /** @type {?|undefined} */
  this.to;

  /** @type {?|undefined} */
  this.status;

  /** @type {?|undefined} */
  this.deadline;

  /** @type {?|undefined} */
  this.noTaxDeliveryFee;

  /** @type {?|undefined} */
  this.inTaxDeliveryFee;

  /** @type {?|undefined} */
  this.inTaxDeliveryFreePrice;

  /** @type {?|undefined} */
  this.isStoreReceipt;

  /** @type {?|undefined} */
  this.deliveryFeeListList;

  /** @type {?|undefined} */
  this.deliveryFrom;

  /** @type {?|undefined} */
  this.deliveryTo;

  /** @type {?|undefined} */
  this.orderDeadline;

  /** @type {?|undefined} */
  this.isDropOff;

  /** @type {?|undefined} */
  this.isContactlessDelivery;

  /** @type {?|undefined} */
  this.receiptPointId;

  /** @type {?|undefined} */
  this.deliveryTimeId;

  /** @type {?|undefined} */
  this.deliveryApplicationBegin;

  /** @type {?|undefined} */
  this.deliveryFeeRangesList;

  /** @type {?|undefined} */
  this.deliveryFeeSubtotalCalculationType;

  /** @type {?|undefined} */
  this.deliveryFeeType;

  /** @type {?|undefined} */
  this.deliveryOrder;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.exclusiveUserId;

  /** @type {?|undefined} */
  this.orderReceiveTypesList;

  /** @type {?|undefined} */
  this.purchasablePrice;

  /** @type {?|undefined} */
  this.orderReceiveKindsList;

  /** @type {?|undefined} */
  this.isPublished;

  /** @type {?|undefined} */
  this.receiveMethod;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.DeliveryTime.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.DeliveryTime}
 */
proto.type.DeliveryTime.fromObject = function(obj) {
  var msg = new proto.type.DeliveryTime();
  obj.from != null && jspb.Message.setField(msg, 1, obj.from);
  obj.to != null && jspb.Message.setField(msg, 2, obj.to);
  obj.status != null && jspb.Message.setField(msg, 3, obj.status);
  obj.deadline != null && jspb.Message.setField(msg, 4, obj.deadline);
  obj.noTaxDeliveryFee != null && jspb.Message.setField(msg, 5, obj.noTaxDeliveryFee);
  obj.inTaxDeliveryFee != null && jspb.Message.setField(msg, 6, obj.inTaxDeliveryFee);
  obj.inTaxDeliveryFreePrice != null && jspb.Message.setField(msg, 7, obj.inTaxDeliveryFreePrice);
  obj.isStoreReceipt != null && jspb.Message.setField(msg, 8, obj.isStoreReceipt);
  obj.deliveryFeeListList && jspb.Message.setRepeatedWrapperField(
      msg, 9, obj.deliveryFeeListList.map(
          proto.type.DeliveryTime.DeliveryFeeListItem.fromObject));
  obj.deliveryFrom && jspb.Message.setWrapperField(
      msg, 10, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryFrom));
  obj.deliveryTo && jspb.Message.setWrapperField(
      msg, 11, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryTo));
  obj.orderDeadline && jspb.Message.setWrapperField(
      msg, 12, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.orderDeadline));
  obj.isDropOff != null && jspb.Message.setField(msg, 13, obj.isDropOff);
  obj.isContactlessDelivery != null && jspb.Message.setField(msg, 14, obj.isContactlessDelivery);
  obj.receiptPointId != null && jspb.Message.setField(msg, 15, obj.receiptPointId);
  obj.deliveryTimeId != null && jspb.Message.setField(msg, 16, obj.deliveryTimeId);
  obj.deliveryApplicationBegin && jspb.Message.setWrapperField(
      msg, 18, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryApplicationBegin));
  obj.deliveryFeeRangesList && jspb.Message.setRepeatedWrapperField(
      msg, 19, obj.deliveryFeeRangesList.map(
          type_delivery_fee_range_pb.DeliveryFeeRange.fromObject));
  obj.deliveryFeeSubtotalCalculationType != null && jspb.Message.setField(msg, 20, obj.deliveryFeeSubtotalCalculationType);
  obj.deliveryFeeType != null && jspb.Message.setField(msg, 21, obj.deliveryFeeType);
  obj.deliveryOrder != null && jspb.Message.setField(msg, 22, obj.deliveryOrder);
  obj.name != null && jspb.Message.setField(msg, 23, obj.name);
  obj.exclusiveUserId != null && jspb.Message.setField(msg, 24, obj.exclusiveUserId);
  obj.orderReceiveTypesList && jspb.Message.setRepeatedWrapperField(
      msg, 25, obj.orderReceiveTypesList.map(
          type_order_receive_type_pb.OrderReceiveType.fromObject));
  obj.purchasablePrice && jspb.Message.setWrapperField(
      msg, 27, type_purchasable_price_pb.PurchasablePrice.fromObject(obj.purchasablePrice));
  obj.orderReceiveKindsList != null && jspb.Message.setField(msg, 28, obj.orderReceiveKindsList);
  obj.isPublished != null && jspb.Message.setField(msg, 29, obj.isPublished);
  obj.receiveMethod && jspb.Message.setWrapperField(
      msg, 30, type_receive_method_pb.ReceiveMethod.fromObject(obj.receiveMethod));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.DeliveryTime}
 */
proto.type.DeliveryTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.DeliveryTime;
  return proto.type.DeliveryTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.DeliveryTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.DeliveryTime}
 */
proto.type.DeliveryTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {!proto.type.DeliveryTime.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadline(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxDeliveryFee(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxDeliveryFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxDeliveryFreePrice(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStoreReceipt(value);
      break;
    case 9:
      var value = new proto.type.DeliveryTime.DeliveryFeeListItem;
      reader.readMessage(value,proto.type.DeliveryTime.DeliveryFeeListItem.deserializeBinaryFromReader);
      msg.addDeliveryFeeList(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryFrom(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryTo(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderDeadline(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDropOff(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsContactlessDelivery(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptPointId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryTimeId(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryApplicationBegin(value);
      break;
    case 19:
      var value = new type_delivery_fee_range_pb.DeliveryFeeRange;
      reader.readMessage(value,type_delivery_fee_range_pb.DeliveryFeeRange.deserializeBinaryFromReader);
      msg.addDeliveryFeeRanges(value);
      break;
    case 20:
      var value = /** @type {!proto.type.DeliveryFeeSubtotalCalculationType} */ (reader.readEnum());
      msg.setDeliveryFeeSubtotalCalculationType(value);
      break;
    case 21:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setDeliveryFeeType(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryOrder(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setExclusiveUserId(value);
      break;
    case 25:
      var value = new type_order_receive_type_pb.OrderReceiveType;
      reader.readMessage(value,type_order_receive_type_pb.OrderReceiveType.deserializeBinaryFromReader);
      msg.addOrderReceiveTypes(value);
      break;
    case 27:
      var value = new type_purchasable_price_pb.PurchasablePrice;
      reader.readMessage(value,type_purchasable_price_pb.PurchasablePrice.deserializeBinaryFromReader);
      msg.setPurchasablePrice(value);
      break;
    case 28:
      var values = /** @type {!Array<!proto.type.OrderReceiveKind>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderReceiveKinds(values[i]);
      }
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublished(value);
      break;
    case 30:
      var value = new type_receive_method_pb.ReceiveMethod;
      reader.readMessage(value,type_receive_method_pb.ReceiveMethod.deserializeBinaryFromReader);
      msg.setReceiveMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.DeliveryTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.DeliveryTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.DeliveryTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeadline();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNoTaxDeliveryFee();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInTaxDeliveryFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getInTaxDeliveryFreePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getIsStoreReceipt();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDeliveryFeeListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.type.DeliveryTime.DeliveryFeeListItem.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryFrom();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryTo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrderDeadline();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDropOff();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsContactlessDelivery();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getReceiptPointId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDeliveryTimeId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDeliveryApplicationBegin();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryFeeRangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      type_delivery_fee_range_pb.DeliveryFeeRange.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryFeeSubtotalCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getDeliveryFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getDeliveryOrder();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getExclusiveUserId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOrderReceiveTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      type_order_receive_type_pb.OrderReceiveType.serializeBinaryToWriter
    );
  }
  f = message.getPurchasablePrice();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      type_purchasable_price_pb.PurchasablePrice.serializeBinaryToWriter
    );
  }
  f = message.getOrderReceiveKindsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      28,
      f
    );
  }
  f = message.getIsPublished();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getReceiveMethod();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      type_receive_method_pb.ReceiveMethod.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.type.DeliveryTime.Status = {
  AVAILABLE: 0,
  NOT_AVAILABLE: 1,
  AVAILABLE_SLIGHTLY: 2
};

/**
 * @enum {number}
 */
proto.type.DeliveryTime.UnavailableReason = {
  RECEPTION_CLOSED: 0,
  DELIVERY_NOT_ACCEPTED: 1,
  APPLICATION_NOT_STARTED: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.type.DeliveryTime.DeliveryFeeListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.DeliveryTime.DeliveryFeeListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryFeeListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    leastOrderPrice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inTaxDeliveryFee: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliveryFeeListItem as accepted by the `fromObject` method.
 * @record
 */
proto.type.DeliveryTime.DeliveryFeeListItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.leastOrderPrice;

  /** @type {?|undefined} */
  this.inTaxDeliveryFee;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.DeliveryTime.DeliveryFeeListItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.fromObject = function(obj) {
  var msg = new proto.type.DeliveryTime.DeliveryFeeListItem();
  obj.leastOrderPrice != null && jspb.Message.setField(msg, 1, obj.leastOrderPrice);
  obj.inTaxDeliveryFee != null && jspb.Message.setField(msg, 2, obj.inTaxDeliveryFee);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.DeliveryTime.DeliveryFeeListItem;
  return proto.type.DeliveryTime.DeliveryFeeListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.DeliveryTime.DeliveryFeeListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeastOrderPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInTaxDeliveryFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.DeliveryTime.DeliveryFeeListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.DeliveryTime.DeliveryFeeListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryFeeListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeastOrderPrice();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInTaxDeliveryFee();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 least_order_price = 1;
 * @return {number}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.getLeastOrderPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem} returns this
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.setLeastOrderPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 in_tax_delivery_fee = 2;
 * @return {number}
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.getInTaxDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem} returns this
 */
proto.type.DeliveryTime.DeliveryFeeListItem.prototype.setInTaxDeliveryFee = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.toObject = function(opt_includeInstance) {
  return proto.type.DeliveryTime.DeliveryDeadlineDuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.DeliveryTime.DeliveryDeadlineDuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    hours: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minutes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliveryDeadlineDuration as accepted by the `fromObject` method.
 * @record
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.hours;

  /** @type {?|undefined} */
  this.minutes;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.DeliveryTime.DeliveryDeadlineDuration.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.DeliveryTime.DeliveryDeadlineDuration}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.fromObject = function(obj) {
  var msg = new proto.type.DeliveryTime.DeliveryDeadlineDuration();
  obj.hours != null && jspb.Message.setField(msg, 1, obj.hours);
  obj.minutes != null && jspb.Message.setField(msg, 2, obj.minutes);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.DeliveryTime.DeliveryDeadlineDuration}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.DeliveryTime.DeliveryDeadlineDuration;
  return proto.type.DeliveryTime.DeliveryDeadlineDuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.DeliveryTime.DeliveryDeadlineDuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.DeliveryTime.DeliveryDeadlineDuration}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHours(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.DeliveryTime.DeliveryDeadlineDuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.DeliveryTime.DeliveryDeadlineDuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHours();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinutes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 hours = 1;
 * @return {number}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime.DeliveryDeadlineDuration} returns this
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.setHours = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 minutes = 2;
 * @return {number}
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.getMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime.DeliveryDeadlineDuration} returns this
 */
proto.type.DeliveryTime.DeliveryDeadlineDuration.prototype.setMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.type.DeliveryTime.DeliveryTimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.DeliveryTime.DeliveryTimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryTimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliveryTimeRange as accepted by the `fromObject` method.
 * @record
 */
proto.type.DeliveryTime.DeliveryTimeRange.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.from;

  /** @type {?|undefined} */
  this.to;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.DeliveryTime.DeliveryTimeRange.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange}
 */
proto.type.DeliveryTime.DeliveryTimeRange.fromObject = function(obj) {
  var msg = new proto.type.DeliveryTime.DeliveryTimeRange();
  obj.from && jspb.Message.setWrapperField(
      msg, 1, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.from));
  obj.to && jspb.Message.setWrapperField(
      msg, 2, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.to));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange}
 */
proto.type.DeliveryTime.DeliveryTimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.DeliveryTime.DeliveryTimeRange;
  return proto.type.DeliveryTime.DeliveryTimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.DeliveryTime.DeliveryTimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange}
 */
proto.type.DeliveryTime.DeliveryTimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.DeliveryTime.DeliveryTimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.DeliveryTime.DeliveryTimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.DeliveryTime.DeliveryTimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange} returns this
*/
proto.type.DeliveryTime.DeliveryTimeRange.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange} returns this
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp to = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange} returns this
*/
proto.type.DeliveryTime.DeliveryTimeRange.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime.DeliveryTimeRange} returns this
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.DeliveryTimeRange.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 from = 1;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 to = 2;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.type.DeliveryTime.Status}
 */
proto.type.DeliveryTime.prototype.getStatus = function() {
  return /** @type {!proto.type.DeliveryTime.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.type.DeliveryTime.Status} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 deadline = 4;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 no_tax_delivery_fee = 5;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getNoTaxDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setNoTaxDeliveryFee = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double in_tax_delivery_fee = 6;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getInTaxDeliveryFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setInTaxDeliveryFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double in_tax_delivery_free_price = 7;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getInTaxDeliveryFreePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setInTaxDeliveryFreePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool is_store_receipt = 8;
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.getIsStoreReceipt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setIsStoreReceipt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated DeliveryFeeListItem delivery_fee_list = 9;
 * @return {!Array<!proto.type.DeliveryTime.DeliveryFeeListItem>}
 */
proto.type.DeliveryTime.prototype.getDeliveryFeeListList = function() {
  return /** @type{!Array<!proto.type.DeliveryTime.DeliveryFeeListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.DeliveryTime.DeliveryFeeListItem, 9));
};


/**
 * @param {!Array<!proto.type.DeliveryTime.DeliveryFeeListItem>} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setDeliveryFeeListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.type.DeliveryTime.DeliveryFeeListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryTime.DeliveryFeeListItem}
 */
proto.type.DeliveryTime.prototype.addDeliveryFeeList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.type.DeliveryTime.DeliveryFeeListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearDeliveryFeeListList = function() {
  return this.setDeliveryFeeListList([]);
};


/**
 * optional google.protobuf.Timestamp delivery_from = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.prototype.getDeliveryFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setDeliveryFrom = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearDeliveryFrom = function() {
  return this.setDeliveryFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasDeliveryFrom = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_to = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.prototype.getDeliveryTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setDeliveryTo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearDeliveryTo = function() {
  return this.setDeliveryTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasDeliveryTo = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp order_deadline = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.prototype.getOrderDeadline = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setOrderDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearOrderDeadline = function() {
  return this.setOrderDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasOrderDeadline = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool is_drop_off = 13;
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.getIsDropOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setIsDropOff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_contactless_delivery = 14;
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.getIsContactlessDelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setIsContactlessDelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string receipt_point_id = 15;
 * @return {string}
 */
proto.type.DeliveryTime.prototype.getReceiptPointId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setReceiptPointId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string delivery_time_id = 16;
 * @return {string}
 */
proto.type.DeliveryTime.prototype.getDeliveryTimeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setDeliveryTimeId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp delivery_application_begin = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.DeliveryTime.prototype.getDeliveryApplicationBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setDeliveryApplicationBegin = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearDeliveryApplicationBegin = function() {
  return this.setDeliveryApplicationBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasDeliveryApplicationBegin = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated DeliveryFeeRange delivery_fee_ranges = 19;
 * @return {!Array<!proto.type.DeliveryFeeRange>}
 */
proto.type.DeliveryTime.prototype.getDeliveryFeeRangesList = function() {
  return /** @type{!Array<!proto.type.DeliveryFeeRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_delivery_fee_range_pb.DeliveryFeeRange, 19));
};


/**
 * @param {!Array<!proto.type.DeliveryFeeRange>} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setDeliveryFeeRangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.type.DeliveryFeeRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryFeeRange}
 */
proto.type.DeliveryTime.prototype.addDeliveryFeeRanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.type.DeliveryFeeRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearDeliveryFeeRangesList = function() {
  return this.setDeliveryFeeRangesList([]);
};


/**
 * optional DeliveryFeeSubtotalCalculationType delivery_fee_subtotal_calculation_type = 20;
 * @return {!proto.type.DeliveryFeeSubtotalCalculationType}
 */
proto.type.DeliveryTime.prototype.getDeliveryFeeSubtotalCalculationType = function() {
  return /** @type {!proto.type.DeliveryFeeSubtotalCalculationType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.type.DeliveryFeeSubtotalCalculationType} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setDeliveryFeeSubtotalCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional PriceType delivery_fee_type = 21;
 * @return {!proto.type.PriceType}
 */
proto.type.DeliveryTime.prototype.getDeliveryFeeType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setDeliveryFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional int32 delivery_order = 22;
 * @return {number}
 */
proto.type.DeliveryTime.prototype.getDeliveryOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setDeliveryOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string name = 23;
 * @return {string}
 */
proto.type.DeliveryTime.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string exclusive_user_id = 24;
 * @return {string}
 */
proto.type.DeliveryTime.prototype.getExclusiveUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setExclusiveUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated OrderReceiveType order_receive_types = 25;
 * @return {!Array<!proto.type.OrderReceiveType>}
 */
proto.type.DeliveryTime.prototype.getOrderReceiveTypesList = function() {
  return /** @type{!Array<!proto.type.OrderReceiveType>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_order_receive_type_pb.OrderReceiveType, 25));
};


/**
 * @param {!Array<!proto.type.OrderReceiveType>} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setOrderReceiveTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.type.OrderReceiveType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.OrderReceiveType}
 */
proto.type.DeliveryTime.prototype.addOrderReceiveTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.type.OrderReceiveType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearOrderReceiveTypesList = function() {
  return this.setOrderReceiveTypesList([]);
};


/**
 * optional PurchasablePrice purchasable_price = 27;
 * @return {?proto.type.PurchasablePrice}
 */
proto.type.DeliveryTime.prototype.getPurchasablePrice = function() {
  return /** @type{?proto.type.PurchasablePrice} */ (
    jspb.Message.getWrapperField(this, type_purchasable_price_pb.PurchasablePrice, 27));
};


/**
 * @param {?proto.type.PurchasablePrice|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setPurchasablePrice = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearPurchasablePrice = function() {
  return this.setPurchasablePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasPurchasablePrice = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * repeated OrderReceiveKind order_receive_kinds = 28;
 * @return {!Array<!proto.type.OrderReceiveKind>}
 */
proto.type.DeliveryTime.prototype.getOrderReceiveKindsList = function() {
  return /** @type {!Array<!proto.type.OrderReceiveKind>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<!proto.type.OrderReceiveKind>} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setOrderReceiveKindsList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {!proto.type.OrderReceiveKind} value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.addOrderReceiveKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearOrderReceiveKindsList = function() {
  return this.setOrderReceiveKindsList([]);
};


/**
 * optional bool is_published = 29;
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.getIsPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.setIsPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional ReceiveMethod receive_method = 30;
 * @return {?proto.type.ReceiveMethod}
 */
proto.type.DeliveryTime.prototype.getReceiveMethod = function() {
  return /** @type{?proto.type.ReceiveMethod} */ (
    jspb.Message.getWrapperField(this, type_receive_method_pb.ReceiveMethod, 30));
};


/**
 * @param {?proto.type.ReceiveMethod|undefined} value
 * @return {!proto.type.DeliveryTime} returns this
*/
proto.type.DeliveryTime.prototype.setReceiveMethod = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.DeliveryTime} returns this
 */
proto.type.DeliveryTime.prototype.clearReceiveMethod = function() {
  return this.setReceiveMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.DeliveryTime.prototype.hasReceiveMethod = function() {
  return jspb.Message.getField(this, 30) != null;
};


goog.object.extend(exports, proto.type);
