// source: type/bundled_item.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_bundle_rule_pb = require('../type/bundle_rule_pb.js');
goog.object.extend(proto, type_bundle_rule_pb);
var type_bundle_type_pb = require('../type/bundle_type_pb.js');
goog.object.extend(proto, type_bundle_type_pb);
goog.exportSymbol('proto.type.BundleAppliedProduct', null, global);
goog.exportSymbol('proto.type.BundledItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.BundleAppliedProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.BundleAppliedProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.BundleAppliedProduct.displayName = 'proto.type.BundleAppliedProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.BundledItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.BundledItem.repeatedFields_, null);
};
goog.inherits(proto.type.BundledItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.BundledItem.displayName = 'proto.type.BundledItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.BundleAppliedProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.type.BundleAppliedProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.BundleAppliedProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundleAppliedProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of BundleAppliedProduct as accepted by the `fromObject` method.
 * @record
 */
proto.type.BundleAppliedProduct.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.amount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.BundleAppliedProduct.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.BundleAppliedProduct}
 */
proto.type.BundleAppliedProduct.fromObject = function(obj) {
  var msg = new proto.type.BundleAppliedProduct();
  obj.productId != null && jspb.Message.setField(msg, 1, obj.productId);
  obj.amount != null && jspb.Message.setField(msg, 2, obj.amount);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.BundleAppliedProduct}
 */
proto.type.BundleAppliedProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.BundleAppliedProduct;
  return proto.type.BundleAppliedProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.BundleAppliedProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.BundleAppliedProduct}
 */
proto.type.BundleAppliedProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.BundleAppliedProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.BundleAppliedProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.BundleAppliedProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundleAppliedProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.type.BundleAppliedProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.BundleAppliedProduct} returns this
 */
proto.type.BundleAppliedProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.type.BundleAppliedProduct.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.BundleAppliedProduct} returns this
 */
proto.type.BundleAppliedProduct.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.BundledItem.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.BundledItem.prototype.toObject = function(opt_includeInstance) {
  return proto.type.BundledItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.BundledItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundledItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    bundleRuleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bundleRuleSnapshotId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    appliedCondition: (f = msg.getAppliedCondition()) && type_bundle_rule_pb.BundleCondition.toObject(includeInstance, f),
    appliedProductsList: jspb.Message.toObjectList(msg.getAppliedProductsList(),
    proto.type.BundleAppliedProduct.toObject, includeInstance),
    bundleRuleName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bundleRuleType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    bundleTypeDisplayName: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of BundledItem as accepted by the `fromObject` method.
 * @record
 */
proto.type.BundledItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.bundleRuleId;

  /** @type {?|undefined} */
  this.bundleRuleSnapshotId;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.appliedCondition;

  /** @type {?|undefined} */
  this.appliedProductsList;

  /** @type {?|undefined} */
  this.bundleRuleName;

  /** @type {?|undefined} */
  this.bundleRuleType;

  /** @type {?|undefined} */
  this.bundleTypeDisplayName;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.BundledItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.BundledItem}
 */
proto.type.BundledItem.fromObject = function(obj) {
  var msg = new proto.type.BundledItem();
  obj.bundleRuleId != null && jspb.Message.setField(msg, 1, obj.bundleRuleId);
  obj.bundleRuleSnapshotId != null && jspb.Message.setField(msg, 2, obj.bundleRuleSnapshotId);
  obj.price != null && jspb.Message.setField(msg, 3, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 4, obj.priceType);
  obj.appliedCondition && jspb.Message.setWrapperField(
      msg, 5, type_bundle_rule_pb.BundleCondition.fromObject(obj.appliedCondition));
  obj.appliedProductsList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.appliedProductsList.map(
          proto.type.BundleAppliedProduct.fromObject));
  obj.bundleRuleName != null && jspb.Message.setField(msg, 7, obj.bundleRuleName);
  obj.bundleRuleType != null && jspb.Message.setField(msg, 8, obj.bundleRuleType);
  obj.bundleTypeDisplayName != null && jspb.Message.setField(msg, 9, obj.bundleTypeDisplayName);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.BundledItem}
 */
proto.type.BundledItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.BundledItem;
  return proto.type.BundledItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.BundledItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.BundledItem}
 */
proto.type.BundledItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleSnapshotId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 5:
      var value = new type_bundle_rule_pb.BundleCondition;
      reader.readMessage(value,type_bundle_rule_pb.BundleCondition.deserializeBinaryFromReader);
      msg.setAppliedCondition(value);
      break;
    case 6:
      var value = new proto.type.BundleAppliedProduct;
      reader.readMessage(value,proto.type.BundleAppliedProduct.deserializeBinaryFromReader);
      msg.addAppliedProducts(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleName(value);
      break;
    case 8:
      var value = /** @type {!proto.type.BundleType} */ (reader.readEnum());
      msg.setBundleRuleType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleTypeDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.BundledItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.BundledItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.BundledItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundledItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBundleRuleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBundleRuleSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAppliedCondition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      type_bundle_rule_pb.BundleCondition.serializeBinaryToWriter
    );
  }
  f = message.getAppliedProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.type.BundleAppliedProduct.serializeBinaryToWriter
    );
  }
  f = message.getBundleRuleName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBundleRuleType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getBundleTypeDisplayName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string bundle_rule_id = 1;
 * @return {string}
 */
proto.type.BundledItem.prototype.getBundleRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setBundleRuleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bundle_rule_snapshot_id = 2;
 * @return {string}
 */
proto.type.BundledItem.prototype.getBundleRuleSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setBundleRuleSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.type.BundledItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PriceType price_type = 4;
 * @return {!proto.type.PriceType}
 */
proto.type.BundledItem.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BundleCondition applied_condition = 5;
 * @return {?proto.type.BundleCondition}
 */
proto.type.BundledItem.prototype.getAppliedCondition = function() {
  return /** @type{?proto.type.BundleCondition} */ (
    jspb.Message.getWrapperField(this, type_bundle_rule_pb.BundleCondition, 5));
};


/**
 * @param {?proto.type.BundleCondition|undefined} value
 * @return {!proto.type.BundledItem} returns this
*/
proto.type.BundledItem.prototype.setAppliedCondition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.clearAppliedCondition = function() {
  return this.setAppliedCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.BundledItem.prototype.hasAppliedCondition = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated BundleAppliedProduct applied_products = 6;
 * @return {!Array<!proto.type.BundleAppliedProduct>}
 */
proto.type.BundledItem.prototype.getAppliedProductsList = function() {
  return /** @type{!Array<!proto.type.BundleAppliedProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.BundleAppliedProduct, 6));
};


/**
 * @param {!Array<!proto.type.BundleAppliedProduct>} value
 * @return {!proto.type.BundledItem} returns this
*/
proto.type.BundledItem.prototype.setAppliedProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.BundleAppliedProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.BundleAppliedProduct}
 */
proto.type.BundledItem.prototype.addAppliedProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.BundleAppliedProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.clearAppliedProductsList = function() {
  return this.setAppliedProductsList([]);
};


/**
 * optional string bundle_rule_name = 7;
 * @return {string}
 */
proto.type.BundledItem.prototype.getBundleRuleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setBundleRuleName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional BundleType bundle_rule_type = 8;
 * @return {!proto.type.BundleType}
 */
proto.type.BundledItem.prototype.getBundleRuleType = function() {
  return /** @type {!proto.type.BundleType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.type.BundleType} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setBundleRuleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string bundle_type_display_name = 9;
 * @return {string}
 */
proto.type.BundledItem.prototype.getBundleTypeDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.BundledItem} returns this
 */
proto.type.BundledItem.prototype.setBundleTypeDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.type);
