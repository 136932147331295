// source: stailer/externaladminrpc/ec_product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_ec_site_pb = require('../../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var stailer_externaladminrpc_ec_site_category_pb = require('../../stailer/externaladminrpc/ec_site_category_pb.js');
goog.object.extend(proto, stailer_externaladminrpc_ec_site_category_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var type_container_type_pb = require('../../type/container_type_pb.js');
goog.object.extend(proto, type_container_type_pb);
var type_ec_product_pb = require('../../type/ec_product_pb.js');
goog.object.extend(proto, type_ec_product_pb);
var type_price_type_pb = require('../../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_date_pb = require('../../type/date_pb.js');
goog.object.extend(proto, type_date_pb);
var type_delivery_limit_time_pb = require('../../type/delivery_limit_time_pb.js');
goog.object.extend(proto, type_delivery_limit_time_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.stailer_external_admin.EcProduct', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.AmountCase', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.AmountChange', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.ContainerTypeCase', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.DisplayStatus', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.PriceChange', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcProduct.Stock.QuantityLimitCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.EcProduct.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.EcProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcProduct.displayName = 'proto.stailer_external_admin.EcProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcProduct.Stock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.EcProduct.Stock.repeatedFields_, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_);
};
goog.inherits(proto.stailer_external_admin.EcProduct.Stock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcProduct.Stock.displayName = 'proto.stailer_external_admin.EcProduct.Stock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.EcProduct.Stock.PriceChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcProduct.Stock.PriceChange.displayName = 'proto.stailer_external_admin.EcProduct.Stock.PriceChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.EcProduct.Stock.AmountChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcProduct.Stock.AmountChange.displayName = 'proto.stailer_external_admin.EcProduct.Stock.AmountChange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.EcProduct.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stock: (f = msg.getStock()) && proto.stailer_external_admin.EcProduct.Stock.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    otherImageUrlsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcProduct as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcProduct.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.stock;

  /** @type {?|undefined} */
  this.createdAt;

  /** @type {?|undefined} */
  this.updatedAt;

  /** @type {?|undefined} */
  this.otherImageUrlsList;

  /** @type {?|undefined} */
  this.description;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcProduct.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcProduct}
 */
proto.stailer_external_admin.EcProduct.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcProduct();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.name != null && jspb.Message.setField(msg, 3, obj.name);
  obj.imageUrl != null && jspb.Message.setField(msg, 4, obj.imageUrl);
  obj.stock && jspb.Message.setWrapperField(
      msg, 5, proto.stailer_external_admin.EcProduct.Stock.fromObject(obj.stock));
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 6, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  obj.updatedAt && jspb.Message.setWrapperField(
      msg, 7, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.updatedAt));
  obj.otherImageUrlsList != null && jspb.Message.setField(msg, 8, obj.otherImageUrlsList);
  obj.description != null && jspb.Message.setField(msg, 9, obj.description);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcProduct}
 */
proto.stailer_external_admin.EcProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcProduct;
  return proto.stailer_external_admin.EcProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcProduct}
 */
proto.stailer_external_admin.EcProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = new proto.stailer_external_admin.EcProduct.Stock;
      reader.readMessage(value,proto.stailer_external_admin.EcProduct.Stock.deserializeBinaryFromReader);
      msg.setStock(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherImageUrls(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStock();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.stailer_external_admin.EcProduct.Stock.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOtherImageUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.EcProduct.Stock.repeatedFields_ = [6,22,23,24];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.stailer_external_admin.EcProduct.Stock.oneofGroups_ = [[10,11],[13,14],[15,16]];

/**
 * @enum {number}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountCase = {
  AMOUNT_NOT_SET: 0,
  AMOUNT_EMPTY: 10,
  AMOUNT_VALUE: 11
};

/**
 * @return {proto.stailer_external_admin.EcProduct.Stock.AmountCase}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getAmountCase = function() {
  return /** @type {proto.stailer_external_admin.EcProduct.Stock.AmountCase} */(jspb.Message.computeOneofCase(this, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.stailer_external_admin.EcProduct.Stock.QuantityLimitCase = {
  QUANTITY_LIMIT_NOT_SET: 0,
  QUANTITY_LIMIT_EMPTY: 13,
  QUANTITY_LIMIT_VALUE: 14
};

/**
 * @return {proto.stailer_external_admin.EcProduct.Stock.QuantityLimitCase}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getQuantityLimitCase = function() {
  return /** @type {proto.stailer_external_admin.EcProduct.Stock.QuantityLimitCase} */(jspb.Message.computeOneofCase(this, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[1]));
};

/**
 * @enum {number}
 */
proto.stailer_external_admin.EcProduct.Stock.ContainerTypeCase = {
  CONTAINER_TYPE_NOT_SET: 0,
  CONTAINER_TYPE_EMPTY: 15,
  CONTAINER_TYPE_VALUE: 16
};

/**
 * @return {proto.stailer_external_admin.EcProduct.Stock.ContainerTypeCase}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getContainerTypeCase = function() {
  return /** @type {proto.stailer_external_admin.EcProduct.Stock.ContainerTypeCase} */(jspb.Message.computeOneofCase(this, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[2]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcProduct.Stock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcProduct.Stock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shopId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    noTaxPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    price: jspb.Message.getFieldWithDefault(msg, 18, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    ecSiteCategoriesList: jspb.Message.toObjectList(msg.getEcSiteCategoriesList(),
    stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    displayStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    amountEmpty: (f = msg.getAmountEmpty()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    amountValue: jspb.Message.getFieldWithDefault(msg, 11, 0),
    isNonStandardBucket: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    quantityLimitEmpty: (f = msg.getQuantityLimitEmpty()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    quantityLimitValue: jspb.Message.getFieldWithDefault(msg, 14, 0),
    containerTypeEmpty: (f = msg.getContainerTypeEmpty()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    containerTypeValue: jspb.Message.getFieldWithDefault(msg, 16, 0),
    updateStatusReason: jspb.Message.getFieldWithDefault(msg, 17, ""),
    deliveryLimitDateFrom: (f = msg.getDeliveryLimitDateFrom()) && type_date_pb.Date.toObject(includeInstance, f),
    deliveryLimitDateTo: (f = msg.getDeliveryLimitDateTo()) && type_date_pb.Date.toObject(includeInstance, f),
    priceChangesList: jspb.Message.toObjectList(msg.getPriceChangesList(),
    proto.stailer_external_admin.EcProduct.Stock.PriceChange.toObject, includeInstance),
    deliveryLimitTimesList: jspb.Message.toObjectList(msg.getDeliveryLimitTimesList(),
    type_delivery_limit_time_pb.DeliveryLimitTime.toObject, includeInstance),
    amountChangesList: jspb.Message.toObjectList(msg.getAmountChangesList(),
    proto.stailer_external_admin.EcProduct.Stock.AmountChange.toObject, includeInstance),
    drugRiskClassification: jspb.Message.getFieldWithDefault(msg, 25, 0),
    approximateTaxIncludedPrice: jspb.Message.getFieldWithDefault(msg, 26, ""),
    approximateTaxExcludedPrice: jspb.Message.getFieldWithDefault(msg, 27, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Stock as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcProduct.Stock.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.shopName;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.ecSiteCategoriesList;

  /** @type {?|undefined} */
  this.createdAt;

  /** @type {?|undefined} */
  this.updatedAt;

  /** @type {?|undefined} */
  this.displayStatus;

  /** @type {?|undefined} */
  this.amountEmpty;

  /** @type {?|undefined} */
  this.amountValue;

  /** @type {?|undefined} */
  this.isNonStandardBucket;

  /** @type {?|undefined} */
  this.quantityLimitEmpty;

  /** @type {?|undefined} */
  this.quantityLimitValue;

  /** @type {?|undefined} */
  this.containerTypeEmpty;

  /** @type {?|undefined} */
  this.containerTypeValue;

  /** @type {?|undefined} */
  this.updateStatusReason;

  /** @type {?|undefined} */
  this.deliveryLimitDateFrom;

  /** @type {?|undefined} */
  this.deliveryLimitDateTo;

  /** @type {?|undefined} */
  this.priceChangesList;

  /** @type {?|undefined} */
  this.deliveryLimitTimesList;

  /** @type {?|undefined} */
  this.amountChangesList;

  /** @type {?|undefined} */
  this.drugRiskClassification;

  /** @type {?|undefined} */
  this.approximateTaxIncludedPrice;

  /** @type {?|undefined} */
  this.approximateTaxExcludedPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcProduct.Stock}
 */
proto.stailer_external_admin.EcProduct.Stock.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcProduct.Stock();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.shopId != null && jspb.Message.setField(msg, 2, obj.shopId);
  obj.shopName != null && jspb.Message.setField(msg, 3, obj.shopName);
  obj.inTaxPrice != null && jspb.Message.setField(msg, 4, obj.inTaxPrice);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 5, obj.noTaxPrice);
  obj.price != null && jspb.Message.setField(msg, 18, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 19, obj.priceType);
  obj.ecSiteCategoriesList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.ecSiteCategoriesList.map(
          stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory.fromObject));
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 7, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  obj.updatedAt && jspb.Message.setWrapperField(
      msg, 8, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.updatedAt));
  obj.displayStatus != null && jspb.Message.setField(msg, 9, obj.displayStatus);
  obj.amountEmpty && jspb.Message.setWrapperField(
      msg, 10, google_protobuf_empty_pb.Empty.fromObject(obj.amountEmpty));
  obj.amountValue != null && jspb.Message.setField(msg, 11, obj.amountValue);
  obj.isNonStandardBucket != null && jspb.Message.setField(msg, 12, obj.isNonStandardBucket);
  obj.quantityLimitEmpty && jspb.Message.setWrapperField(
      msg, 13, google_protobuf_empty_pb.Empty.fromObject(obj.quantityLimitEmpty));
  obj.quantityLimitValue != null && jspb.Message.setField(msg, 14, obj.quantityLimitValue);
  obj.containerTypeEmpty && jspb.Message.setWrapperField(
      msg, 15, google_protobuf_empty_pb.Empty.fromObject(obj.containerTypeEmpty));
  obj.containerTypeValue != null && jspb.Message.setField(msg, 16, obj.containerTypeValue);
  obj.updateStatusReason != null && jspb.Message.setField(msg, 17, obj.updateStatusReason);
  obj.deliveryLimitDateFrom && jspb.Message.setWrapperField(
      msg, 20, type_date_pb.Date.fromObject(obj.deliveryLimitDateFrom));
  obj.deliveryLimitDateTo && jspb.Message.setWrapperField(
      msg, 21, type_date_pb.Date.fromObject(obj.deliveryLimitDateTo));
  obj.priceChangesList && jspb.Message.setRepeatedWrapperField(
      msg, 22, obj.priceChangesList.map(
          proto.stailer_external_admin.EcProduct.Stock.PriceChange.fromObject));
  obj.deliveryLimitTimesList && jspb.Message.setRepeatedWrapperField(
      msg, 23, obj.deliveryLimitTimesList.map(
          type_delivery_limit_time_pb.DeliveryLimitTime.fromObject));
  obj.amountChangesList && jspb.Message.setRepeatedWrapperField(
      msg, 24, obj.amountChangesList.map(
          proto.stailer_external_admin.EcProduct.Stock.AmountChange.fromObject));
  obj.drugRiskClassification != null && jspb.Message.setField(msg, 25, obj.drugRiskClassification);
  obj.approximateTaxIncludedPrice != null && jspb.Message.setField(msg, 26, obj.approximateTaxIncludedPrice);
  obj.approximateTaxExcludedPrice != null && jspb.Message.setField(msg, 27, obj.approximateTaxExcludedPrice);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcProduct.Stock}
 */
proto.stailer_external_admin.EcProduct.Stock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcProduct.Stock;
  return proto.stailer_external_admin.EcProduct.Stock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcProduct.Stock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcProduct.Stock}
 */
proto.stailer_external_admin.EcProduct.Stock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxPrice(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 19:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 6:
      var value = new stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory;
      reader.readMessage(value,stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory.deserializeBinaryFromReader);
      msg.addEcSiteCategories(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {!proto.stailer_external_admin.EcProduct.Stock.DisplayStatus} */ (reader.readEnum());
      msg.setDisplayStatus(value);
      break;
    case 10:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setAmountEmpty(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountValue(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNonStandardBucket(value);
      break;
    case 13:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setQuantityLimitEmpty(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantityLimitValue(value);
      break;
    case 15:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setContainerTypeEmpty(value);
      break;
    case 16:
      var value = /** @type {!proto.type.ContainerType} */ (reader.readEnum());
      msg.setContainerTypeValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateStatusReason(value);
      break;
    case 20:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeliveryLimitDateFrom(value);
      break;
    case 21:
      var value = new type_date_pb.Date;
      reader.readMessage(value,type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeliveryLimitDateTo(value);
      break;
    case 22:
      var value = new proto.stailer_external_admin.EcProduct.Stock.PriceChange;
      reader.readMessage(value,proto.stailer_external_admin.EcProduct.Stock.PriceChange.deserializeBinaryFromReader);
      msg.addPriceChanges(value);
      break;
    case 23:
      var value = new type_delivery_limit_time_pb.DeliveryLimitTime;
      reader.readMessage(value,type_delivery_limit_time_pb.DeliveryLimitTime.deserializeBinaryFromReader);
      msg.addDeliveryLimitTimes(value);
      break;
    case 24:
      var value = new proto.stailer_external_admin.EcProduct.Stock.AmountChange;
      reader.readMessage(value,proto.stailer_external_admin.EcProduct.Stock.AmountChange.deserializeBinaryFromReader);
      msg.addAmountChanges(value);
      break;
    case 25:
      var value = /** @type {!proto.type.DrugRiskClassification} */ (reader.readEnum());
      msg.setDrugRiskClassification(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxIncludedPrice(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateTaxExcludedPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcProduct.Stock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcProduct.Stock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getEcSiteCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisplayStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAmountEmpty();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getIsNonStandardBucket();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getQuantityLimitEmpty();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getContainerTypeEmpty();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.type.ContainerType} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getUpdateStatusReason();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDeliveryLimitDateFrom();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryLimitDateTo();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPriceChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.stailer_external_admin.EcProduct.Stock.PriceChange.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryLimitTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      type_delivery_limit_time_pb.DeliveryLimitTime.serializeBinaryToWriter
    );
  }
  f = message.getAmountChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.stailer_external_admin.EcProduct.Stock.AmountChange.serializeBinaryToWriter
    );
  }
  f = message.getDrugRiskClassification();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getApproximateTaxIncludedPrice();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getApproximateTaxExcludedPrice();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.stailer_external_admin.EcProduct.Stock.DisplayStatus = {
  FOR_SALE: 0,
  SOLD_OUT: 1,
  HIDDEN: 2,
  DEPRECATED_CONFIRMED: 3,
  DEPRECATED_CONFIRMATION_REQUIRED: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcProduct.Stock.PriceChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    quantityLimit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    brand: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PriceChange as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.start;

  /** @type {?|undefined} */
  this.end;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.quantityLimit;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.brand;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.PriceChange.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcProduct.Stock.PriceChange();
  obj.start && jspb.Message.setWrapperField(
      msg, 1, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.start));
  obj.end && jspb.Message.setWrapperField(
      msg, 2, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.end));
  obj.price != null && jspb.Message.setField(msg, 3, obj.price);
  obj.quantityLimit != null && jspb.Message.setField(msg, 5, obj.quantityLimit);
  obj.productName != null && jspb.Message.setField(msg, 6, obj.productName);
  obj.brand != null && jspb.Message.setField(msg, 7, obj.brand);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcProduct.Stock.PriceChange;
  return proto.stailer_external_admin.EcProduct.Stock.PriceChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcProduct.Stock.PriceChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getQuantityLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBrand();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 quantity_limit = 5;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getQuantityLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setQuantityLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string product_name = 6;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 brand = 7;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.getBrand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.PriceChange.prototype.setBrand = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcProduct.Stock.AmountChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of AmountChange as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.start;

  /** @type {?|undefined} */
  this.end;

  /** @type {?|undefined} */
  this.amount;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.AmountChange.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcProduct.Stock.AmountChange();
  obj.start && jspb.Message.setWrapperField(
      msg, 1, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.start));
  obj.end && jspb.Message.setWrapperField(
      msg, 2, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.end));
  obj.amount && jspb.Message.setWrapperField(
      msg, 3, google_protobuf_wrappers_pb.Int32Value.fromObject(obj.amount));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcProduct.Stock.AmountChange;
  return proto.stailer_external_admin.EcProduct.Stock.AmountChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcProduct.Stock.AmountChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value amount = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.AmountChange.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop_id = 2;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shop_name = 3;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double in_tax_price = 4;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 no_tax_price = 5;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 price = 18;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional type.PriceType price_type = 19;
 * @return {!proto.type.PriceType}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * repeated EcSiteCategory ec_site_categories = 6;
 * @return {!Array<!proto.stailer_external_admin.EcSiteCategory>}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getEcSiteCategoriesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.EcSiteCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, stailer_externaladminrpc_ec_site_category_pb.EcSiteCategory, 6));
};


/**
 * @param {!Array<!proto.stailer_external_admin.EcSiteCategory>} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setEcSiteCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.stailer_external_admin.EcSiteCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcSiteCategory}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.addEcSiteCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.stailer_external_admin.EcSiteCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearEcSiteCategoriesList = function() {
  return this.setEcSiteCategoriesList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DisplayStatus display_status = 9;
 * @return {!proto.stailer_external_admin.EcProduct.Stock.DisplayStatus}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getDisplayStatus = function() {
  return /** @type {!proto.stailer_external_admin.EcProduct.Stock.DisplayStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.stailer_external_admin.EcProduct.Stock.DisplayStatus} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setDisplayStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.Empty amount_empty = 10;
 * @return {?proto.google.protobuf.Empty}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getAmountEmpty = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 10));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setAmountEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearAmountEmpty = function() {
  return this.setAmountEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasAmountEmpty = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 amount_value = 11;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getAmountValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setAmountValue = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearAmountValue = function() {
  return jspb.Message.setOneofField(this, 11, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasAmountValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool is_non_standard_bucket = 12;
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getIsNonStandardBucket = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setIsNonStandardBucket = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional google.protobuf.Empty quantity_limit_empty = 13;
 * @return {?proto.google.protobuf.Empty}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getQuantityLimitEmpty = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 13));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setQuantityLimitEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearQuantityLimitEmpty = function() {
  return this.setQuantityLimitEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasQuantityLimitEmpty = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 quantity_limit_value = 14;
 * @return {number}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getQuantityLimitValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setQuantityLimitValue = function(value) {
  return jspb.Message.setOneofField(this, 14, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearQuantityLimitValue = function() {
  return jspb.Message.setOneofField(this, 14, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasQuantityLimitValue = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Empty container_type_empty = 15;
 * @return {?proto.google.protobuf.Empty}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getContainerTypeEmpty = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 15));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setContainerTypeEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[2], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearContainerTypeEmpty = function() {
  return this.setContainerTypeEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasContainerTypeEmpty = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional type.ContainerType container_type_value = 16;
 * @return {!proto.type.ContainerType}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getContainerTypeValue = function() {
  return /** @type {!proto.type.ContainerType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.type.ContainerType} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setContainerTypeValue = function(value) {
  return jspb.Message.setOneofField(this, 16, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[2], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearContainerTypeValue = function() {
  return jspb.Message.setOneofField(this, 16, proto.stailer_external_admin.EcProduct.Stock.oneofGroups_[2], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasContainerTypeValue = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string update_status_reason = 17;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getUpdateStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setUpdateStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional type.Date delivery_limit_date_from = 20;
 * @return {?proto.type.Date}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getDeliveryLimitDateFrom = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 20));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setDeliveryLimitDateFrom = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearDeliveryLimitDateFrom = function() {
  return this.setDeliveryLimitDateFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasDeliveryLimitDateFrom = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional type.Date delivery_limit_date_to = 21;
 * @return {?proto.type.Date}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getDeliveryLimitDateTo = function() {
  return /** @type{?proto.type.Date} */ (
    jspb.Message.getWrapperField(this, type_date_pb.Date, 21));
};


/**
 * @param {?proto.type.Date|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setDeliveryLimitDateTo = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearDeliveryLimitDateTo = function() {
  return this.setDeliveryLimitDateTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.hasDeliveryLimitDateTo = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated PriceChange price_changes = 22;
 * @return {!Array<!proto.stailer_external_admin.EcProduct.Stock.PriceChange>}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getPriceChangesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.EcProduct.Stock.PriceChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.EcProduct.Stock.PriceChange, 22));
};


/**
 * @param {!Array<!proto.stailer_external_admin.EcProduct.Stock.PriceChange>} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setPriceChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.stailer_external_admin.EcProduct.Stock.PriceChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcProduct.Stock.PriceChange}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.addPriceChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.stailer_external_admin.EcProduct.Stock.PriceChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearPriceChangesList = function() {
  return this.setPriceChangesList([]);
};


/**
 * repeated type.DeliveryLimitTime delivery_limit_times = 23;
 * @return {!Array<!proto.type.DeliveryLimitTime>}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getDeliveryLimitTimesList = function() {
  return /** @type{!Array<!proto.type.DeliveryLimitTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_delivery_limit_time_pb.DeliveryLimitTime, 23));
};


/**
 * @param {!Array<!proto.type.DeliveryLimitTime>} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setDeliveryLimitTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.type.DeliveryLimitTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryLimitTime}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.addDeliveryLimitTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.type.DeliveryLimitTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearDeliveryLimitTimesList = function() {
  return this.setDeliveryLimitTimesList([]);
};


/**
 * repeated AmountChange amount_changes = 24;
 * @return {!Array<!proto.stailer_external_admin.EcProduct.Stock.AmountChange>}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getAmountChangesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.EcProduct.Stock.AmountChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.EcProduct.Stock.AmountChange, 24));
};


/**
 * @param {!Array<!proto.stailer_external_admin.EcProduct.Stock.AmountChange>} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
*/
proto.stailer_external_admin.EcProduct.Stock.prototype.setAmountChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.stailer_external_admin.EcProduct.Stock.AmountChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcProduct.Stock.AmountChange}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.addAmountChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.stailer_external_admin.EcProduct.Stock.AmountChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.clearAmountChangesList = function() {
  return this.setAmountChangesList([]);
};


/**
 * optional type.DrugRiskClassification drug_risk_classification = 25;
 * @return {!proto.type.DrugRiskClassification}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getDrugRiskClassification = function() {
  return /** @type {!proto.type.DrugRiskClassification} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.type.DrugRiskClassification} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setDrugRiskClassification = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional string approximate_tax_included_price = 26;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getApproximateTaxIncludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setApproximateTaxIncludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string approximate_tax_excluded_price = 27;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.getApproximateTaxExcludedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct.Stock} returns this
 */
proto.stailer_external_admin.EcProduct.Stock.prototype.setApproximateTaxExcludedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional type.EcSite.SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.stailer_external_admin.EcProduct.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Stock stock = 5;
 * @return {?proto.stailer_external_admin.EcProduct.Stock}
 */
proto.stailer_external_admin.EcProduct.prototype.getStock = function() {
  return /** @type{?proto.stailer_external_admin.EcProduct.Stock} */ (
    jspb.Message.getWrapperField(this, proto.stailer_external_admin.EcProduct.Stock, 5));
};


/**
 * @param {?proto.stailer_external_admin.EcProduct.Stock|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
*/
proto.stailer_external_admin.EcProduct.prototype.setStock = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.clearStock = function() {
  return this.setStock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.prototype.hasStock = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
*/
proto.stailer_external_admin.EcProduct.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcProduct.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
*/
proto.stailer_external_admin.EcProduct.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcProduct.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string other_image_urls = 8;
 * @return {!Array<string>}
 */
proto.stailer_external_admin.EcProduct.prototype.getOtherImageUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setOtherImageUrlsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.addOtherImageUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.clearOtherImageUrlsList = function() {
  return this.setOtherImageUrlsList([]);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.stailer_external_admin.EcProduct.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcProduct} returns this
 */
proto.stailer_external_admin.EcProduct.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.stailer_external_admin);
