
import { Component, Vue } from 'nuxt-property-decorator'
import { FeatureSection } from '~/models/feature'
import * as feature from '~/models/feature'
import { getPartnerConfig } from '~/models/partner_config'

@Component({
  middleware: ['auth'],
})
export default class extends Vue {
  userActionOccuredChannel: BroadcastChannel | null =
    typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel('userActionOccured') : null

  created() {
    this.$nextTick(() => {
      const user = this.user
      if (user == null) {
        return
      }
      // 一定時間操作がなかった場合に自動でログアウトする
      const timeout = getPartnerConfig(user.siteType).sessionTimeoutMs
      let timerId: number

      const resetTimeout = () => {
        window.clearTimeout(timerId)
        timerId = window.setTimeout(autoLogout, timeout)
      }
      const autoLogout = async () => {
        try {
          await this.$signOut()
          await this.$session.clearStatus()
        } catch (e) {}
        location.replace('/signIn')
      }

      timerId = window.setTimeout(autoLogout, timeout)
      if (this.userActionOccuredChannel) {
        this.userActionOccuredChannel.onmessage = (e) => {
          if (e.origin !== window.location.origin) {
            return
          }
          resetTimeout()
        }
      }
      window.localStorage.setItem('lastUserActionAt', new Date().getTime().toString())
      this.$el.addEventListener('mousedown', () => {
        this.userActionOccuredChannel?.postMessage(null)
        window.localStorage.setItem('lastUserActionAt', new Date().getTime().toString())
        resetTimeout()
      })
    })
    this.$session.changedChannel.addEventListener('message', this.onSessionChanged)
  }

  beforeDestroy() {
    this.userActionOccuredChannel?.close()
    this.$session.changedChannel.removeEventListener('message', this.onSessionChanged)
  }

  onSessionChanged(e: MessageEvent) {
    // 他のタブでセッションが変わったら（ログアウトされたら）リロードする
    if (e.origin !== window.location.origin) {
      return
    }
    if (e.data == null || e.data !== this.$session.signedInUser?.id) {
      window.location.reload()
    }
  }

  async logout() {
    await this.$signOut()
    await this.$session.clearStatus()
    alert('ログアウトしました')
    location.replace('/signIn')
  }

  get navType() {
    if (this.$isProduction()) {
      return 'is-dark'
    }
    return 'is-info-dark'
  }

  get user() {
    return this.$session.signedInUser
  }

  get companyLogo() {
    if (this.user) {
      return this.user.companyLogo
    }
    return ''
  }

  get companyName() {
    if (this.user) {
      return this.user.companyName
    }
    return ''
  }

  get mainFeatureSections(): FeatureSection[] {
    const user = this.$session.signedInUser
    if (user == null) {
      return []
    }
    return feature.getNavbarMainFeatures(user.siteType, user.role)
  }

  get otherFeatureSections(): FeatureSection[] {
    const user = this.$session.signedInUser
    if (user == null) {
      return []
    }
    return feature.getNavbarOtherFeatures(user.siteType, user.role)
  }
}
