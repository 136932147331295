// source: type/coupon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_ec_product_pb = require('../type/ec_product_pb.js');
goog.object.extend(proto, type_ec_product_pb);
var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
goog.exportSymbol('proto.type.AppliedCoupon', null, global);
goog.exportSymbol('proto.type.ApplyCouponError', null, global);
goog.exportSymbol('proto.type.ApplyCouponError.Type', null, global);
goog.exportSymbol('proto.type.Coupon', null, global);
goog.exportSymbol('proto.type.Coupon.Type', null, global);
goog.exportSymbol('proto.type.CouponPlan', null, global);
goog.exportSymbol('proto.type.OwnedCoupon', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Coupon.displayName = 'proto.type.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.CouponPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.CouponPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.CouponPlan.displayName = 'proto.type.CouponPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.OwnedCoupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.OwnedCoupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.OwnedCoupon.displayName = 'proto.type.OwnedCoupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.AppliedCoupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.AppliedCoupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.AppliedCoupon.displayName = 'proto.type.AppliedCoupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.ApplyCouponError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.ApplyCouponError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.ApplyCouponError.displayName = 'proto.type.ApplyCouponError';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    applicableCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    benefitDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appliedConditionDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    initialPublishedAt: (f = msg.getInitialPublishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    discountFee: jspb.Message.getFieldWithDefault(msg, 9, 0),
    discountPrice: jspb.Message.getFieldWithDefault(msg, 10, 0),
    usageLimit: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lowerLimitOrderPrice: jspb.Message.getFieldWithDefault(msg, 12, 0),
    upperLimitOrderPrice: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lowerLimitOrderCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    upperLimitOrderCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ecProduct: (f = msg.getEcProduct()) && type_ec_product_pb.EcProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Coupon as accepted by the `fromObject` method.
 * @record
 */
proto.type.Coupon.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.type;

  /** @type {?|undefined} */
  this.applicableCode;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.description;

  /** @type {?|undefined} */
  this.benefitDescription;

  /** @type {?|undefined} */
  this.appliedConditionDescription;

  /** @type {?|undefined} */
  this.initialPublishedAt;

  /** @type {?|undefined} */
  this.discountFee;

  /** @type {?|undefined} */
  this.discountPrice;

  /** @type {?|undefined} */
  this.usageLimit;

  /** @type {?|undefined} */
  this.lowerLimitOrderPrice;

  /** @type {?|undefined} */
  this.upperLimitOrderPrice;

  /** @type {?|undefined} */
  this.lowerLimitOrderCount;

  /** @type {?|undefined} */
  this.upperLimitOrderCount;

  /** @type {?|undefined} */
  this.startTime;

  /** @type {?|undefined} */
  this.endTime;

  /** @type {?|undefined} */
  this.ecProduct;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Coupon.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Coupon}
 */
proto.type.Coupon.fromObject = function(obj) {
  var msg = new proto.type.Coupon();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.type != null && jspb.Message.setField(msg, 2, obj.type);
  obj.applicableCode != null && jspb.Message.setField(msg, 3, obj.applicableCode);
  obj.name != null && jspb.Message.setField(msg, 4, obj.name);
  obj.description != null && jspb.Message.setField(msg, 5, obj.description);
  obj.benefitDescription != null && jspb.Message.setField(msg, 6, obj.benefitDescription);
  obj.appliedConditionDescription != null && jspb.Message.setField(msg, 7, obj.appliedConditionDescription);
  obj.initialPublishedAt && jspb.Message.setWrapperField(
      msg, 8, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.initialPublishedAt));
  obj.discountFee != null && jspb.Message.setField(msg, 9, obj.discountFee);
  obj.discountPrice != null && jspb.Message.setField(msg, 10, obj.discountPrice);
  obj.usageLimit != null && jspb.Message.setField(msg, 11, obj.usageLimit);
  obj.lowerLimitOrderPrice != null && jspb.Message.setField(msg, 12, obj.lowerLimitOrderPrice);
  obj.upperLimitOrderPrice != null && jspb.Message.setField(msg, 13, obj.upperLimitOrderPrice);
  obj.lowerLimitOrderCount != null && jspb.Message.setField(msg, 14, obj.lowerLimitOrderCount);
  obj.upperLimitOrderCount != null && jspb.Message.setField(msg, 15, obj.upperLimitOrderCount);
  obj.startTime && jspb.Message.setWrapperField(
      msg, 16, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.startTime));
  obj.endTime && jspb.Message.setWrapperField(
      msg, 17, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.endTime));
  obj.ecProduct && jspb.Message.setWrapperField(
      msg, 18, type_ec_product_pb.EcProduct.fromObject(obj.ecProduct));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Coupon}
 */
proto.type.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Coupon;
  return proto.type.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Coupon}
 */
proto.type.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {!proto.type.Coupon.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicableCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBenefitDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliedConditionDescription(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInitialPublishedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscountFee(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscountPrice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsageLimit(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerLimitOrderPrice(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpperLimitOrderPrice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerLimitOrderCount(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpperLimitOrderCount(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 18:
      var value = new type_ec_product_pb.EcProduct;
      reader.readMessage(value,type_ec_product_pb.EcProduct.deserializeBinaryFromReader);
      msg.setEcProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getApplicableCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBenefitDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppliedConditionDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInitialPublishedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDiscountFee();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDiscountPrice();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUsageLimit();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLowerLimitOrderPrice();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getUpperLimitOrderPrice();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getLowerLimitOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getUpperLimitOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEcProduct();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      type_ec_product_pb.EcProduct.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.type.Coupon.Type = {
  NONE: 0,
  DISCOUNT_FEE: 1,
  DISCOUNT_PRICE: 2
};

/**
 * optional EcSite.SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.Coupon.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.type.Coupon.Type}
 */
proto.type.Coupon.prototype.getType = function() {
  return /** @type {!proto.type.Coupon.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.Coupon.Type} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string applicable_code = 3;
 * @return {string}
 */
proto.type.Coupon.prototype.getApplicableCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setApplicableCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.type.Coupon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.type.Coupon.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string benefit_description = 6;
 * @return {string}
 */
proto.type.Coupon.prototype.getBenefitDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setBenefitDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string applied_condition_description = 7;
 * @return {string}
 */
proto.type.Coupon.prototype.getAppliedConditionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setAppliedConditionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp initial_published_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Coupon.prototype.getInitialPublishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Coupon} returns this
*/
proto.type.Coupon.prototype.setInitialPublishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.clearInitialPublishedAt = function() {
  return this.setInitialPublishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Coupon.prototype.hasInitialPublishedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 discount_fee = 9;
 * @return {number}
 */
proto.type.Coupon.prototype.getDiscountFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setDiscountFee = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 discount_price = 10;
 * @return {number}
 */
proto.type.Coupon.prototype.getDiscountPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setDiscountPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 usage_limit = 11;
 * @return {number}
 */
proto.type.Coupon.prototype.getUsageLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setUsageLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 lower_limit_order_price = 12;
 * @return {number}
 */
proto.type.Coupon.prototype.getLowerLimitOrderPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setLowerLimitOrderPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 upper_limit_order_price = 13;
 * @return {number}
 */
proto.type.Coupon.prototype.getUpperLimitOrderPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setUpperLimitOrderPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 lower_limit_order_count = 14;
 * @return {number}
 */
proto.type.Coupon.prototype.getLowerLimitOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setLowerLimitOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 upper_limit_order_count = 15;
 * @return {number}
 */
proto.type.Coupon.prototype.getUpperLimitOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.setUpperLimitOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Coupon.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Coupon} returns this
*/
proto.type.Coupon.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Coupon.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.Coupon.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.Coupon} returns this
*/
proto.type.Coupon.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Coupon.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional EcProduct ec_product = 18;
 * @return {?proto.type.EcProduct}
 */
proto.type.Coupon.prototype.getEcProduct = function() {
  return /** @type{?proto.type.EcProduct} */ (
    jspb.Message.getWrapperField(this, type_ec_product_pb.EcProduct, 18));
};


/**
 * @param {?proto.type.EcProduct|undefined} value
 * @return {!proto.type.Coupon} returns this
*/
proto.type.Coupon.prototype.setEcProduct = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Coupon} returns this
 */
proto.type.Coupon.prototype.clearEcProduct = function() {
  return this.setEcProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Coupon.prototype.hasEcProduct = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.CouponPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.type.CouponPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.CouponPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.CouponPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    applicableCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isPublished: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    distributionLimit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    distributionCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    initialPublishedAt: (f = msg.getInitialPublishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coupon: (f = msg.getCoupon()) && proto.type.Coupon.toObject(includeInstance, f),
    isLowerLimitOrderPrice: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isUpperLimitOrderPrice: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isLowerLimitOrderCount: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isUpperLimitOrderCount: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isProductLimit: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CouponPlan as accepted by the `fromObject` method.
 * @record
 */
proto.type.CouponPlan.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.applicableCode;

  /** @type {?|undefined} */
  this.isPublished;

  /** @type {?|undefined} */
  this.distributionLimit;

  /** @type {?|undefined} */
  this.distributionCount;

  /** @type {?|undefined} */
  this.initialPublishedAt;

  /** @type {?|undefined} */
  this.createdAt;

  /** @type {?|undefined} */
  this.coupon;

  /** @type {?|undefined} */
  this.isLowerLimitOrderPrice;

  /** @type {?|undefined} */
  this.isUpperLimitOrderPrice;

  /** @type {?|undefined} */
  this.isLowerLimitOrderCount;

  /** @type {?|undefined} */
  this.isUpperLimitOrderCount;

  /** @type {?|undefined} */
  this.isProductLimit;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.CouponPlan.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.CouponPlan}
 */
proto.type.CouponPlan.fromObject = function(obj) {
  var msg = new proto.type.CouponPlan();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.siteType != null && jspb.Message.setField(msg, 2, obj.siteType);
  obj.applicableCode != null && jspb.Message.setField(msg, 3, obj.applicableCode);
  obj.isPublished != null && jspb.Message.setField(msg, 4, obj.isPublished);
  obj.distributionLimit != null && jspb.Message.setField(msg, 5, obj.distributionLimit);
  obj.distributionCount != null && jspb.Message.setField(msg, 6, obj.distributionCount);
  obj.initialPublishedAt && jspb.Message.setWrapperField(
      msg, 7, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.initialPublishedAt));
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 8, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  obj.coupon && jspb.Message.setWrapperField(
      msg, 9, proto.type.Coupon.fromObject(obj.coupon));
  obj.isLowerLimitOrderPrice != null && jspb.Message.setField(msg, 10, obj.isLowerLimitOrderPrice);
  obj.isUpperLimitOrderPrice != null && jspb.Message.setField(msg, 11, obj.isUpperLimitOrderPrice);
  obj.isLowerLimitOrderCount != null && jspb.Message.setField(msg, 12, obj.isLowerLimitOrderCount);
  obj.isUpperLimitOrderCount != null && jspb.Message.setField(msg, 13, obj.isUpperLimitOrderCount);
  obj.isProductLimit != null && jspb.Message.setField(msg, 14, obj.isProductLimit);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.CouponPlan}
 */
proto.type.CouponPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.CouponPlan;
  return proto.type.CouponPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.CouponPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.CouponPlan}
 */
proto.type.CouponPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicableCode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublished(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistributionLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistributionCount(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInitialPublishedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = new proto.type.Coupon;
      reader.readMessage(value,proto.type.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLowerLimitOrderPrice(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpperLimitOrderPrice(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLowerLimitOrderCount(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpperLimitOrderCount(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProductLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.CouponPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.CouponPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.CouponPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.CouponPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getApplicableCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsPublished();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDistributionLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDistributionCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getInitialPublishedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.type.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getIsLowerLimitOrderPrice();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsUpperLimitOrderPrice();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsLowerLimitOrderCount();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsUpperLimitOrderCount();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsProductLimit();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.type.CouponPlan.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EcSite.SiteType site_type = 2;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.CouponPlan.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string applicable_code = 3;
 * @return {string}
 */
proto.type.CouponPlan.prototype.getApplicableCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setApplicableCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_published = 4;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 distribution_limit = 5;
 * @return {number}
 */
proto.type.CouponPlan.prototype.getDistributionLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setDistributionLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 distribution_count = 6;
 * @return {number}
 */
proto.type.CouponPlan.prototype.getDistributionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setDistributionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp initial_published_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.CouponPlan.prototype.getInitialPublishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.CouponPlan} returns this
*/
proto.type.CouponPlan.prototype.setInitialPublishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.clearInitialPublishedAt = function() {
  return this.setInitialPublishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.hasInitialPublishedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.CouponPlan.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.CouponPlan} returns this
*/
proto.type.CouponPlan.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Coupon coupon = 9;
 * @return {?proto.type.Coupon}
 */
proto.type.CouponPlan.prototype.getCoupon = function() {
  return /** @type{?proto.type.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.type.Coupon, 9));
};


/**
 * @param {?proto.type.Coupon|undefined} value
 * @return {!proto.type.CouponPlan} returns this
*/
proto.type.CouponPlan.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_lower_limit_order_price = 10;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsLowerLimitOrderPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsLowerLimitOrderPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_upper_limit_order_price = 11;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsUpperLimitOrderPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsUpperLimitOrderPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_lower_limit_order_count = 12;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsLowerLimitOrderCount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsLowerLimitOrderCount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_upper_limit_order_count = 13;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsUpperLimitOrderCount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsUpperLimitOrderCount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_product_limit = 14;
 * @return {boolean}
 */
proto.type.CouponPlan.prototype.getIsProductLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.CouponPlan} returns this
 */
proto.type.CouponPlan.prototype.setIsProductLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.OwnedCoupon.prototype.toObject = function(opt_includeInstance) {
  return proto.type.OwnedCoupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.OwnedCoupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.OwnedCoupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coupon: (f = msg.getCoupon()) && proto.type.Coupon.toObject(includeInstance, f),
    usageCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of OwnedCoupon as accepted by the `fromObject` method.
 * @record
 */
proto.type.OwnedCoupon.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.userId;

  /** @type {?|undefined} */
  this.coupon;

  /** @type {?|undefined} */
  this.usageCount;

  /** @type {?|undefined} */
  this.createdAt;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.OwnedCoupon.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.OwnedCoupon}
 */
proto.type.OwnedCoupon.fromObject = function(obj) {
  var msg = new proto.type.OwnedCoupon();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.userId != null && jspb.Message.setField(msg, 2, obj.userId);
  obj.coupon && jspb.Message.setWrapperField(
      msg, 3, proto.type.Coupon.fromObject(obj.coupon));
  obj.usageCount != null && jspb.Message.setField(msg, 4, obj.usageCount);
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 5, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.OwnedCoupon}
 */
proto.type.OwnedCoupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.OwnedCoupon;
  return proto.type.OwnedCoupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.OwnedCoupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.OwnedCoupon}
 */
proto.type.OwnedCoupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new proto.type.Coupon;
      reader.readMessage(value,proto.type.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsageCount(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.OwnedCoupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.OwnedCoupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.OwnedCoupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.OwnedCoupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.type.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getUsageCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.type.OwnedCoupon.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OwnedCoupon} returns this
 */
proto.type.OwnedCoupon.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.type.OwnedCoupon.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OwnedCoupon} returns this
 */
proto.type.OwnedCoupon.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Coupon coupon = 3;
 * @return {?proto.type.Coupon}
 */
proto.type.OwnedCoupon.prototype.getCoupon = function() {
  return /** @type{?proto.type.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.type.Coupon, 3));
};


/**
 * @param {?proto.type.Coupon|undefined} value
 * @return {!proto.type.OwnedCoupon} returns this
*/
proto.type.OwnedCoupon.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.OwnedCoupon} returns this
 */
proto.type.OwnedCoupon.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.OwnedCoupon.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 usage_count = 4;
 * @return {number}
 */
proto.type.OwnedCoupon.prototype.getUsageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.OwnedCoupon} returns this
 */
proto.type.OwnedCoupon.prototype.setUsageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.OwnedCoupon.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.OwnedCoupon} returns this
*/
proto.type.OwnedCoupon.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.OwnedCoupon} returns this
 */
proto.type.OwnedCoupon.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.OwnedCoupon.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.AppliedCoupon.prototype.toObject = function(opt_includeInstance) {
  return proto.type.AppliedCoupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.AppliedCoupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.AppliedCoupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownedCouponId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    coupon: (f = msg.getCoupon()) && proto.type.Coupon.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of AppliedCoupon as accepted by the `fromObject` method.
 * @record
 */
proto.type.AppliedCoupon.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ownedCouponId;

  /** @type {?|undefined} */
  this.coupon;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.AppliedCoupon.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.AppliedCoupon}
 */
proto.type.AppliedCoupon.fromObject = function(obj) {
  var msg = new proto.type.AppliedCoupon();
  obj.ownedCouponId != null && jspb.Message.setField(msg, 1, obj.ownedCouponId);
  obj.coupon && jspb.Message.setWrapperField(
      msg, 2, proto.type.Coupon.fromObject(obj.coupon));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.AppliedCoupon}
 */
proto.type.AppliedCoupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.AppliedCoupon;
  return proto.type.AppliedCoupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.AppliedCoupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.AppliedCoupon}
 */
proto.type.AppliedCoupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnedCouponId(value);
      break;
    case 2:
      var value = new proto.type.Coupon;
      reader.readMessage(value,proto.type.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.AppliedCoupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.AppliedCoupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.AppliedCoupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.AppliedCoupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnedCouponId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.type.Coupon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string owned_coupon_id = 1;
 * @return {string}
 */
proto.type.AppliedCoupon.prototype.getOwnedCouponId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.AppliedCoupon} returns this
 */
proto.type.AppliedCoupon.prototype.setOwnedCouponId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Coupon coupon = 2;
 * @return {?proto.type.Coupon}
 */
proto.type.AppliedCoupon.prototype.getCoupon = function() {
  return /** @type{?proto.type.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.type.Coupon, 2));
};


/**
 * @param {?proto.type.Coupon|undefined} value
 * @return {!proto.type.AppliedCoupon} returns this
*/
proto.type.AppliedCoupon.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.AppliedCoupon} returns this
 */
proto.type.AppliedCoupon.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.AppliedCoupon.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.ApplyCouponError.prototype.toObject = function(opt_includeInstance) {
  return proto.type.ApplyCouponError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.ApplyCouponError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.ApplyCouponError.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ApplyCouponError as accepted by the `fromObject` method.
 * @record
 */
proto.type.ApplyCouponError.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.type;

  /** @type {?|undefined} */
  this.message;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.ApplyCouponError.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.ApplyCouponError}
 */
proto.type.ApplyCouponError.fromObject = function(obj) {
  var msg = new proto.type.ApplyCouponError();
  obj.type != null && jspb.Message.setField(msg, 1, obj.type);
  obj.message != null && jspb.Message.setField(msg, 2, obj.message);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.ApplyCouponError}
 */
proto.type.ApplyCouponError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.ApplyCouponError;
  return proto.type.ApplyCouponError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.ApplyCouponError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.ApplyCouponError}
 */
proto.type.ApplyCouponError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.ApplyCouponError.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.ApplyCouponError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.ApplyCouponError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.ApplyCouponError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.ApplyCouponError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.ApplyCouponError.Type = {
  UNKNOWN: 0,
  INVALID_USAGE_COUNT: 1,
  INVALID_APPLYING_ORDER: 2,
  INVALID_APPLY_TIME_RANGE: 3,
  INVALID_LOWER_LIMIT_ORDER_PRICE: 4,
  INVALID_UPPER_LIMIT_ORDER_PRICE: 5,
  INVALID_LOWER_LIMIT_ORDER_COUNT: 6,
  INVALID_UPPER_LIMIT_ORDER_COUNT: 7,
  INVALID_MEMBERSHIP: 8,
  INVALID_PRODUCT: 9
};

/**
 * optional Type type = 1;
 * @return {!proto.type.ApplyCouponError.Type}
 */
proto.type.ApplyCouponError.prototype.getType = function() {
  return /** @type {!proto.type.ApplyCouponError.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.ApplyCouponError.Type} value
 * @return {!proto.type.ApplyCouponError} returns this
 */
proto.type.ApplyCouponError.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.type.ApplyCouponError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ApplyCouponError} returns this
 */
proto.type.ApplyCouponError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.type);
