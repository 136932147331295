import { Middleware } from '@nuxt/types'
import { UserRole } from '~/types/user'

const OnlyAdminAndManagerMiddleware: Middleware = (context) => {
  if (process.server) {
    return
  }
  if (
    !context.app.$session.roleIncludedIn([UserRole.Admin, UserRole.Developer, UserRole.Manager])
  ) {
    context.redirect('/')
  }
}

export default OnlyAdminAndManagerMiddleware
