// source: type/recurring_order_setting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_price_pb = require('../type/price_pb.js');
goog.object.extend(proto, type_price_pb);
var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
goog.exportSymbol('proto.type.RecurringOrderSetting', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.DeliverySlotConstraint', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.DeliverySlotConstraint.ConstraintCase', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.OrderItem', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.PaymentMethod', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.PaymentMethod.IdCase', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.PaymentMethodType', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.RecurringCycle', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.RecurringCycle.CycleCase', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.RecurringDeliverySlot', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.RelativeWeeklyTime', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.Time', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.TimeRange', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.Weekday', null, global);
goog.exportSymbol('proto.type.RecurringOrderSetting.WeeklyRecurringCycle', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.RecurringOrderSetting.repeatedFields_, null);
};
goog.inherits(proto.type.RecurringOrderSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.displayName = 'proto.type.RecurringOrderSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.Time = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.Time, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.Time.displayName = 'proto.type.RecurringOrderSetting.Time';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.TimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.TimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.TimeRange.displayName = 'proto.type.RecurringOrderSetting.TimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.RelativeWeeklyTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.RelativeWeeklyTime.displayName = 'proto.type.RecurringOrderSetting.RelativeWeeklyTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.OrderItem.displayName = 'proto.type.RecurringOrderSetting.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_);
};
goog.inherits(proto.type.RecurringOrderSetting.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.PaymentMethod.displayName = 'proto.type.RecurringOrderSetting.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.RecurringCycle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.type.RecurringOrderSetting.RecurringCycle.oneofGroups_);
};
goog.inherits(proto.type.RecurringOrderSetting.RecurringCycle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.RecurringCycle.displayName = 'proto.type.RecurringOrderSetting.RecurringCycle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.WeeklyRecurringCycle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.WeeklyRecurringCycle.displayName = 'proto.type.RecurringOrderSetting.WeeklyRecurringCycle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.RecurringOrderSetting.RecurringDeliverySlot.repeatedFields_, null);
};
goog.inherits(proto.type.RecurringOrderSetting.RecurringDeliverySlot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.RecurringDeliverySlot.displayName = 'proto.type.RecurringOrderSetting.RecurringDeliverySlot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_);
};
goog.inherits(proto.type.RecurringOrderSetting.DeliverySlotConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.DeliverySlotConstraint.displayName = 'proto.type.RecurringOrderSetting.DeliverySlotConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.displayName = 'proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.displayName = 'proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.displayName = 'proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.RecurringOrderSetting.repeatedFields_ = [6,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    shopId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    deliveryStartAt: (f = msg.getDeliveryStartAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deliveryEndAt: (f = msg.getDeliveryEndAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    recurringCycle: (f = msg.getRecurringCycle()) && proto.type.RecurringOrderSetting.RecurringCycle.toObject(includeInstance, f),
    deliverySlotsList: jspb.Message.toObjectList(msg.getDeliverySlotsList(),
    proto.type.RecurringOrderSetting.RecurringDeliverySlot.toObject, includeInstance),
    orderItemsList: jspb.Message.toObjectList(msg.getOrderItemsList(),
    proto.type.RecurringOrderSetting.OrderItem.toObject, includeInstance),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.type.RecurringOrderSetting.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of RecurringOrderSetting as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.userId;

  /** @type {?|undefined} */
  this.isEnabled;

  /** @type {?|undefined} */
  this.deliveryStartAt;

  /** @type {?|undefined} */
  this.deliveryEndAt;

  /** @type {?|undefined} */
  this.recurringCycle;

  /** @type {?|undefined} */
  this.deliverySlotsList;

  /** @type {?|undefined} */
  this.orderItemsList;

  /** @type {?|undefined} */
  this.paymentMethod;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting}
 */
proto.type.RecurringOrderSetting.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.siteType != null && jspb.Message.setField(msg, 10, obj.siteType);
  obj.shopId != null && jspb.Message.setField(msg, 11, obj.shopId);
  obj.userId != null && jspb.Message.setField(msg, 2, obj.userId);
  obj.isEnabled != null && jspb.Message.setField(msg, 3, obj.isEnabled);
  obj.deliveryStartAt && jspb.Message.setWrapperField(
      msg, 8, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryStartAt));
  obj.deliveryEndAt && jspb.Message.setWrapperField(
      msg, 9, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.deliveryEndAt));
  obj.recurringCycle && jspb.Message.setWrapperField(
      msg, 4, proto.type.RecurringOrderSetting.RecurringCycle.fromObject(obj.recurringCycle));
  obj.deliverySlotsList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.deliverySlotsList.map(
          proto.type.RecurringOrderSetting.RecurringDeliverySlot.fromObject));
  obj.orderItemsList && jspb.Message.setRepeatedWrapperField(
      msg, 5, obj.orderItemsList.map(
          proto.type.RecurringOrderSetting.OrderItem.fromObject));
  obj.paymentMethod && jspb.Message.setWrapperField(
      msg, 12, proto.type.RecurringOrderSetting.PaymentMethod.fromObject(obj.paymentMethod));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting}
 */
proto.type.RecurringOrderSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting;
  return proto.type.RecurringOrderSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting}
 */
proto.type.RecurringOrderSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryStartAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryEndAt(value);
      break;
    case 4:
      var value = new proto.type.RecurringOrderSetting.RecurringCycle;
      reader.readMessage(value,proto.type.RecurringOrderSetting.RecurringCycle.deserializeBinaryFromReader);
      msg.setRecurringCycle(value);
      break;
    case 6:
      var value = new proto.type.RecurringOrderSetting.RecurringDeliverySlot;
      reader.readMessage(value,proto.type.RecurringOrderSetting.RecurringDeliverySlot.deserializeBinaryFromReader);
      msg.addDeliverySlots(value);
      break;
    case 5:
      var value = new proto.type.RecurringOrderSetting.OrderItem;
      reader.readMessage(value,proto.type.RecurringOrderSetting.OrderItem.deserializeBinaryFromReader);
      msg.addOrderItems(value);
      break;
    case 12:
      var value = new proto.type.RecurringOrderSetting.PaymentMethod;
      reader.readMessage(value,proto.type.RecurringOrderSetting.PaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDeliveryStartAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryEndAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRecurringCycle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.type.RecurringOrderSetting.RecurringCycle.serializeBinaryToWriter
    );
  }
  f = message.getDeliverySlotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.type.RecurringOrderSetting.RecurringDeliverySlot.serializeBinaryToWriter
    );
  }
  f = message.getOrderItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.type.RecurringOrderSetting.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.type.RecurringOrderSetting.PaymentMethod.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.type.RecurringOrderSetting.Weekday = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6
};

/**
 * @enum {number}
 */
proto.type.RecurringOrderSetting.PaymentMethodType = {
  CREDIT_CARD: 0,
  BANK_ACCOUNT_TRANSFER: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.Time.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.Time.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.Time} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.Time.toObject = function(includeInstance, msg) {
  var f, obj = {
    hour: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minute: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Time as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.Time.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.hour;

  /** @type {?|undefined} */
  this.minute;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.Time.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.Time.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.Time();
  obj.hour != null && jspb.Message.setField(msg, 1, obj.hour);
  obj.minute != null && jspb.Message.setField(msg, 2, obj.minute);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.Time.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.Time;
  return proto.type.RecurringOrderSetting.Time.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.Time} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.Time.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHour(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.Time.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.Time.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.Time} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.Time.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHour();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinute();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 hour = 1;
 * @return {number}
 */
proto.type.RecurringOrderSetting.Time.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.RecurringOrderSetting.Time} returns this
 */
proto.type.RecurringOrderSetting.Time.prototype.setHour = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 minute = 2;
 * @return {number}
 */
proto.type.RecurringOrderSetting.Time.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.RecurringOrderSetting.Time} returns this
 */
proto.type.RecurringOrderSetting.Time.prototype.setMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.TimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.TimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.TimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.type.RecurringOrderSetting.Time.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.type.RecurringOrderSetting.Time.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of TimeRange as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.TimeRange.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.start;

  /** @type {?|undefined} */
  this.end;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.TimeRange.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.TimeRange}
 */
proto.type.RecurringOrderSetting.TimeRange.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.TimeRange();
  obj.start && jspb.Message.setWrapperField(
      msg, 1, proto.type.RecurringOrderSetting.Time.fromObject(obj.start));
  obj.end && jspb.Message.setWrapperField(
      msg, 2, proto.type.RecurringOrderSetting.Time.fromObject(obj.end));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.TimeRange}
 */
proto.type.RecurringOrderSetting.TimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.TimeRange;
  return proto.type.RecurringOrderSetting.TimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.TimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.TimeRange}
 */
proto.type.RecurringOrderSetting.TimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.Time;
      reader.readMessage(value,proto.type.RecurringOrderSetting.Time.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.type.RecurringOrderSetting.Time;
      reader.readMessage(value,proto.type.RecurringOrderSetting.Time.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.TimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.TimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.TimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.Time.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.type.RecurringOrderSetting.Time.serializeBinaryToWriter
    );
  }
};


/**
 * optional Time start = 1;
 * @return {?proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.getStart = function() {
  return /** @type{?proto.type.RecurringOrderSetting.Time} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.Time, 1));
};


/**
 * @param {?proto.type.RecurringOrderSetting.Time|undefined} value
 * @return {!proto.type.RecurringOrderSetting.TimeRange} returns this
*/
proto.type.RecurringOrderSetting.TimeRange.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.TimeRange} returns this
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Time end = 2;
 * @return {?proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.getEnd = function() {
  return /** @type{?proto.type.RecurringOrderSetting.Time} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.Time, 2));
};


/**
 * @param {?proto.type.RecurringOrderSetting.Time|undefined} value
 * @return {!proto.type.RecurringOrderSetting.TimeRange} returns this
*/
proto.type.RecurringOrderSetting.TimeRange.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.TimeRange} returns this
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.TimeRange.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.RelativeWeeklyTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekOffset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weekday: jspb.Message.getFieldWithDefault(msg, 2, 0),
    time: (f = msg.getTime()) && proto.type.RecurringOrderSetting.Time.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of RelativeWeeklyTime as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.weekOffset;

  /** @type {?|undefined} */
  this.weekday;

  /** @type {?|undefined} */
  this.time;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.RelativeWeeklyTime.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.RelativeWeeklyTime();
  obj.weekOffset != null && jspb.Message.setField(msg, 1, obj.weekOffset);
  obj.weekday != null && jspb.Message.setField(msg, 2, obj.weekday);
  obj.time && jspb.Message.setWrapperField(
      msg, 3, proto.type.RecurringOrderSetting.Time.fromObject(obj.time));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.RelativeWeeklyTime;
  return proto.type.RecurringOrderSetting.RelativeWeeklyTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeekOffset(value);
      break;
    case 2:
      var value = /** @type {!proto.type.RecurringOrderSetting.Weekday} */ (reader.readEnum());
      msg.setWeekday(value);
      break;
    case 3:
      var value = new proto.type.RecurringOrderSetting.Time;
      reader.readMessage(value,proto.type.RecurringOrderSetting.Time.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.RelativeWeeklyTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekOffset();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWeekday();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.type.RecurringOrderSetting.Time.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 week_offset = 1;
 * @return {number}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.getWeekOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} returns this
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.setWeekOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Weekday weekday = 2;
 * @return {!proto.type.RecurringOrderSetting.Weekday}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.getWeekday = function() {
  return /** @type {!proto.type.RecurringOrderSetting.Weekday} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.RecurringOrderSetting.Weekday} value
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} returns this
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.setWeekday = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Time time = 3;
 * @return {?proto.type.RecurringOrderSetting.Time}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.getTime = function() {
  return /** @type{?proto.type.RecurringOrderSetting.Time} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.Time, 3));
};


/**
 * @param {?proto.type.RecurringOrderSetting.Time|undefined} value
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} returns this
*/
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.RelativeWeeklyTime} returns this
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.RelativeWeeklyTime.prototype.hasTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.OrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.OrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.OrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    ecProductId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: (f = msg.getPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of OrderItem as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.OrderItem.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ecProductId;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.quantity;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.OrderItem.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.OrderItem}
 */
proto.type.RecurringOrderSetting.OrderItem.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.OrderItem();
  obj.ecProductId != null && jspb.Message.setField(msg, 1, obj.ecProductId);
  obj.imageUrl != null && jspb.Message.setField(msg, 2, obj.imageUrl);
  obj.name != null && jspb.Message.setField(msg, 3, obj.name);
  obj.price && jspb.Message.setWrapperField(
      msg, 4, type_price_pb.Price.fromObject(obj.price));
  obj.quantity != null && jspb.Message.setField(msg, 5, obj.quantity);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.OrderItem}
 */
proto.type.RecurringOrderSetting.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.OrderItem;
  return proto.type.RecurringOrderSetting.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.OrderItem}
 */
proto.type.RecurringOrderSetting.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEcProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEcProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string ec_product_id = 1;
 * @return {string}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.getEcProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.setEcProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Price price = 4;
 * @return {?proto.type.Price}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.getPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 4));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
*/
proto.type.RecurringOrderSetting.OrderItem.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 quantity = 5;
 * @return {number}
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.RecurringOrderSetting.OrderItem} returns this
 */
proto.type.RecurringOrderSetting.OrderItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.type.RecurringOrderSetting.PaymentMethod.IdCase = {
  ID_NOT_SET: 0,
  CREDIT_CARD_ID: 2,
  BANK_ACCOUNT_ID: 3
};

/**
 * @return {proto.type.RecurringOrderSetting.PaymentMethod.IdCase}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.getIdCase = function() {
  return /** @type {proto.type.RecurringOrderSetting.PaymentMethod.IdCase} */(jspb.Message.computeOneofCase(this, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creditCardId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankAccountId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PaymentMethod as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.PaymentMethod.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.type;

  /** @type {?|undefined} */
  this.creditCardId;

  /** @type {?|undefined} */
  this.bankAccountId;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.PaymentMethod.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod}
 */
proto.type.RecurringOrderSetting.PaymentMethod.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.PaymentMethod();
  obj.type != null && jspb.Message.setField(msg, 1, obj.type);
  obj.creditCardId != null && jspb.Message.setField(msg, 2, obj.creditCardId);
  obj.bankAccountId != null && jspb.Message.setField(msg, 3, obj.bankAccountId);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod}
 */
proto.type.RecurringOrderSetting.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.PaymentMethod;
  return proto.type.RecurringOrderSetting.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod}
 */
proto.type.RecurringOrderSetting.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.RecurringOrderSetting.PaymentMethodType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCardId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional PaymentMethodType type = 1;
 * @return {!proto.type.RecurringOrderSetting.PaymentMethodType}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.getType = function() {
  return /** @type {!proto.type.RecurringOrderSetting.PaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.RecurringOrderSetting.PaymentMethodType} value
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod} returns this
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string credit_card_id = 2;
 * @return {string}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.getCreditCardId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod} returns this
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.setCreditCardId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod} returns this
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.clearCreditCardId = function() {
  return jspb.Message.setOneofField(this, 2, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.hasCreditCardId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string bank_account_id = 3;
 * @return {string}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.getBankAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod} returns this
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.setBankAccountId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.PaymentMethod} returns this
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.clearBankAccountId = function() {
  return jspb.Message.setOneofField(this, 3, proto.type.RecurringOrderSetting.PaymentMethod.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.PaymentMethod.prototype.hasBankAccountId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.RecurringOrderSetting.RecurringCycle.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.type.RecurringOrderSetting.RecurringCycle.CycleCase = {
  CYCLE_NOT_SET: 0,
  WEEKLY: 1
};

/**
 * @return {proto.type.RecurringOrderSetting.RecurringCycle.CycleCase}
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.getCycleCase = function() {
  return /** @type {proto.type.RecurringOrderSetting.RecurringCycle.CycleCase} */(jspb.Message.computeOneofCase(this, proto.type.RecurringOrderSetting.RecurringCycle.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.RecurringCycle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.RecurringCycle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RecurringCycle.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekly: (f = msg.getWeekly()) && proto.type.RecurringOrderSetting.WeeklyRecurringCycle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of RecurringCycle as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.RecurringCycle.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.weekly;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.RecurringCycle.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.RecurringCycle}
 */
proto.type.RecurringOrderSetting.RecurringCycle.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.RecurringCycle();
  obj.weekly && jspb.Message.setWrapperField(
      msg, 1, proto.type.RecurringOrderSetting.WeeklyRecurringCycle.fromObject(obj.weekly));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.RecurringCycle}
 */
proto.type.RecurringOrderSetting.RecurringCycle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.RecurringCycle;
  return proto.type.RecurringOrderSetting.RecurringCycle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.RecurringCycle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.RecurringCycle}
 */
proto.type.RecurringOrderSetting.RecurringCycle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.WeeklyRecurringCycle;
      reader.readMessage(value,proto.type.RecurringOrderSetting.WeeklyRecurringCycle.deserializeBinaryFromReader);
      msg.setWeekly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.RecurringCycle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.RecurringCycle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RecurringCycle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.WeeklyRecurringCycle.serializeBinaryToWriter
    );
  }
};


/**
 * optional WeeklyRecurringCycle weekly = 1;
 * @return {?proto.type.RecurringOrderSetting.WeeklyRecurringCycle}
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.getWeekly = function() {
  return /** @type{?proto.type.RecurringOrderSetting.WeeklyRecurringCycle} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.WeeklyRecurringCycle, 1));
};


/**
 * @param {?proto.type.RecurringOrderSetting.WeeklyRecurringCycle|undefined} value
 * @return {!proto.type.RecurringOrderSetting.RecurringCycle} returns this
*/
proto.type.RecurringOrderSetting.RecurringCycle.prototype.setWeekly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.type.RecurringOrderSetting.RecurringCycle.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.RecurringCycle} returns this
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.clearWeekly = function() {
  return this.setWeekly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.RecurringCycle.prototype.hasWeekly = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.WeeklyRecurringCycle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.toObject = function(includeInstance, msg) {
  var f, obj = {
    relativeOrderCreateAt: (f = msg.getRelativeOrderCreateAt()) && proto.type.RecurringOrderSetting.RelativeWeeklyTime.toObject(includeInstance, f),
    relativeOrderChangeDeadline: (f = msg.getRelativeOrderChangeDeadline()) && proto.type.RecurringOrderSetting.RelativeWeeklyTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of WeeklyRecurringCycle as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.relativeOrderCreateAt;

  /** @type {?|undefined} */
  this.relativeOrderChangeDeadline;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.WeeklyRecurringCycle();
  obj.relativeOrderCreateAt && jspb.Message.setWrapperField(
      msg, 2, proto.type.RecurringOrderSetting.RelativeWeeklyTime.fromObject(obj.relativeOrderCreateAt));
  obj.relativeOrderChangeDeadline && jspb.Message.setWrapperField(
      msg, 3, proto.type.RecurringOrderSetting.RelativeWeeklyTime.fromObject(obj.relativeOrderChangeDeadline));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.WeeklyRecurringCycle;
  return proto.type.RecurringOrderSetting.WeeklyRecurringCycle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.type.RecurringOrderSetting.RelativeWeeklyTime;
      reader.readMessage(value,proto.type.RecurringOrderSetting.RelativeWeeklyTime.deserializeBinaryFromReader);
      msg.setRelativeOrderCreateAt(value);
      break;
    case 3:
      var value = new proto.type.RecurringOrderSetting.RelativeWeeklyTime;
      reader.readMessage(value,proto.type.RecurringOrderSetting.RelativeWeeklyTime.deserializeBinaryFromReader);
      msg.setRelativeOrderChangeDeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.WeeklyRecurringCycle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelativeOrderCreateAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.type.RecurringOrderSetting.RelativeWeeklyTime.serializeBinaryToWriter
    );
  }
  f = message.getRelativeOrderChangeDeadline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.type.RecurringOrderSetting.RelativeWeeklyTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelativeWeeklyTime relative_order_create_at = 2;
 * @return {?proto.type.RecurringOrderSetting.RelativeWeeklyTime}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.getRelativeOrderCreateAt = function() {
  return /** @type{?proto.type.RecurringOrderSetting.RelativeWeeklyTime} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.RelativeWeeklyTime, 2));
};


/**
 * @param {?proto.type.RecurringOrderSetting.RelativeWeeklyTime|undefined} value
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} returns this
*/
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.setRelativeOrderCreateAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} returns this
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.clearRelativeOrderCreateAt = function() {
  return this.setRelativeOrderCreateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.hasRelativeOrderCreateAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RelativeWeeklyTime relative_order_change_deadline = 3;
 * @return {?proto.type.RecurringOrderSetting.RelativeWeeklyTime}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.getRelativeOrderChangeDeadline = function() {
  return /** @type{?proto.type.RecurringOrderSetting.RelativeWeeklyTime} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.RelativeWeeklyTime, 3));
};


/**
 * @param {?proto.type.RecurringOrderSetting.RelativeWeeklyTime|undefined} value
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} returns this
*/
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.setRelativeOrderChangeDeadline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.WeeklyRecurringCycle} returns this
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.clearRelativeOrderChangeDeadline = function() {
  return this.setRelativeOrderChangeDeadline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.WeeklyRecurringCycle.prototype.hasRelativeOrderChangeDeadline = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.RecurringDeliverySlot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.RecurringDeliverySlot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.toObject = function(includeInstance, msg) {
  var f, obj = {
    constraintsList: jspb.Message.toObjectList(msg.getConstraintsList(),
    proto.type.RecurringOrderSetting.DeliverySlotConstraint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of RecurringDeliverySlot as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.constraintsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.RecurringDeliverySlot.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.RecurringDeliverySlot();
  obj.constraintsList && jspb.Message.setRepeatedWrapperField(
      msg, 1, obj.constraintsList.map(
          proto.type.RecurringOrderSetting.DeliverySlotConstraint.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.RecurringDeliverySlot;
  return proto.type.RecurringOrderSetting.RecurringDeliverySlot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.RecurringDeliverySlot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.DeliverySlotConstraint;
      reader.readMessage(value,proto.type.RecurringOrderSetting.DeliverySlotConstraint.deserializeBinaryFromReader);
      msg.addConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.RecurringDeliverySlot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.RecurringDeliverySlot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstraintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.DeliverySlotConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeliverySlotConstraint constraints = 1;
 * @return {!Array<!proto.type.RecurringOrderSetting.DeliverySlotConstraint>}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.getConstraintsList = function() {
  return /** @type{!Array<!proto.type.RecurringOrderSetting.DeliverySlotConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.RecurringOrderSetting.DeliverySlotConstraint, 1));
};


/**
 * @param {!Array<!proto.type.RecurringOrderSetting.DeliverySlotConstraint>} value
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot} returns this
*/
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.setConstraintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint}
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.addConstraints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.type.RecurringOrderSetting.DeliverySlotConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot} returns this
 */
proto.type.RecurringOrderSetting.RecurringDeliverySlot.prototype.clearConstraintsList = function() {
  return this.setConstraintsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.ConstraintCase = {
  CONSTRAINT_NOT_SET: 0,
  WEEKDAY_CONSTRAINT: 1,
  INCLUDES_DATE_TIME_RANGE_CONSTRAINT: 2,
  MATCH_DATE_TIME_RANGE_CONSTRAINT: 3
};

/**
 * @return {proto.type.RecurringOrderSetting.DeliverySlotConstraint.ConstraintCase}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.getConstraintCase = function() {
  return /** @type {proto.type.RecurringOrderSetting.DeliverySlotConstraint.ConstraintCase} */(jspb.Message.computeOneofCase(this, proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.DeliverySlotConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekdayConstraint: (f = msg.getWeekdayConstraint()) && proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.toObject(includeInstance, f),
    includesDateTimeRangeConstraint: (f = msg.getIncludesDateTimeRangeConstraint()) && proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.toObject(includeInstance, f),
    matchDateTimeRangeConstraint: (f = msg.getMatchDateTimeRangeConstraint()) && proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliverySlotConstraint as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.weekdayConstraint;

  /** @type {?|undefined} */
  this.includesDateTimeRangeConstraint;

  /** @type {?|undefined} */
  this.matchDateTimeRangeConstraint;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotConstraint.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotConstraint();
  obj.weekdayConstraint && jspb.Message.setWrapperField(
      msg, 1, proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.fromObject(obj.weekdayConstraint));
  obj.includesDateTimeRangeConstraint && jspb.Message.setWrapperField(
      msg, 2, proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.fromObject(obj.includesDateTimeRangeConstraint));
  obj.matchDateTimeRangeConstraint && jspb.Message.setWrapperField(
      msg, 3, proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.fromObject(obj.matchDateTimeRangeConstraint));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotConstraint;
  return proto.type.RecurringOrderSetting.DeliverySlotConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint;
      reader.readMessage(value,proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.deserializeBinaryFromReader);
      msg.setWeekdayConstraint(value);
      break;
    case 2:
      var value = new proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint;
      reader.readMessage(value,proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.deserializeBinaryFromReader);
      msg.setIncludesDateTimeRangeConstraint(value);
      break;
    case 3:
      var value = new proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint;
      reader.readMessage(value,proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.deserializeBinaryFromReader);
      msg.setMatchDateTimeRangeConstraint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.DeliverySlotConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekdayConstraint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.serializeBinaryToWriter
    );
  }
  f = message.getIncludesDateTimeRangeConstraint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.serializeBinaryToWriter
    );
  }
  f = message.getMatchDateTimeRangeConstraint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeliverySlotWeekdayConstraint weekday_constraint = 1;
 * @return {?proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.getWeekdayConstraint = function() {
  return /** @type{?proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint, 1));
};


/**
 * @param {?proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint|undefined} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
*/
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.setWeekdayConstraint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.clearWeekdayConstraint = function() {
  return this.setWeekdayConstraint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.hasWeekdayConstraint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeliverySlotIncludesDateTimeRangeConstraint includes_date_time_range_constraint = 2;
 * @return {?proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.getIncludesDateTimeRangeConstraint = function() {
  return /** @type{?proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint, 2));
};


/**
 * @param {?proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint|undefined} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
*/
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.setIncludesDateTimeRangeConstraint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.clearIncludesDateTimeRangeConstraint = function() {
  return this.setIncludesDateTimeRangeConstraint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.hasIncludesDateTimeRangeConstraint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeliverySlotMatchDateTimeRangeConstraint match_date_time_range_constraint = 3;
 * @return {?proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.getMatchDateTimeRangeConstraint = function() {
  return /** @type{?proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint, 3));
};


/**
 * @param {?proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint|undefined} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
*/
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.setMatchDateTimeRangeConstraint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.type.RecurringOrderSetting.DeliverySlotConstraint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.clearMatchDateTimeRangeConstraint = function() {
  return this.setMatchDateTimeRangeConstraint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.DeliverySlotConstraint.prototype.hasMatchDateTimeRangeConstraint = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekday: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliverySlotWeekdayConstraint as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.weekday;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint();
  obj.weekday != null && jspb.Message.setField(msg, 1, obj.weekday);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint;
  return proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.RecurringOrderSetting.Weekday} */ (reader.readEnum());
      msg.setWeekday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekday();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Weekday weekday = 1;
 * @return {!proto.type.RecurringOrderSetting.Weekday}
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.prototype.getWeekday = function() {
  return /** @type {!proto.type.RecurringOrderSetting.Weekday} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.RecurringOrderSetting.Weekday} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotWeekdayConstraint.prototype.setWeekday = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRange: (f = msg.getTimeRange()) && proto.type.RecurringOrderSetting.TimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliverySlotIncludesDateTimeRangeConstraint as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.timeRange;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint();
  obj.timeRange && jspb.Message.setWrapperField(
      msg, 1, proto.type.RecurringOrderSetting.TimeRange.fromObject(obj.timeRange));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint;
  return proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.TimeRange;
      reader.readMessage(value,proto.type.RecurringOrderSetting.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.TimeRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeRange time_range = 1;
 * @return {?proto.type.RecurringOrderSetting.TimeRange}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.getTimeRange = function() {
  return /** @type{?proto.type.RecurringOrderSetting.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.TimeRange, 1));
};


/**
 * @param {?proto.type.RecurringOrderSetting.TimeRange|undefined} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} returns this
*/
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.DeliverySlotIncludesDateTimeRangeConstraint.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRange: (f = msg.getTimeRange()) && proto.type.RecurringOrderSetting.TimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of DeliverySlotMatchDateTimeRangeConstraint as accepted by the `fromObject` method.
 * @record
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.timeRange;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.fromObject = function(obj) {
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint();
  obj.timeRange && jspb.Message.setWrapperField(
      msg, 1, proto.type.RecurringOrderSetting.TimeRange.fromObject(obj.timeRange));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint;
  return proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.RecurringOrderSetting.TimeRange;
      reader.readMessage(value,proto.type.RecurringOrderSetting.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.RecurringOrderSetting.TimeRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeRange time_range = 1;
 * @return {?proto.type.RecurringOrderSetting.TimeRange}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.getTimeRange = function() {
  return /** @type{?proto.type.RecurringOrderSetting.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.TimeRange, 1));
};


/**
 * @param {?proto.type.RecurringOrderSetting.TimeRange|undefined} value
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} returns this
*/
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint} returns this
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.DeliverySlotMatchDateTimeRangeConstraint.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.type.RecurringOrderSetting.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EcSite.SiteType site_type = 10;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.RecurringOrderSetting.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string shop_id = 11;
 * @return {string}
 */
proto.type.RecurringOrderSetting.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.type.RecurringOrderSetting.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_enabled = 3;
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp delivery_start_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.RecurringOrderSetting.prototype.getDeliveryStartAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setDeliveryStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearDeliveryStartAt = function() {
  return this.setDeliveryStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.prototype.hasDeliveryStartAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp delivery_end_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.RecurringOrderSetting.prototype.getDeliveryEndAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setDeliveryEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearDeliveryEndAt = function() {
  return this.setDeliveryEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.prototype.hasDeliveryEndAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional RecurringCycle recurring_cycle = 4;
 * @return {?proto.type.RecurringOrderSetting.RecurringCycle}
 */
proto.type.RecurringOrderSetting.prototype.getRecurringCycle = function() {
  return /** @type{?proto.type.RecurringOrderSetting.RecurringCycle} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.RecurringCycle, 4));
};


/**
 * @param {?proto.type.RecurringOrderSetting.RecurringCycle|undefined} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setRecurringCycle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearRecurringCycle = function() {
  return this.setRecurringCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.prototype.hasRecurringCycle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated RecurringDeliverySlot delivery_slots = 6;
 * @return {!Array<!proto.type.RecurringOrderSetting.RecurringDeliverySlot>}
 */
proto.type.RecurringOrderSetting.prototype.getDeliverySlotsList = function() {
  return /** @type{!Array<!proto.type.RecurringOrderSetting.RecurringDeliverySlot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.RecurringOrderSetting.RecurringDeliverySlot, 6));
};


/**
 * @param {!Array<!proto.type.RecurringOrderSetting.RecurringDeliverySlot>} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setDeliverySlotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.RecurringOrderSetting.RecurringDeliverySlot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.RecurringOrderSetting.RecurringDeliverySlot}
 */
proto.type.RecurringOrderSetting.prototype.addDeliverySlots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.RecurringOrderSetting.RecurringDeliverySlot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearDeliverySlotsList = function() {
  return this.setDeliverySlotsList([]);
};


/**
 * repeated OrderItem order_items = 5;
 * @return {!Array<!proto.type.RecurringOrderSetting.OrderItem>}
 */
proto.type.RecurringOrderSetting.prototype.getOrderItemsList = function() {
  return /** @type{!Array<!proto.type.RecurringOrderSetting.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.RecurringOrderSetting.OrderItem, 5));
};


/**
 * @param {!Array<!proto.type.RecurringOrderSetting.OrderItem>} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setOrderItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.type.RecurringOrderSetting.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.RecurringOrderSetting.OrderItem}
 */
proto.type.RecurringOrderSetting.prototype.addOrderItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.type.RecurringOrderSetting.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearOrderItemsList = function() {
  return this.setOrderItemsList([]);
};


/**
 * optional PaymentMethod payment_method = 12;
 * @return {?proto.type.RecurringOrderSetting.PaymentMethod}
 */
proto.type.RecurringOrderSetting.prototype.getPaymentMethod = function() {
  return /** @type{?proto.type.RecurringOrderSetting.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.type.RecurringOrderSetting.PaymentMethod, 12));
};


/**
 * @param {?proto.type.RecurringOrderSetting.PaymentMethod|undefined} value
 * @return {!proto.type.RecurringOrderSetting} returns this
*/
proto.type.RecurringOrderSetting.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.RecurringOrderSetting} returns this
 */
proto.type.RecurringOrderSetting.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.RecurringOrderSetting.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.type);
