// source: type/tax_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.type.TaxType', null, global);
/**
 * @enum {number}
 */
proto.type.TaxType = {
  TAX_TYPE_UNSPECIFIED: 0,
  EXEMPT: 1,
  INTERNAL: 6,
  EXTERNAL: 7,
  DEPRECATED_IN_10_PERCENT: 2,
  DEPRECATED_EX_10_PERCENT: 3,
  DEPRECATED_IN_08_PERCENT: 4,
  DEPRECATED_EX_08_PERCENT: 5
};

goog.object.extend(exports, proto.type);
