import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import { CalloutBody } from '~/components/callout.vue'

// Calloutコンポーネント用のプラグイン
// フォームの投稿完了メッセージをページ遷移後に表示したい場合などで使用する
type CalloutPlugin = {
  getAndClearMessage: () => CalloutBody | null
  setMessage: (flash: CalloutBody) => void
}

declare module 'vue/types/vue' {
  interface Vue {
    $flash: CalloutPlugin
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $flash: CalloutPlugin
  }

  interface Context {
    $flash: CalloutPlugin
  }
}

const calloutBody = { type: '', title: '', messages: [], link: undefined } as CalloutBody

// 保存されているCalloutのコピーを返し、保存されているCalloutをリセットする
// ex. const message = this.$flash.getAndClearMessage()
const getAndClearMessage = (): CalloutBody | null => {
  if (calloutBody.title !== '') {
    const copiedCallout = { ...calloutBody }

    calloutBody.type = ''
    calloutBody.title = ''
    calloutBody.messages = []
    calloutBody.link = undefined

    return copiedCallout
  } else {
    return null
  }
}

// Calloutを一時保存する
// ex. this.$flash.setMessage({ type: 'success', title: '保存に成功しました', messages:[] })
const setMessage = (flash: CalloutBody): void => {
  calloutBody.type = flash.type
  calloutBody.title = flash.title
  calloutBody.messages = flash.messages
  calloutBody.link = flash.link
}

const flash = Vue.observable({
  getAndClearMessage,
  setMessage,
} as CalloutPlugin)

const calloutPlugin: Plugin = (_, inject) => {
  inject('flash', flash)
}

export default calloutPlugin
