// source: stailer/externaladminrpc/ec_site_category.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_ec_site_pb = require('../../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_ec_site_category_pb = require('../../type/ec_site_category_pb.js');
goog.object.extend(proto, type_ec_site_category_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.stailer_external_admin.EcSiteCategory', null, global);
goog.exportSymbol('proto.stailer_external_admin.EcSiteShopCategory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcSiteCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stailer_external_admin.EcSiteCategory.repeatedFields_, null);
};
goog.inherits(proto.stailer_external_admin.EcSiteCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcSiteCategory.displayName = 'proto.stailer_external_admin.EcSiteCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stailer_external_admin.EcSiteShopCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stailer_external_admin.EcSiteShopCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stailer_external_admin.EcSiteShopCategory.displayName = 'proto.stailer_external_admin.EcSiteShopCategory';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stailer_external_admin.EcSiteCategory.repeatedFields_ = [5,8,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcSiteCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcSiteCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcSiteCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteCategoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteCategoryPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ancestorNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    parentSiteCategoryId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    childrenNamesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    isTopTab: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isRootCategory: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    categoryMappingSiteCategoryIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    ecSiteShopCategoriesList: jspb.Message.toObjectList(msg.getEcSiteShopCategoriesList(),
    proto.stailer_external_admin.EcSiteShopCategory.toObject, includeInstance),
    categoryType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    publicationStartAt: (f = msg.getPublicationStartAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    publicationEndAt: (f = msg.getPublicationEndAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    status: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSiteCategory as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcSiteCategory.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.siteCategoryId;

  /** @type {?|undefined} */
  this.siteCategoryPath;

  /** @type {?|undefined} */
  this.ancestorNamesList;

  /** @type {?|undefined} */
  this.order;

  /** @type {?|undefined} */
  this.parentSiteCategoryId;

  /** @type {?|undefined} */
  this.childrenNamesList;

  /** @type {?|undefined} */
  this.isTopTab;

  /** @type {?|undefined} */
  this.isRootCategory;

  /** @type {?|undefined} */
  this.categoryMappingSiteCategoryIdsList;

  /** @type {?|undefined} */
  this.ecSiteShopCategoriesList;

  /** @type {?|undefined} */
  this.categoryType;

  /** @type {?|undefined} */
  this.publicationStartAt;

  /** @type {?|undefined} */
  this.publicationEndAt;

  /** @type {?|undefined} */
  this.isSuspended;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.status;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcSiteCategory.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcSiteCategory}
 */
proto.stailer_external_admin.EcSiteCategory.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcSiteCategory();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.name != null && jspb.Message.setField(msg, 2, obj.name);
  obj.siteCategoryId != null && jspb.Message.setField(msg, 3, obj.siteCategoryId);
  obj.siteCategoryPath != null && jspb.Message.setField(msg, 4, obj.siteCategoryPath);
  obj.ancestorNamesList != null && jspb.Message.setField(msg, 5, obj.ancestorNamesList);
  obj.order != null && jspb.Message.setField(msg, 6, obj.order);
  obj.parentSiteCategoryId != null && jspb.Message.setField(msg, 7, obj.parentSiteCategoryId);
  obj.childrenNamesList != null && jspb.Message.setField(msg, 8, obj.childrenNamesList);
  obj.isTopTab != null && jspb.Message.setField(msg, 9, obj.isTopTab);
  obj.isRootCategory != null && jspb.Message.setField(msg, 10, obj.isRootCategory);
  obj.categoryMappingSiteCategoryIdsList != null && jspb.Message.setField(msg, 11, obj.categoryMappingSiteCategoryIdsList);
  obj.ecSiteShopCategoriesList && jspb.Message.setRepeatedWrapperField(
      msg, 12, obj.ecSiteShopCategoriesList.map(
          proto.stailer_external_admin.EcSiteShopCategory.fromObject));
  obj.categoryType != null && jspb.Message.setField(msg, 13, obj.categoryType);
  obj.publicationStartAt && jspb.Message.setWrapperField(
      msg, 14, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.publicationStartAt));
  obj.publicationEndAt && jspb.Message.setWrapperField(
      msg, 15, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.publicationEndAt));
  obj.isSuspended != null && jspb.Message.setField(msg, 16, obj.isSuspended);
  obj.imageUrl != null && jspb.Message.setField(msg, 17, obj.imageUrl);
  obj.status != null && jspb.Message.setField(msg, 18, obj.status);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcSiteCategory}
 */
proto.stailer_external_admin.EcSiteCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcSiteCategory;
  return proto.stailer_external_admin.EcSiteCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcSiteCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcSiteCategory}
 */
proto.stailer_external_admin.EcSiteCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteCategoryPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAncestorNames(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentSiteCategoryId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildrenNames(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTopTab(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRootCategory(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryMappingSiteCategoryIds(value);
      break;
    case 12:
      var value = new proto.stailer_external_admin.EcSiteShopCategory;
      reader.readMessage(value,proto.stailer_external_admin.EcSiteShopCategory.deserializeBinaryFromReader);
      msg.addEcSiteShopCategories(value);
      break;
    case 13:
      var value = /** @type {!proto.type.EcSiteCategory.Type} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublicationStartAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublicationEndAt(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 18:
      var value = /** @type {!proto.type.EcSiteCategory.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcSiteCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcSiteCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcSiteCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteCategoryPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAncestorNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getParentSiteCategoryId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getChildrenNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getIsTopTab();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsRootCategory();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCategoryMappingSiteCategoryIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getEcSiteShopCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.stailer_external_admin.EcSiteShopCategory.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getPublicationStartAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPublicationEndAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional type.EcSite.SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_category_id = 3;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getSiteCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setSiteCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_category_path = 4;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getSiteCategoryPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setSiteCategoryPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string ancestor_names = 5;
 * @return {!Array<string>}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getAncestorNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setAncestorNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.addAncestorNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearAncestorNamesList = function() {
  return this.setAncestorNamesList([]);
};


/**
 * optional int64 order = 6;
 * @return {number}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string parent_site_category_id = 7;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getParentSiteCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setParentSiteCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string children_names = 8;
 * @return {!Array<string>}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getChildrenNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setChildrenNamesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.addChildrenNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearChildrenNamesList = function() {
  return this.setChildrenNamesList([]);
};


/**
 * optional bool is_top_tab = 9;
 * @return {boolean}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getIsTopTab = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setIsTopTab = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_root_category = 10;
 * @return {boolean}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getIsRootCategory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setIsRootCategory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated string category_mapping_site_category_ids = 11;
 * @return {!Array<string>}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getCategoryMappingSiteCategoryIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setCategoryMappingSiteCategoryIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.addCategoryMappingSiteCategoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearCategoryMappingSiteCategoryIdsList = function() {
  return this.setCategoryMappingSiteCategoryIdsList([]);
};


/**
 * repeated EcSiteShopCategory ec_site_shop_categories = 12;
 * @return {!Array<!proto.stailer_external_admin.EcSiteShopCategory>}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getEcSiteShopCategoriesList = function() {
  return /** @type{!Array<!proto.stailer_external_admin.EcSiteShopCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stailer_external_admin.EcSiteShopCategory, 12));
};


/**
 * @param {!Array<!proto.stailer_external_admin.EcSiteShopCategory>} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
*/
proto.stailer_external_admin.EcSiteCategory.prototype.setEcSiteShopCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.stailer_external_admin.EcSiteShopCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stailer_external_admin.EcSiteShopCategory}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.addEcSiteShopCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.stailer_external_admin.EcSiteShopCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearEcSiteShopCategoriesList = function() {
  return this.setEcSiteShopCategoriesList([]);
};


/**
 * optional type.EcSiteCategory.Type category_type = 13;
 * @return {!proto.type.EcSiteCategory.Type}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getCategoryType = function() {
  return /** @type {!proto.type.EcSiteCategory.Type} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.type.EcSiteCategory.Type} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp publication_start_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getPublicationStartAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
*/
proto.stailer_external_admin.EcSiteCategory.prototype.setPublicationStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearPublicationStartAt = function() {
  return this.setPublicationStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.hasPublicationStartAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp publication_end_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getPublicationEndAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
*/
proto.stailer_external_admin.EcSiteCategory.prototype.setPublicationEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.clearPublicationEndAt = function() {
  return this.setPublicationEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.hasPublicationEndAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_suspended = 16;
 * @return {boolean}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string image_url = 17;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional type.EcSiteCategory.Status status = 18;
 * @return {!proto.type.EcSiteCategory.Status}
 */
proto.stailer_external_admin.EcSiteCategory.prototype.getStatus = function() {
  return /** @type {!proto.type.EcSiteCategory.Status} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.type.EcSiteCategory.Status} value
 * @return {!proto.stailer_external_admin.EcSiteCategory} returns this
 */
proto.stailer_external_admin.EcSiteCategory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.stailer_external_admin.EcSiteShopCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stailer_external_admin.EcSiteShopCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcSiteShopCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shopId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSiteShopCategory as accepted by the `fromObject` method.
 * @record
 */
proto.stailer_external_admin.EcSiteShopCategory.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.order;

  /** @type {?|undefined} */
  this.shopId;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.stailer_external_admin.EcSiteShopCategory.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.stailer_external_admin.EcSiteShopCategory}
 */
proto.stailer_external_admin.EcSiteShopCategory.fromObject = function(obj) {
  var msg = new proto.stailer_external_admin.EcSiteShopCategory();
  obj.name != null && jspb.Message.setField(msg, 1, obj.name);
  obj.order != null && jspb.Message.setField(msg, 2, obj.order);
  obj.shopId != null && jspb.Message.setField(msg, 3, obj.shopId);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stailer_external_admin.EcSiteShopCategory}
 */
proto.stailer_external_admin.EcSiteShopCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stailer_external_admin.EcSiteShopCategory;
  return proto.stailer_external_admin.EcSiteShopCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stailer_external_admin.EcSiteShopCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stailer_external_admin.EcSiteShopCategory}
 */
proto.stailer_external_admin.EcSiteShopCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stailer_external_admin.EcSiteShopCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stailer_external_admin.EcSiteShopCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stailer_external_admin.EcSiteShopCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteShopCategory} returns this
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 order = 2;
 * @return {number}
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stailer_external_admin.EcSiteShopCategory} returns this
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string shop_id = 3;
 * @return {string}
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stailer_external_admin.EcSiteShopCategory} returns this
 */
proto.stailer_external_admin.EcSiteShopCategory.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.stailer_external_admin);
