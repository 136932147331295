// source: type/bundle_rule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_bundle_type_pb = require('../type/bundle_type_pb.js');
goog.object.extend(proto, type_bundle_type_pb);
var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
goog.exportSymbol('proto.type.BundleCondition', null, global);
goog.exportSymbol('proto.type.InvalidBundleRulesReason', null, global);
goog.exportSymbol('proto.type.PbBundleRule', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.BundleCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.BundleCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.BundleCondition.displayName = 'proto.type.BundleCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PbBundleRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.PbBundleRule.repeatedFields_, null);
};
goog.inherits(proto.type.PbBundleRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PbBundleRule.displayName = 'proto.type.PbBundleRule';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.BundleCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.type.BundleCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.BundleCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundleCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    threshold: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0),
    splitPrice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    useSplitPrice: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of BundleCondition as accepted by the `fromObject` method.
 * @record
 */
proto.type.BundleCondition.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.threshold;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.splitPrice;

  /** @type {?|undefined} */
  this.useSplitPrice;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.BundleCondition.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.BundleCondition}
 */
proto.type.BundleCondition.fromObject = function(obj) {
  var msg = new proto.type.BundleCondition();
  obj.threshold != null && jspb.Message.setField(msg, 1, obj.threshold);
  obj.price != null && jspb.Message.setField(msg, 2, obj.price);
  obj.splitPrice != null && jspb.Message.setField(msg, 3, obj.splitPrice);
  obj.useSplitPrice != null && jspb.Message.setField(msg, 4, obj.useSplitPrice);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.BundleCondition}
 */
proto.type.BundleCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.BundleCondition;
  return proto.type.BundleCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.BundleCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.BundleCondition}
 */
proto.type.BundleCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setThreshold(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitPrice(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSplitPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.BundleCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.BundleCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.BundleCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.BundleCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreshold();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSplitPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUseSplitPrice();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 threshold = 1;
 * @return {number}
 */
proto.type.BundleCondition.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.BundleCondition} returns this
 */
proto.type.BundleCondition.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 price = 2;
 * @return {number}
 */
proto.type.BundleCondition.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.BundleCondition} returns this
 */
proto.type.BundleCondition.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 split_price = 3;
 * @return {number}
 */
proto.type.BundleCondition.prototype.getSplitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.BundleCondition} returns this
 */
proto.type.BundleCondition.prototype.setSplitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool use_split_price = 4;
 * @return {boolean}
 */
proto.type.BundleCondition.prototype.getUseSplitPrice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.BundleCondition} returns this
 */
proto.type.BundleCondition.prototype.setUseSplitPrice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.PbBundleRule.repeatedFields_ = [6,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PbBundleRule.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PbBundleRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PbBundleRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PbBundleRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    bundleRuleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shopId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bundleType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.type.BundleCondition.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    priceType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PbBundleRule as accepted by the `fromObject` method.
 * @record
 */
proto.type.PbBundleRule.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.bundleRuleId;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.code;

  /** @type {?|undefined} */
  this.bundleType;

  /** @type {?|undefined} */
  this.conditionsList;

  /** @type {?|undefined} */
  this.createdAt;

  /** @type {?|undefined} */
  this.updatedAt;

  /** @type {?|undefined} */
  this.from;

  /** @type {?|undefined} */
  this.to;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.productIdsList;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.snapshotId;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.PbBundleRule.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.PbBundleRule}
 */
proto.type.PbBundleRule.fromObject = function(obj) {
  var msg = new proto.type.PbBundleRule();
  obj.bundleRuleId != null && jspb.Message.setField(msg, 1, obj.bundleRuleId);
  obj.siteType != null && jspb.Message.setField(msg, 2, obj.siteType);
  obj.shopId != null && jspb.Message.setField(msg, 3, obj.shopId);
  obj.code != null && jspb.Message.setField(msg, 4, obj.code);
  obj.bundleType != null && jspb.Message.setField(msg, 5, obj.bundleType);
  obj.conditionsList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.conditionsList.map(
          proto.type.BundleCondition.fromObject));
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 7, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  obj.updatedAt && jspb.Message.setWrapperField(
      msg, 8, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.updatedAt));
  obj.from && jspb.Message.setWrapperField(
      msg, 9, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.from));
  obj.to && jspb.Message.setWrapperField(
      msg, 10, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.to));
  obj.name != null && jspb.Message.setField(msg, 11, obj.name);
  obj.productIdsList != null && jspb.Message.setField(msg, 12, obj.productIdsList);
  obj.priceType != null && jspb.Message.setField(msg, 13, obj.priceType);
  obj.snapshotId != null && jspb.Message.setField(msg, 14, obj.snapshotId);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PbBundleRule}
 */
proto.type.PbBundleRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PbBundleRule;
  return proto.type.PbBundleRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PbBundleRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PbBundleRule}
 */
proto.type.PbBundleRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBundleRuleId(value);
      break;
    case 2:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {!proto.type.BundleType} */ (reader.readEnum());
      msg.setBundleType(value);
      break;
    case 6:
      var value = new proto.type.BundleCondition;
      reader.readMessage(value,proto.type.BundleCondition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 13:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PbBundleRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PbBundleRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PbBundleRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PbBundleRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBundleRuleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBundleType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.type.BundleCondition.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string bundle_rule_id = 1;
 * @return {string}
 */
proto.type.PbBundleRule.prototype.getBundleRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setBundleRuleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EcSite.SiteType site_type = 2;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.PbBundleRule.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string shop_id = 3;
 * @return {string}
 */
proto.type.PbBundleRule.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.type.PbBundleRule.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BundleType bundle_type = 5;
 * @return {!proto.type.BundleType}
 */
proto.type.PbBundleRule.prototype.getBundleType = function() {
  return /** @type {!proto.type.BundleType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.type.BundleType} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setBundleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated BundleCondition conditions = 6;
 * @return {!Array<!proto.type.BundleCondition>}
 */
proto.type.PbBundleRule.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.type.BundleCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.BundleCondition, 6));
};


/**
 * @param {!Array<!proto.type.BundleCondition>} value
 * @return {!proto.type.PbBundleRule} returns this
*/
proto.type.PbBundleRule.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.BundleCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.BundleCondition}
 */
proto.type.PbBundleRule.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.BundleCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.PbBundleRule.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.PbBundleRule} returns this
*/
proto.type.PbBundleRule.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.PbBundleRule.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.PbBundleRule.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.PbBundleRule} returns this
*/
proto.type.PbBundleRule.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.PbBundleRule.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp from = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.PbBundleRule.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.PbBundleRule} returns this
*/
proto.type.PbBundleRule.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.PbBundleRule.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp to = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.PbBundleRule.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.PbBundleRule} returns this
*/
proto.type.PbBundleRule.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.PbBundleRule.prototype.hasTo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.type.PbBundleRule.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string product_ids = 12;
 * @return {!Array<string>}
 */
proto.type.PbBundleRule.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * optional PriceType price_type = 13;
 * @return {!proto.type.PriceType}
 */
proto.type.PbBundleRule.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string snapshot_id = 14;
 * @return {string}
 */
proto.type.PbBundleRule.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PbBundleRule} returns this
 */
proto.type.PbBundleRule.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * @enum {number}
 */
proto.type.InvalidBundleRulesReason = {
  INVALID_PERIOD: 0,
  NEW_DATA_DETECTED: 1
};

goog.object.extend(exports, proto.type);
