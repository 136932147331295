// source: type/compensation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_price_pb = require('../type/price_pb.js');
goog.object.extend(proto, type_price_pb);
goog.exportSymbol('proto.type.Compensation', null, global);
goog.exportSymbol('proto.type.Compensation.Discount', null, global);
goog.exportSymbol('proto.type.Compensation.Exchange', null, global);
goog.exportSymbol('proto.type.Compensation.Other', null, global);
goog.exportSymbol('proto.type.Compensation.Returns', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Compensation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Compensation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Compensation.displayName = 'proto.type.Compensation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Compensation.Exchange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Compensation.Exchange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Compensation.Exchange.displayName = 'proto.type.Compensation.Exchange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Compensation.Returns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Compensation.Returns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Compensation.Returns.displayName = 'proto.type.Compensation.Returns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Compensation.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Compensation.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Compensation.Discount.displayName = 'proto.type.Compensation.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.Compensation.Other = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.Compensation.Other, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.Compensation.Other.displayName = 'proto.type.Compensation.Other';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Compensation.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Compensation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Compensation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Compensation as accepted by the `fromObject` method.
 * @record
 */
proto.type.Compensation.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Compensation.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Compensation}
 */
proto.type.Compensation.fromObject = function(obj) {
  var msg = new proto.type.Compensation();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Compensation}
 */
proto.type.Compensation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Compensation;
  return proto.type.Compensation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Compensation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Compensation}
 */
proto.type.Compensation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Compensation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Compensation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Compensation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Compensation.Exchange.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Compensation.Exchange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Compensation.Exchange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Exchange.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    beforePrice: (f = msg.getBeforePrice()) && type_price_pb.Price.toObject(includeInstance, f),
    afterPrice: (f = msg.getAfterPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    compensationPrice: (f = msg.getCompensationPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Exchange as accepted by the `fromObject` method.
 * @record
 */
proto.type.Compensation.Exchange.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderItemId;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.beforePrice;

  /** @type {?|undefined} */
  this.afterPrice;

  /** @type {?|undefined} */
  this.compensationPrice;

  /** @type {?|undefined} */
  this.quantity;

  /** @type {?|undefined} */
  this.imageUrl;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Compensation.Exchange.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Compensation.Exchange}
 */
proto.type.Compensation.Exchange.fromObject = function(obj) {
  var msg = new proto.type.Compensation.Exchange();
  obj.orderItemId != null && jspb.Message.setField(msg, 1, obj.orderItemId);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.productName != null && jspb.Message.setField(msg, 3, obj.productName);
  obj.beforePrice && jspb.Message.setWrapperField(
      msg, 4, type_price_pb.Price.fromObject(obj.beforePrice));
  obj.afterPrice && jspb.Message.setWrapperField(
      msg, 5, type_price_pb.Price.fromObject(obj.afterPrice));
  obj.compensationPrice && jspb.Message.setWrapperField(
      msg, 6, type_price_pb.Price.fromObject(obj.compensationPrice));
  obj.quantity != null && jspb.Message.setField(msg, 7, obj.quantity);
  obj.imageUrl != null && jspb.Message.setField(msg, 8, obj.imageUrl);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Compensation.Exchange}
 */
proto.type.Compensation.Exchange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Compensation.Exchange;
  return proto.type.Compensation.Exchange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Compensation.Exchange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Compensation.Exchange}
 */
proto.type.Compensation.Exchange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setBeforePrice(value);
      break;
    case 5:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setAfterPrice(value);
      break;
    case 6:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setCompensationPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Compensation.Exchange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Compensation.Exchange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Compensation.Exchange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Exchange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBeforePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getAfterPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getCompensationPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.type.Compensation.Exchange.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.type.Compensation.Exchange.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.type.Compensation.Exchange.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Price before_price = 4;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Exchange.prototype.getBeforePrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 4));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Exchange} returns this
*/
proto.type.Compensation.Exchange.prototype.setBeforePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.clearBeforePrice = function() {
  return this.setBeforePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Exchange.prototype.hasBeforePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price after_price = 5;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Exchange.prototype.getAfterPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 5));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Exchange} returns this
*/
proto.type.Compensation.Exchange.prototype.setAfterPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.clearAfterPrice = function() {
  return this.setAfterPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Exchange.prototype.hasAfterPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Price compensation_price = 6;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Exchange.prototype.getCompensationPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 6));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Exchange} returns this
*/
proto.type.Compensation.Exchange.prototype.setCompensationPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.clearCompensationPrice = function() {
  return this.setCompensationPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Exchange.prototype.hasCompensationPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 quantity = 7;
 * @return {number}
 */
proto.type.Compensation.Exchange.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string image_url = 8;
 * @return {string}
 */
proto.type.Compensation.Exchange.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Exchange} returns this
 */
proto.type.Compensation.Exchange.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Compensation.Returns.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Compensation.Returns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Compensation.Returns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Returns.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    beforePrice: (f = msg.getBeforePrice()) && type_price_pb.Price.toObject(includeInstance, f),
    afterPrice: (f = msg.getAfterPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    compensationPrice: (f = msg.getCompensationPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    beforeQuantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    afterQuantity: jspb.Message.getFieldWithDefault(msg, 8, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Returns as accepted by the `fromObject` method.
 * @record
 */
proto.type.Compensation.Returns.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderItemId;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.beforePrice;

  /** @type {?|undefined} */
  this.afterPrice;

  /** @type {?|undefined} */
  this.compensationPrice;

  /** @type {?|undefined} */
  this.beforeQuantity;

  /** @type {?|undefined} */
  this.afterQuantity;

  /** @type {?|undefined} */
  this.imageUrl;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Compensation.Returns.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Compensation.Returns}
 */
proto.type.Compensation.Returns.fromObject = function(obj) {
  var msg = new proto.type.Compensation.Returns();
  obj.orderItemId != null && jspb.Message.setField(msg, 1, obj.orderItemId);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.productName != null && jspb.Message.setField(msg, 3, obj.productName);
  obj.beforePrice && jspb.Message.setWrapperField(
      msg, 4, type_price_pb.Price.fromObject(obj.beforePrice));
  obj.afterPrice && jspb.Message.setWrapperField(
      msg, 5, type_price_pb.Price.fromObject(obj.afterPrice));
  obj.compensationPrice && jspb.Message.setWrapperField(
      msg, 6, type_price_pb.Price.fromObject(obj.compensationPrice));
  obj.beforeQuantity != null && jspb.Message.setField(msg, 7, obj.beforeQuantity);
  obj.afterQuantity != null && jspb.Message.setField(msg, 8, obj.afterQuantity);
  obj.imageUrl != null && jspb.Message.setField(msg, 9, obj.imageUrl);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Compensation.Returns}
 */
proto.type.Compensation.Returns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Compensation.Returns;
  return proto.type.Compensation.Returns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Compensation.Returns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Compensation.Returns}
 */
proto.type.Compensation.Returns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setBeforePrice(value);
      break;
    case 5:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setAfterPrice(value);
      break;
    case 6:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setCompensationPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeforeQuantity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAfterQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Compensation.Returns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Compensation.Returns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Compensation.Returns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Returns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBeforePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getAfterPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getCompensationPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getBeforeQuantity();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAfterQuantity();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.type.Compensation.Returns.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.type.Compensation.Returns.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.type.Compensation.Returns.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Price before_price = 4;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Returns.prototype.getBeforePrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 4));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Returns} returns this
*/
proto.type.Compensation.Returns.prototype.setBeforePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.clearBeforePrice = function() {
  return this.setBeforePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Returns.prototype.hasBeforePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price after_price = 5;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Returns.prototype.getAfterPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 5));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Returns} returns this
*/
proto.type.Compensation.Returns.prototype.setAfterPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.clearAfterPrice = function() {
  return this.setAfterPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Returns.prototype.hasAfterPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Price compensation_price = 6;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Returns.prototype.getCompensationPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 6));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Returns} returns this
*/
proto.type.Compensation.Returns.prototype.setCompensationPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.clearCompensationPrice = function() {
  return this.setCompensationPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Returns.prototype.hasCompensationPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 before_quantity = 7;
 * @return {number}
 */
proto.type.Compensation.Returns.prototype.getBeforeQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setBeforeQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 after_quantity = 8;
 * @return {number}
 */
proto.type.Compensation.Returns.prototype.getAfterQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setAfterQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string image_url = 9;
 * @return {string}
 */
proto.type.Compensation.Returns.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Returns} returns this
 */
proto.type.Compensation.Returns.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Compensation.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Compensation.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Compensation.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    beforePrice: (f = msg.getBeforePrice()) && type_price_pb.Price.toObject(includeInstance, f),
    afterPrice: (f = msg.getAfterPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    compensationPrice: (f = msg.getCompensationPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Discount as accepted by the `fromObject` method.
 * @record
 */
proto.type.Compensation.Discount.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderItemId;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.beforePrice;

  /** @type {?|undefined} */
  this.afterPrice;

  /** @type {?|undefined} */
  this.compensationPrice;

  /** @type {?|undefined} */
  this.quantity;

  /** @type {?|undefined} */
  this.imageUrl;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Compensation.Discount.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Compensation.Discount}
 */
proto.type.Compensation.Discount.fromObject = function(obj) {
  var msg = new proto.type.Compensation.Discount();
  obj.orderItemId != null && jspb.Message.setField(msg, 1, obj.orderItemId);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.productName != null && jspb.Message.setField(msg, 3, obj.productName);
  obj.beforePrice && jspb.Message.setWrapperField(
      msg, 4, type_price_pb.Price.fromObject(obj.beforePrice));
  obj.afterPrice && jspb.Message.setWrapperField(
      msg, 5, type_price_pb.Price.fromObject(obj.afterPrice));
  obj.compensationPrice && jspb.Message.setWrapperField(
      msg, 6, type_price_pb.Price.fromObject(obj.compensationPrice));
  obj.quantity != null && jspb.Message.setField(msg, 7, obj.quantity);
  obj.imageUrl != null && jspb.Message.setField(msg, 8, obj.imageUrl);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Compensation.Discount}
 */
proto.type.Compensation.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Compensation.Discount;
  return proto.type.Compensation.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Compensation.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Compensation.Discount}
 */
proto.type.Compensation.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setBeforePrice(value);
      break;
    case 5:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setAfterPrice(value);
      break;
    case 6:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setCompensationPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Compensation.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Compensation.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Compensation.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBeforePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getAfterPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getCompensationPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.type.Compensation.Discount.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.type.Compensation.Discount.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.type.Compensation.Discount.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Price before_price = 4;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Discount.prototype.getBeforePrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 4));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Discount} returns this
*/
proto.type.Compensation.Discount.prototype.setBeforePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.clearBeforePrice = function() {
  return this.setBeforePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Discount.prototype.hasBeforePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price after_price = 5;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Discount.prototype.getAfterPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 5));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Discount} returns this
*/
proto.type.Compensation.Discount.prototype.setAfterPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.clearAfterPrice = function() {
  return this.setAfterPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Discount.prototype.hasAfterPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Price compensation_price = 6;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Discount.prototype.getCompensationPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 6));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Discount} returns this
*/
proto.type.Compensation.Discount.prototype.setCompensationPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.clearCompensationPrice = function() {
  return this.setCompensationPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Discount.prototype.hasCompensationPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 quantity = 7;
 * @return {number}
 */
proto.type.Compensation.Discount.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string image_url = 8;
 * @return {string}
 */
proto.type.Compensation.Discount.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Discount} returns this
 */
proto.type.Compensation.Discount.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.Compensation.Other.prototype.toObject = function(opt_includeInstance) {
  return proto.type.Compensation.Other.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.Compensation.Other} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Other.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    beforePrice: (f = msg.getBeforePrice()) && type_price_pb.Price.toObject(includeInstance, f),
    afterPrice: (f = msg.getAfterPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    compensationPrice: (f = msg.getCompensationPrice()) && type_price_pb.Price.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of Other as accepted by the `fromObject` method.
 * @record
 */
proto.type.Compensation.Other.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.orderItemId;

  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.beforePrice;

  /** @type {?|undefined} */
  this.afterPrice;

  /** @type {?|undefined} */
  this.compensationPrice;

  /** @type {?|undefined} */
  this.quantity;

  /** @type {?|undefined} */
  this.imageUrl;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.Compensation.Other.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.Compensation.Other}
 */
proto.type.Compensation.Other.fromObject = function(obj) {
  var msg = new proto.type.Compensation.Other();
  obj.orderItemId != null && jspb.Message.setField(msg, 1, obj.orderItemId);
  obj.productId != null && jspb.Message.setField(msg, 2, obj.productId);
  obj.productName != null && jspb.Message.setField(msg, 3, obj.productName);
  obj.beforePrice && jspb.Message.setWrapperField(
      msg, 4, type_price_pb.Price.fromObject(obj.beforePrice));
  obj.afterPrice && jspb.Message.setWrapperField(
      msg, 5, type_price_pb.Price.fromObject(obj.afterPrice));
  obj.compensationPrice && jspb.Message.setWrapperField(
      msg, 6, type_price_pb.Price.fromObject(obj.compensationPrice));
  obj.quantity != null && jspb.Message.setField(msg, 7, obj.quantity);
  obj.imageUrl != null && jspb.Message.setField(msg, 8, obj.imageUrl);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.Compensation.Other}
 */
proto.type.Compensation.Other.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.Compensation.Other;
  return proto.type.Compensation.Other.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.Compensation.Other} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.Compensation.Other}
 */
proto.type.Compensation.Other.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setBeforePrice(value);
      break;
    case 5:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setAfterPrice(value);
      break;
    case 6:
      var value = new type_price_pb.Price;
      reader.readMessage(value,type_price_pb.Price.deserializeBinaryFromReader);
      msg.setCompensationPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.Compensation.Other.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.Compensation.Other.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.Compensation.Other} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.Compensation.Other.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBeforePrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getAfterPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getCompensationPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      type_price_pb.Price.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.type.Compensation.Other.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.type.Compensation.Other.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.type.Compensation.Other.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Price before_price = 4;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Other.prototype.getBeforePrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 4));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Other} returns this
*/
proto.type.Compensation.Other.prototype.setBeforePrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.clearBeforePrice = function() {
  return this.setBeforePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Other.prototype.hasBeforePrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Price after_price = 5;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Other.prototype.getAfterPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 5));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Other} returns this
*/
proto.type.Compensation.Other.prototype.setAfterPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.clearAfterPrice = function() {
  return this.setAfterPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Other.prototype.hasAfterPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Price compensation_price = 6;
 * @return {?proto.type.Price}
 */
proto.type.Compensation.Other.prototype.getCompensationPrice = function() {
  return /** @type{?proto.type.Price} */ (
    jspb.Message.getWrapperField(this, type_price_pb.Price, 6));
};


/**
 * @param {?proto.type.Price|undefined} value
 * @return {!proto.type.Compensation.Other} returns this
*/
proto.type.Compensation.Other.prototype.setCompensationPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.clearCompensationPrice = function() {
  return this.setCompensationPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.Compensation.Other.prototype.hasCompensationPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 quantity = 7;
 * @return {number}
 */
proto.type.Compensation.Other.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string image_url = 8;
 * @return {string}
 */
proto.type.Compensation.Other.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.Compensation.Other} returns this
 */
proto.type.Compensation.Other.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.type);
