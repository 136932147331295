import firebase from 'firebase/app'
import 'firebase/auth'
import config, { Environment } from 'config'
import { trimHostForBranchDeployOnDev } from '~/utils/env'

export default (function () {
  let host = trimHostForBranchDeployOnDev(location.host)
  if (/\d+\.\d+\.\d+\.\d+:3001/.test(host)) {
    host = 'localhost:3001'
  }
  if (process.env.LOCAL_SERVER === 'true' && config.environment === Environment.development) {
    host = 'dev.stailer.jp'
  }

  const configForCurrentSite = config.siteConfigMapping[host]
  if (!configForCurrentSite) {
    throw new Error(`SiteConfig for ${host} is not defined.`)
  }
  if (!firebase.apps.length) {
    return firebase.initializeApp(configForCurrentSite.firebaseConfig, 'stailer admin')
  } else {
    return firebase.app('stailer admin')
  }
})()
