import config, { Environment } from 'config'

// This is a dirty workaround for making branch deploy work without injecting
// a pull request number from GitHub Actions to speed up docker build.
export function trimHostForBranchDeployOnDev(host: string) {
  if (config.environment !== Environment.development) {
    return host
  }

  // example:
  // external-admin-123-life.admin.develop.stailer.jp -> life.admin.develop.stailer.jp
  return host.replace(/^external-admin-[0-9]+-/, '')
}

export function getHostForSiteConfig(locationHost: string) {
  const host = trimHostForBranchDeployOnDev(locationHost)
  if (/\d+\.\d+\.\d+\.\d+:3001/.test(host)) {
    return 'localhost:3001'
  } else if (
    process.env.LOCAL_SERVER === 'true' &&
    config.environment === Environment.development
  ) {
    return 'dev.stailer.jp'
  } else {
    return host
  }
}
