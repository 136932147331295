// source: type/shipping_info.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_address_pb = require('../type/address_pb.js');
goog.object.extend(proto, type_address_pb);
var type_custom_field_option_pb = require('../type/custom_field_option_pb.js');
goog.object.extend(proto, type_custom_field_option_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.type.ShippingInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.ShippingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.ShippingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.ShippingInfo.displayName = 'proto.type.ShippingInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.ShippingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.type.ShippingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.ShippingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.ShippingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    familyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    familyNameKana: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstNameKana: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: (f = msg.getAddress()) && type_address_pb.Address.toObject(includeInstance, f),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    deliveryUnattendedLocation: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isAutoLockApartment: (f = msg.getIsAutoLockApartment()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of ShippingInfo as accepted by the `fromObject` method.
 * @record
 */
proto.type.ShippingInfo.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.familyName;

  /** @type {?|undefined} */
  this.firstName;

  /** @type {?|undefined} */
  this.familyNameKana;

  /** @type {?|undefined} */
  this.firstNameKana;

  /** @type {?|undefined} */
  this.phoneNumber;

  /** @type {?|undefined} */
  this.address;

  /** @type {?|undefined} */
  this.isDefault;

  /** @type {?|undefined} */
  this.deliveryUnattendedLocation;

  /** @type {?|undefined} */
  this.isAutoLockApartment;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.ShippingInfo.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.ShippingInfo}
 */
proto.type.ShippingInfo.fromObject = function(obj) {
  var msg = new proto.type.ShippingInfo();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.familyName != null && jspb.Message.setField(msg, 2, obj.familyName);
  obj.firstName != null && jspb.Message.setField(msg, 3, obj.firstName);
  obj.familyNameKana != null && jspb.Message.setField(msg, 4, obj.familyNameKana);
  obj.firstNameKana != null && jspb.Message.setField(msg, 5, obj.firstNameKana);
  obj.phoneNumber != null && jspb.Message.setField(msg, 6, obj.phoneNumber);
  obj.address && jspb.Message.setWrapperField(
      msg, 7, type_address_pb.Address.fromObject(obj.address));
  obj.isDefault != null && jspb.Message.setField(msg, 8, obj.isDefault);
  obj.deliveryUnattendedLocation != null && jspb.Message.setField(msg, 9, obj.deliveryUnattendedLocation);
  obj.isAutoLockApartment && jspb.Message.setWrapperField(
      msg, 10, google_protobuf_wrappers_pb.BoolValue.fromObject(obj.isAutoLockApartment));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.ShippingInfo}
 */
proto.type.ShippingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.ShippingInfo;
  return proto.type.ShippingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.ShippingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.ShippingInfo}
 */
proto.type.ShippingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyNameKana(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstNameKana(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = new type_address_pb.Address;
      reader.readMessage(value,type_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryUnattendedLocation(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsAutoLockApartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.ShippingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.ShippingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.ShippingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.ShippingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFamilyNameKana();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstNameKana();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      type_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDeliveryUnattendedLocation();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsAutoLockApartment();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string family_name = 2;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string family_name_kana = 4;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getFamilyNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setFamilyNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string first_name_kana = 5;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getFirstNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setFirstNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Address address = 7;
 * @return {?proto.type.Address}
 */
proto.type.ShippingInfo.prototype.getAddress = function() {
  return /** @type{?proto.type.Address} */ (
    jspb.Message.getWrapperField(this, type_address_pb.Address, 7));
};


/**
 * @param {?proto.type.Address|undefined} value
 * @return {!proto.type.ShippingInfo} returns this
*/
proto.type.ShippingInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.ShippingInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_default = 8;
 * @return {boolean}
 */
proto.type.ShippingInfo.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string delivery_unattended_location = 9;
 * @return {string}
 */
proto.type.ShippingInfo.prototype.getDeliveryUnattendedLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.setDeliveryUnattendedLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.BoolValue is_auto_lock_apartment = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.type.ShippingInfo.prototype.getIsAutoLockApartment = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.type.ShippingInfo} returns this
*/
proto.type.ShippingInfo.prototype.setIsAutoLockApartment = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.ShippingInfo} returns this
 */
proto.type.ShippingInfo.prototype.clearIsAutoLockApartment = function() {
  return this.setIsAutoLockApartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.ShippingInfo.prototype.hasIsAutoLockApartment = function() {
  return jspb.Message.getField(this, 10) != null;
};


goog.object.extend(exports, proto.type);
