import { OrderReceiveKind } from '@apis/type/order_receive_type_pb'

const Delivery = 'Delivery' as const
const Pickup = 'Pickup' as const
const None = 'None' as const

export const OrderReceiveKindTypes = {
  Delivery,
  Pickup,
  None,
} as const

export type OrderReceiveKindType =
  (typeof OrderReceiveKindTypes)[keyof typeof OrderReceiveKindTypes]

export const getOrderReceiveKindType = (kind: OrderReceiveKind): OrderReceiveKindType => {
  switch (kind) {
    case OrderReceiveKind.DELIVERY:
      return Delivery
    case OrderReceiveKind.PICKUP_SHOP:
      return Pickup
    case OrderReceiveKind.PICKUP_CAR:
      return Pickup
    case OrderReceiveKind.NONE_RECEIVE_TYPE:
      return None
    case OrderReceiveKind.PICKUP_LOCKER:
      return Pickup
    case OrderReceiveKind.PICKUP_OTHER_SHOP:
      return Pickup
    case OrderReceiveKind.DELIVERY_UNATTENDED:
      return Delivery
    case OrderReceiveKind.DELIVERY_CONTACTLESS:
      return Delivery
    default:
      throw new Error(`${kind} is unexpected kind.`)
  }
}
