// source: type/transaction_product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var type_container_type_pb = require('../type/container_type_pb.js');
goog.object.extend(proto, type_container_type_pb);
var type_ec_product_pb = require('../type/ec_product_pb.js');
goog.object.extend(proto, type_ec_product_pb);
var type_price_type_pb = require('../type/price_type_pb.js');
goog.object.extend(proto, type_price_type_pb);
var type_tax_type_pb = require('../type/tax_type_pb.js');
goog.object.extend(proto, type_tax_type_pb);
goog.exportSymbol('proto.type.TransactionProduct', null, global);
goog.exportSymbol('proto.type.TransactionProduct.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.TransactionProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.TransactionProduct.repeatedFields_, null);
};
goog.inherits(proto.type.TransactionProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.TransactionProduct.displayName = 'proto.type.TransactionProduct';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.TransactionProduct.repeatedFields_ = [27,34];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.TransactionProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.type.TransactionProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.TransactionProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.TransactionProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noTaxPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inTaxPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    productName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pointTimes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isSelfMedication: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isReducedTaxRate: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    taxRate: (f = msg.getTaxRate()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    salesAgeRestrictionType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    drugRiskClassification: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isPcr: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    taxType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    isExcludedEarnedPoint: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    price: jspb.Message.getFieldWithDefault(msg, 17, 0),
    priceType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isAlcohol: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    origin: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isIneligibleBonusPoint: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    isAddedByCustomerRequest: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    isAlternative: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    id: jspb.Message.getFieldWithDefault(msg, 24, ""),
    originalId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    pickingAssigneeId: jspb.Message.getFieldWithDefault(msg, 29, ""),
    onHoldReason: jspb.Message.getFieldWithDefault(msg, 30, ""),
    isPickedWithoutScan: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    isLabelPrinted: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    initialStatusChangedAt: (f = msg.getInitialStatusChangedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scanCodesList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
    containerType: jspb.Message.getFieldWithDefault(msg, 35, 0),
    isOriginInputRequired: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 38, 0),
    location: jspb.Message.getFieldWithDefault(msg, 39, ""),
    mainScanCode: jspb.Message.getFieldWithDefault(msg, 40, ""),
    janCode: jspb.Message.getFieldWithDefault(msg, 41, ""),
    deprecatedTaxType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deprecatedOriginalPriceForAlternative: jspb.Message.getFieldWithDefault(msg, 37, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of TransactionProduct as accepted by the `fromObject` method.
 * @record
 */
proto.type.TransactionProduct.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.productId;

  /** @type {?|undefined} */
  this.noTaxPrice;

  /** @type {?|undefined} */
  this.amount;

  /** @type {?|undefined} */
  this.inTaxPrice;

  /** @type {?|undefined} */
  this.productName;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.pointTimes;

  /** @type {?|undefined} */
  this.isSelfMedication;

  /** @type {?|undefined} */
  this.isReducedTaxRate;

  /** @type {?|undefined} */
  this.taxRate;

  /** @type {?|undefined} */
  this.salesAgeRestrictionType;

  /** @type {?|undefined} */
  this.drugRiskClassification;

  /** @type {?|undefined} */
  this.isPcr;

  /** @type {?|undefined} */
  this.taxType;

  /** @type {?|undefined} */
  this.isExcludedEarnedPoint;

  /** @type {?|undefined} */
  this.price;

  /** @type {?|undefined} */
  this.priceType;

  /** @type {?|undefined} */
  this.isAlcohol;

  /** @type {?|undefined} */
  this.origin;

  /** @type {?|undefined} */
  this.isIneligibleBonusPoint;

  /** @type {?|undefined} */
  this.isAddedByCustomerRequest;

  /** @type {?|undefined} */
  this.isAlternative;

  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.originalId;

  /** @type {?|undefined} */
  this.statusesList;

  /** @type {?|undefined} */
  this.pickingAssigneeId;

  /** @type {?|undefined} */
  this.onHoldReason;

  /** @type {?|undefined} */
  this.isPickedWithoutScan;

  /** @type {?|undefined} */
  this.isLabelPrinted;

  /** @type {?|undefined} */
  this.initialStatusChangedAt;

  /** @type {?|undefined} */
  this.scanCodesList;

  /** @type {?|undefined} */
  this.containerType;

  /** @type {?|undefined} */
  this.isOriginInputRequired;

  /** @type {?|undefined} */
  this.totalPrice;

  /** @type {?|undefined} */
  this.location;

  /** @type {?|undefined} */
  this.mainScanCode;

  /** @type {?|undefined} */
  this.janCode;

  /** @type {?|undefined} */
  this.deprecatedTaxType;

  /** @type {?|undefined} */
  this.deprecatedOriginalPriceForAlternative;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.TransactionProduct.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.TransactionProduct}
 */
proto.type.TransactionProduct.fromObject = function(obj) {
  var msg = new proto.type.TransactionProduct();
  obj.productId != null && jspb.Message.setField(msg, 1, obj.productId);
  obj.noTaxPrice != null && jspb.Message.setField(msg, 2, obj.noTaxPrice);
  obj.amount != null && jspb.Message.setField(msg, 3, obj.amount);
  obj.inTaxPrice != null && jspb.Message.setField(msg, 4, obj.inTaxPrice);
  obj.productName != null && jspb.Message.setField(msg, 5, obj.productName);
  obj.imageUrl != null && jspb.Message.setField(msg, 6, obj.imageUrl);
  obj.pointTimes != null && jspb.Message.setField(msg, 7, obj.pointTimes);
  obj.isSelfMedication != null && jspb.Message.setField(msg, 9, obj.isSelfMedication);
  obj.isReducedTaxRate != null && jspb.Message.setField(msg, 10, obj.isReducedTaxRate);
  obj.taxRate && jspb.Message.setWrapperField(
      msg, 11, google_protobuf_wrappers_pb.UInt64Value.fromObject(obj.taxRate));
  obj.salesAgeRestrictionType != null && jspb.Message.setField(msg, 12, obj.salesAgeRestrictionType);
  obj.drugRiskClassification != null && jspb.Message.setField(msg, 13, obj.drugRiskClassification);
  obj.isPcr != null && jspb.Message.setField(msg, 14, obj.isPcr);
  obj.taxType != null && jspb.Message.setField(msg, 15, obj.taxType);
  obj.isExcludedEarnedPoint != null && jspb.Message.setField(msg, 16, obj.isExcludedEarnedPoint);
  obj.price != null && jspb.Message.setField(msg, 17, obj.price);
  obj.priceType != null && jspb.Message.setField(msg, 18, obj.priceType);
  obj.isAlcohol != null && jspb.Message.setField(msg, 19, obj.isAlcohol);
  obj.origin != null && jspb.Message.setField(msg, 20, obj.origin);
  obj.isIneligibleBonusPoint != null && jspb.Message.setField(msg, 21, obj.isIneligibleBonusPoint);
  obj.isAddedByCustomerRequest != null && jspb.Message.setField(msg, 22, obj.isAddedByCustomerRequest);
  obj.isAlternative != null && jspb.Message.setField(msg, 23, obj.isAlternative);
  obj.id != null && jspb.Message.setField(msg, 24, obj.id);
  obj.originalId != null && jspb.Message.setField(msg, 26, obj.originalId);
  obj.statusesList != null && jspb.Message.setField(msg, 27, obj.statusesList);
  obj.pickingAssigneeId != null && jspb.Message.setField(msg, 29, obj.pickingAssigneeId);
  obj.onHoldReason != null && jspb.Message.setField(msg, 30, obj.onHoldReason);
  obj.isPickedWithoutScan != null && jspb.Message.setField(msg, 31, obj.isPickedWithoutScan);
  obj.isLabelPrinted != null && jspb.Message.setField(msg, 32, obj.isLabelPrinted);
  obj.initialStatusChangedAt && jspb.Message.setWrapperField(
      msg, 33, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.initialStatusChangedAt));
  obj.scanCodesList != null && jspb.Message.setField(msg, 34, obj.scanCodesList);
  obj.containerType != null && jspb.Message.setField(msg, 35, obj.containerType);
  obj.isOriginInputRequired != null && jspb.Message.setField(msg, 36, obj.isOriginInputRequired);
  obj.totalPrice != null && jspb.Message.setField(msg, 38, obj.totalPrice);
  obj.location != null && jspb.Message.setField(msg, 39, obj.location);
  obj.mainScanCode != null && jspb.Message.setField(msg, 40, obj.mainScanCode);
  obj.janCode != null && jspb.Message.setField(msg, 41, obj.janCode);
  obj.deprecatedTaxType != null && jspb.Message.setField(msg, 8, obj.deprecatedTaxType);
  obj.deprecatedOriginalPriceForAlternative != null && jspb.Message.setField(msg, 37, obj.deprecatedOriginalPriceForAlternative);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.TransactionProduct}
 */
proto.type.TransactionProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.TransactionProduct;
  return proto.type.TransactionProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.TransactionProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.TransactionProduct}
 */
proto.type.TransactionProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoTaxPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInTaxPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointTimes(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSelfMedication(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReducedTaxRate(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setTaxRate(value);
      break;
    case 12:
      var value = /** @type {!proto.type.SalesAgeRestrictionType} */ (reader.readEnum());
      msg.setSalesAgeRestrictionType(value);
      break;
    case 13:
      var value = /** @type {!proto.type.DrugRiskClassification} */ (reader.readEnum());
      msg.setDrugRiskClassification(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPcr(value);
      break;
    case 15:
      var value = /** @type {!proto.type.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExcludedEarnedPoint(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 18:
      var value = /** @type {!proto.type.PriceType} */ (reader.readEnum());
      msg.setPriceType(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAlcohol(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIneligibleBonusPoint(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAddedByCustomerRequest(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAlternative(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalId(value);
      break;
    case 27:
      var values = /** @type {!Array<!proto.type.TransactionProduct.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickingAssigneeId(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnHoldReason(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPickedWithoutScan(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLabelPrinted(value);
      break;
    case 33:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInitialStatusChangedAt(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanCodes(value);
      break;
    case 35:
      var value = /** @type {!proto.type.ContainerType} */ (reader.readEnum());
      msg.setContainerType(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOriginInputRequired(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalPrice(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainScanCode(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setJanCode(value);
      break;
    case 8:
      var value = /** @type {!proto.type.TaxType} */ (reader.readEnum());
      msg.setDeprecatedTaxType(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeprecatedOriginalPriceForAlternative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.TransactionProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.TransactionProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.TransactionProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.TransactionProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoTaxPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInTaxPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPointTimes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getIsSelfMedication();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsReducedTaxRate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getTaxRate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getSalesAgeRestrictionType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDrugRiskClassification();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getIsPcr();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getIsExcludedEarnedPoint();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getPriceType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIsAlcohol();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsIneligibleBonusPoint();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIsAddedByCustomerRequest();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getIsAlternative();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOriginalId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      27,
      f
    );
  }
  f = message.getPickingAssigneeId();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getOnHoldReason();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getIsPickedWithoutScan();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getIsLabelPrinted();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getInitialStatusChangedAt();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScanCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      34,
      f
    );
  }
  f = message.getContainerType();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getIsOriginInputRequired();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getMainScanCode();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getJanCode();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getDeprecatedTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDeprecatedOriginalPriceForAlternative();
  if (f !== 0) {
    writer.writeInt64(
      37,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.TransactionProduct.Status = {
  WAITING_FOR_PICKING: 0,
  PICKED: 1,
  ON_HOLD: 2,
  STOCK_OUT: 3,
  PACKED: 4,
  WAITING_FOR_ALTERNATIVE: 5,
  UNABLE_TO_STORE_TO_RECEIVING_LOCKER: 6,
  DEFECTIVE_PRODUCT: 7,
  CANCEL_BY_CUSTOMER: 8
};

/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 no_tax_price = 2;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getNoTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setNoTaxPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double in_tax_price = 4;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getInTaxPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setInTaxPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string product_name = 5;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 point_times = 7;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getPointTimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setPointTimes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_self_medication = 9;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsSelfMedication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsSelfMedication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_reduced_tax_rate = 10;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsReducedTaxRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsReducedTaxRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.UInt64Value tax_rate = 11;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.type.TransactionProduct.prototype.getTaxRate = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 11));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.type.TransactionProduct} returns this
*/
proto.type.TransactionProduct.prototype.setTaxRate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.clearTaxRate = function() {
  return this.setTaxRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.hasTaxRate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SalesAgeRestrictionType sales_age_restriction_type = 12;
 * @return {!proto.type.SalesAgeRestrictionType}
 */
proto.type.TransactionProduct.prototype.getSalesAgeRestrictionType = function() {
  return /** @type {!proto.type.SalesAgeRestrictionType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.type.SalesAgeRestrictionType} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setSalesAgeRestrictionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional DrugRiskClassification drug_risk_classification = 13;
 * @return {!proto.type.DrugRiskClassification}
 */
proto.type.TransactionProduct.prototype.getDrugRiskClassification = function() {
  return /** @type {!proto.type.DrugRiskClassification} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.type.DrugRiskClassification} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setDrugRiskClassification = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool is_pcr = 14;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsPcr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsPcr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional TaxType tax_type = 15;
 * @return {!proto.type.TaxType}
 */
proto.type.TransactionProduct.prototype.getTaxType = function() {
  return /** @type {!proto.type.TaxType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.type.TaxType} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool is_excluded_earned_point = 16;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsExcludedEarnedPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsExcludedEarnedPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int64 price = 17;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional PriceType price_type = 18;
 * @return {!proto.type.PriceType}
 */
proto.type.TransactionProduct.prototype.getPriceType = function() {
  return /** @type {!proto.type.PriceType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.type.PriceType} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setPriceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional bool is_alcohol = 19;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsAlcohol = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsAlcohol = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string origin = 20;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool is_ineligible_bonus_point = 21;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsIneligibleBonusPoint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsIneligibleBonusPoint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool is_added_by_customer_request = 22;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsAddedByCustomerRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsAddedByCustomerRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool is_alternative = 23;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsAlternative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsAlternative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional string id = 24;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string original_id = 26;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getOriginalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setOriginalId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated Status statuses = 27;
 * @return {!Array<!proto.type.TransactionProduct.Status>}
 */
proto.type.TransactionProduct.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.type.TransactionProduct.Status>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<!proto.type.TransactionProduct.Status>} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {!proto.type.TransactionProduct.Status} value
 * @param {number=} opt_index
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional string picking_assignee_id = 29;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getPickingAssigneeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setPickingAssigneeId = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string on_hold_reason = 30;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getOnHoldReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setOnHoldReason = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool is_picked_without_scan = 31;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsPickedWithoutScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsPickedWithoutScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool is_label_printed = 32;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsLabelPrinted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsLabelPrinted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional google.protobuf.Timestamp initial_status_changed_at = 33;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.TransactionProduct.prototype.getInitialStatusChangedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 33));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.TransactionProduct} returns this
*/
proto.type.TransactionProduct.prototype.setInitialStatusChangedAt = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.clearInitialStatusChangedAt = function() {
  return this.setInitialStatusChangedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.hasInitialStatusChangedAt = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated string scan_codes = 34;
 * @return {!Array<string>}
 */
proto.type.TransactionProduct.prototype.getScanCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 34));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setScanCodesList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.addScanCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.clearScanCodesList = function() {
  return this.setScanCodesList([]);
};


/**
 * optional ContainerType container_type = 35;
 * @return {!proto.type.ContainerType}
 */
proto.type.TransactionProduct.prototype.getContainerType = function() {
  return /** @type {!proto.type.ContainerType} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.type.ContainerType} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setContainerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional bool is_origin_input_required = 36;
 * @return {boolean}
 */
proto.type.TransactionProduct.prototype.getIsOriginInputRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setIsOriginInputRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional int64 total_price = 38;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional string location = 39;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string main_scan_code = 40;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getMainScanCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setMainScanCode = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string jan_code = 41;
 * @return {string}
 */
proto.type.TransactionProduct.prototype.getJanCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setJanCode = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional TaxType deprecated_tax_type = 8;
 * @return {!proto.type.TaxType}
 */
proto.type.TransactionProduct.prototype.getDeprecatedTaxType = function() {
  return /** @type {!proto.type.TaxType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.type.TaxType} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setDeprecatedTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 deprecated_original_price_for_alternative = 37;
 * @return {number}
 */
proto.type.TransactionProduct.prototype.getDeprecatedOriginalPriceForAlternative = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.TransactionProduct} returns this
 */
proto.type.TransactionProduct.prototype.setDeprecatedOriginalPriceForAlternative = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


goog.object.extend(exports, proto.type);
