// source: type/ec_site_category.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_reservation_group_pb = require('../type/reservation_group_pb.js');
goog.object.extend(proto, type_reservation_group_pb);
goog.exportSymbol('proto.type.EcSiteCategory', null, global);
goog.exportSymbol('proto.type.EcSiteCategory.Status', null, global);
goog.exportSymbol('proto.type.EcSiteCategory.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcSiteCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.EcSiteCategory.repeatedFields_, null);
};
goog.inherits(proto.type.EcSiteCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcSiteCategory.displayName = 'proto.type.EcSiteCategory';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.EcSiteCategory.repeatedFields_ = [9,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcSiteCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcSiteCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcSiteCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    shopId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteCategoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    haschildcategory: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isInitialTopTab: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.type.EcSiteCategory.toObject, includeInstance),
    isExpanded: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    productImageUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    showSubCategoryList: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    ancestorNamesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    siteCategoryPath: jspb.Message.getFieldWithDefault(msg, 14, ""),
    deprecatedReservationGroup: (f = msg.getDeprecatedReservationGroup()) && type_reservation_group_pb.DeprecatedReservationGroup.toObject(includeInstance, f),
    reservationGroupId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    categoryType: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSiteCategory as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcSiteCategory.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.siteCategoryId;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.haschildcategory;

  /** @type {?|undefined} */
  this.order;

  /** @type {?|undefined} */
  this.isInitialTopTab;

  /** @type {?|undefined} */
  this.imageUrl;

  /** @type {?|undefined} */
  this.childrenList;

  /** @type {?|undefined} */
  this.isExpanded;

  /** @type {?|undefined} */
  this.productImageUrl;

  /** @type {?|undefined} */
  this.showSubCategoryList;

  /** @type {?|undefined} */
  this.ancestorNamesList;

  /** @type {?|undefined} */
  this.siteCategoryPath;

  /** @type {?|undefined} */
  this.deprecatedReservationGroup;

  /** @type {?|undefined} */
  this.reservationGroupId;

  /** @type {?|undefined} */
  this.categoryType;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcSiteCategory.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcSiteCategory}
 */
proto.type.EcSiteCategory.fromObject = function(obj) {
  var msg = new proto.type.EcSiteCategory();
  obj.shopId != null && jspb.Message.setField(msg, 1, obj.shopId);
  obj.siteType != null && jspb.Message.setField(msg, 2, obj.siteType);
  obj.siteCategoryId != null && jspb.Message.setField(msg, 3, obj.siteCategoryId);
  obj.name != null && jspb.Message.setField(msg, 4, obj.name);
  obj.haschildcategory != null && jspb.Message.setField(msg, 5, obj.haschildcategory);
  obj.order != null && jspb.Message.setField(msg, 6, obj.order);
  obj.isInitialTopTab != null && jspb.Message.setField(msg, 7, obj.isInitialTopTab);
  obj.imageUrl != null && jspb.Message.setField(msg, 8, obj.imageUrl);
  obj.childrenList && jspb.Message.setRepeatedWrapperField(
      msg, 9, obj.childrenList.map(
          proto.type.EcSiteCategory.fromObject));
  obj.isExpanded != null && jspb.Message.setField(msg, 10, obj.isExpanded);
  obj.productImageUrl != null && jspb.Message.setField(msg, 11, obj.productImageUrl);
  obj.showSubCategoryList != null && jspb.Message.setField(msg, 12, obj.showSubCategoryList);
  obj.ancestorNamesList != null && jspb.Message.setField(msg, 13, obj.ancestorNamesList);
  obj.siteCategoryPath != null && jspb.Message.setField(msg, 14, obj.siteCategoryPath);
  obj.deprecatedReservationGroup && jspb.Message.setWrapperField(
      msg, 15, type_reservation_group_pb.DeprecatedReservationGroup.fromObject(obj.deprecatedReservationGroup));
  obj.reservationGroupId != null && jspb.Message.setField(msg, 16, obj.reservationGroupId);
  obj.categoryType != null && jspb.Message.setField(msg, 17, obj.categoryType);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcSiteCategory}
 */
proto.type.EcSiteCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcSiteCategory;
  return proto.type.EcSiteCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcSiteCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcSiteCategory}
 */
proto.type.EcSiteCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 2:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHaschildcategory(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInitialTopTab(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 9:
      var value = new proto.type.EcSiteCategory;
      reader.readMessage(value,proto.type.EcSiteCategory.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpanded(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductImageUrl(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowSubCategoryList(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addAncestorNames(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteCategoryPath(value);
      break;
    case 15:
      var value = new type_reservation_group_pb.DeprecatedReservationGroup;
      reader.readMessage(value,type_reservation_group_pb.DeprecatedReservationGroup.deserializeBinaryFromReader);
      msg.setDeprecatedReservationGroup(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationGroupId(value);
      break;
    case 17:
      var value = /** @type {!proto.type.EcSiteCategory.Type} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcSiteCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcSiteCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcSiteCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSiteCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHaschildcategory();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsInitialTopTab();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.type.EcSiteCategory.serializeBinaryToWriter
    );
  }
  f = message.getIsExpanded();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProductImageUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getShowSubCategoryList();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAncestorNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getSiteCategoryPath();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDeprecatedReservationGroup();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      type_reservation_group_pb.DeprecatedReservationGroup.serializeBinaryToWriter
    );
  }
  f = message.getReservationGroupId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.EcSiteCategory.Type = {
  TYPE_NORMAL: 0,
  TYPE_FEATURE: 1,
  TYPE_PROMOTION: 2,
  TYPE_RESERVATION: 3
};

/**
 * @enum {number}
 */
proto.type.EcSiteCategory.Status = {
  CREATED: 0,
  RESERVED: 1,
  PUBLISHED: 2,
  CLOSED: 3
};

/**
 * optional string shop_id = 1;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EcSite.SiteType site_type = 2;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.EcSiteCategory.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string site_category_id = 3;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getSiteCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setSiteCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool hasChildCategory = 5;
 * @return {boolean}
 */
proto.type.EcSiteCategory.prototype.getHaschildcategory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setHaschildcategory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 order = 6;
 * @return {number}
 */
proto.type.EcSiteCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_initial_top_tab = 7;
 * @return {boolean}
 */
proto.type.EcSiteCategory.prototype.getIsInitialTopTab = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setIsInitialTopTab = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string image_url = 8;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated EcSiteCategory children = 9;
 * @return {!Array<!proto.type.EcSiteCategory>}
 */
proto.type.EcSiteCategory.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.type.EcSiteCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.type.EcSiteCategory, 9));
};


/**
 * @param {!Array<!proto.type.EcSiteCategory>} value
 * @return {!proto.type.EcSiteCategory} returns this
*/
proto.type.EcSiteCategory.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.type.EcSiteCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.EcSiteCategory}
 */
proto.type.EcSiteCategory.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.type.EcSiteCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional bool is_expanded = 10;
 * @return {boolean}
 */
proto.type.EcSiteCategory.prototype.getIsExpanded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setIsExpanded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string product_image_url = 11;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getProductImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setProductImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool show_sub_category_list = 12;
 * @return {boolean}
 */
proto.type.EcSiteCategory.prototype.getShowSubCategoryList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setShowSubCategoryList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated string ancestor_names = 13;
 * @return {!Array<string>}
 */
proto.type.EcSiteCategory.prototype.getAncestorNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setAncestorNamesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.addAncestorNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.clearAncestorNamesList = function() {
  return this.setAncestorNamesList([]);
};


/**
 * optional string site_category_path = 14;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getSiteCategoryPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setSiteCategoryPath = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional DeprecatedReservationGroup deprecated_reservation_group = 15;
 * @return {?proto.type.DeprecatedReservationGroup}
 */
proto.type.EcSiteCategory.prototype.getDeprecatedReservationGroup = function() {
  return /** @type{?proto.type.DeprecatedReservationGroup} */ (
    jspb.Message.getWrapperField(this, type_reservation_group_pb.DeprecatedReservationGroup, 15));
};


/**
 * @param {?proto.type.DeprecatedReservationGroup|undefined} value
 * @return {!proto.type.EcSiteCategory} returns this
*/
proto.type.EcSiteCategory.prototype.setDeprecatedReservationGroup = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.clearDeprecatedReservationGroup = function() {
  return this.setDeprecatedReservationGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteCategory.prototype.hasDeprecatedReservationGroup = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string reservation_group_id = 16;
 * @return {string}
 */
proto.type.EcSiteCategory.prototype.getReservationGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setReservationGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional Type category_type = 17;
 * @return {!proto.type.EcSiteCategory.Type}
 */
proto.type.EcSiteCategory.prototype.getCategoryType = function() {
  return /** @type {!proto.type.EcSiteCategory.Type} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.type.EcSiteCategory.Type} value
 * @return {!proto.type.EcSiteCategory} returns this
 */
proto.type.EcSiteCategory.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


goog.object.extend(exports, proto.type);
