import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Metadata } from 'grpc-web'
import config, { Environment } from 'config'
import firebaseApp from '~/firebase/defaultApp'
import { trimHostForBranchDeployOnDev, getHostForSiteConfig } from '~/utils/env'

declare module 'vue/types/vue' {
  interface Vue {
    $signInWithEmailLink(email: string, url: string): Promise<firebase.auth.UserCredential>
    $signInWithEmailAndPassword(
      email: string,
      password: string,
    ): Promise<firebase.auth.UserCredential>
    $signInWithCustomToken(customToken: string): Promise<firebase.auth.UserCredential>
    $signOut(): Promise<void>
    $isSignInWithEmailLink(url: string): boolean
    $sendPasswordResetEmail(email: string): Promise<void>
    $verifyPasswordResetCode(actionCode: string): Promise<string>
    $confirmPasswordReset(actionCode: string, newPassword: string): Promise<void>
    $updateEmail(email: string): Promise<void>
    $handleRecoverEmail(actionCode: string): Promise<string>
    $getMetadata(): Promise<Metadata>
  }
}

Vue.prototype.$signInWithEmailLink = async (email: string, url: string) => {
  await firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  return firebaseApp.auth().signInWithEmailLink(email, url)
}

Vue.prototype.$signInWithEmailAndPassword = async (email: string, password: string) => {
  await firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  return firebaseApp.auth().signInWithEmailAndPassword(email, password)
}

Vue.prototype.$signInWithCustomToken = (customToken: string) => {
  return firebaseApp.auth().signInWithCustomToken(customToken)
}

Vue.prototype.$signOut = async () => {
  await firebaseApp.auth().signOut()
}

Vue.prototype.$isSignInWithEmailLink = (url: string) => {
  return firebaseApp.auth().isSignInWithEmailLink(url)
}

Vue.prototype.$sendPasswordResetEmail = (email: string) => {
  const trimmedHost = trimHostForBranchDeployOnDev(location.host)
  return firebaseApp.auth().sendPasswordResetEmail(email, {
    url: `https://${trimmedHost}/newPassword`,
  })
}

Vue.prototype.$verifyPasswordResetCode = (actionCode: string) => {
  return firebaseApp.auth().verifyPasswordResetCode(actionCode)
}

Vue.prototype.$confirmPasswordReset = (actionCode: string, newPassword: string) => {
  return firebaseApp.auth().confirmPasswordReset(actionCode, newPassword)
}

Vue.prototype.$updateEmail = (email: string) => {
  const currentUser = firebaseApp.auth().currentUser
  if (currentUser === null) {
    throw new Error('Sign in required')
  }

  return currentUser.updateEmail(email)
}

Vue.prototype.$handleRecoverEmail = async (actionCode: string) => {
  const currentUser = firebaseApp.auth().currentUser
  if (currentUser === null) {
    throw new Error('Sign in required')
  }
  let restoredEmail: string | null | undefined = null
  const info = await firebaseApp.auth().checkActionCode(actionCode)
  restoredEmail = info.data.email
  await firebaseApp.auth().applyActionCode(actionCode)
  return restoredEmail
}

Vue.prototype.$getMetadata = async () => {
  const pathName = location.pathname
  if (config.environment === Environment.local) {
    const uid = firebaseApp.auth().currentUser?.uid
    const base64Json = btoa(JSON.stringify({ user_id: uid }))
    const host = getHostForSiteConfig(location.host)
    const projectId = config.siteConfigMapping[host].firebaseConfig.projectId
    return {
      'x-endpoint-api-userinfo': base64Json,
      'x-stailer-external-admin-web': '1',
      'x-forwarded-for': '150.249.249.44', // ローカルなので偽装
      'x-stailer-project-id': projectId,
      'x-stailer-rpc-caller-web-path': pathName,
    }
  } else {
    const token = await firebaseApp.auth().currentUser?.getIdToken()
    return {
      Authorization: `Bearer ${token}`,
      'x-stailer-external-admin-web': '1',
      'x-stailer-rpc-caller-web-path': pathName,
    }
  }
}
