// source: type/orderer_info.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var type_address_pb = require('../type/address_pb.js');
goog.object.extend(proto, type_address_pb);
goog.exportSymbol('proto.type.OrdererInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.OrdererInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.OrdererInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.OrdererInfo.displayName = 'proto.type.OrdererInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.OrdererInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.type.OrdererInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.OrdererInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.OrdererInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    familyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    familyNameKana: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstNameKana: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address: (f = msg.getAddress()) && type_address_pb.Address.toObject(includeInstance, f),
    corporateName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    corporateNameKana: jspb.Message.getFieldWithDefault(msg, 15, ""),
    deliveryUnattendedLocation: jspb.Message.getFieldWithDefault(msg, 16, ""),
    isAutoLockApartment: (f = msg.getIsAutoLockApartment()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    deprecatedPostalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deprecatedPrefecture: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deprecatedAddress1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    deprecatedAddress2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deprecatedApartment: jspb.Message.getFieldWithDefault(msg, 10, ""),
    deprecatedCity: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of OrdererInfo as accepted by the `fromObject` method.
 * @record
 */
proto.type.OrdererInfo.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.familyName;

  /** @type {?|undefined} */
  this.firstName;

  /** @type {?|undefined} */
  this.familyNameKana;

  /** @type {?|undefined} */
  this.firstNameKana;

  /** @type {?|undefined} */
  this.phoneNumber;

  /** @type {?|undefined} */
  this.birthday;

  /** @type {?|undefined} */
  this.address;

  /** @type {?|undefined} */
  this.corporateName;

  /** @type {?|undefined} */
  this.corporateNameKana;

  /** @type {?|undefined} */
  this.deliveryUnattendedLocation;

  /** @type {?|undefined} */
  this.isAutoLockApartment;

  /** @type {?|undefined} */
  this.deprecatedPostalCode;

  /** @type {?|undefined} */
  this.deprecatedPrefecture;

  /** @type {?|undefined} */
  this.deprecatedAddress1;

  /** @type {?|undefined} */
  this.deprecatedAddress2;

  /** @type {?|undefined} */
  this.deprecatedApartment;

  /** @type {?|undefined} */
  this.deprecatedCity;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.OrdererInfo.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.OrdererInfo}
 */
proto.type.OrdererInfo.fromObject = function(obj) {
  var msg = new proto.type.OrdererInfo();
  obj.familyName != null && jspb.Message.setField(msg, 1, obj.familyName);
  obj.firstName != null && jspb.Message.setField(msg, 2, obj.firstName);
  obj.familyNameKana != null && jspb.Message.setField(msg, 3, obj.familyNameKana);
  obj.firstNameKana != null && jspb.Message.setField(msg, 4, obj.firstNameKana);
  obj.phoneNumber != null && jspb.Message.setField(msg, 5, obj.phoneNumber);
  obj.birthday != null && jspb.Message.setField(msg, 12, obj.birthday);
  obj.address && jspb.Message.setWrapperField(
      msg, 13, type_address_pb.Address.fromObject(obj.address));
  obj.corporateName != null && jspb.Message.setField(msg, 14, obj.corporateName);
  obj.corporateNameKana != null && jspb.Message.setField(msg, 15, obj.corporateNameKana);
  obj.deliveryUnattendedLocation != null && jspb.Message.setField(msg, 16, obj.deliveryUnattendedLocation);
  obj.isAutoLockApartment && jspb.Message.setWrapperField(
      msg, 17, google_protobuf_wrappers_pb.BoolValue.fromObject(obj.isAutoLockApartment));
  obj.deprecatedPostalCode != null && jspb.Message.setField(msg, 6, obj.deprecatedPostalCode);
  obj.deprecatedPrefecture != null && jspb.Message.setField(msg, 7, obj.deprecatedPrefecture);
  obj.deprecatedAddress1 != null && jspb.Message.setField(msg, 8, obj.deprecatedAddress1);
  obj.deprecatedAddress2 != null && jspb.Message.setField(msg, 9, obj.deprecatedAddress2);
  obj.deprecatedApartment != null && jspb.Message.setField(msg, 10, obj.deprecatedApartment);
  obj.deprecatedCity != null && jspb.Message.setField(msg, 11, obj.deprecatedCity);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.OrdererInfo}
 */
proto.type.OrdererInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.OrdererInfo;
  return proto.type.OrdererInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.OrdererInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.OrdererInfo}
 */
proto.type.OrdererInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyNameKana(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstNameKana(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 13:
      var value = new type_address_pb.Address;
      reader.readMessage(value,type_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorporateName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorporateNameKana(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryUnattendedLocation(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsAutoLockApartment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedPrefecture(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedAddress1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedAddress2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedApartment(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedCity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.OrdererInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.OrdererInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.OrdererInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.OrdererInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFamilyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFamilyNameKana();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstNameKana();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      type_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getCorporateName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCorporateNameKana();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDeliveryUnattendedLocation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIsAutoLockApartment();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDeprecatedPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeprecatedPrefecture();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeprecatedAddress1();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDeprecatedAddress2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeprecatedApartment();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDeprecatedCity();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string family_name = 1;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getFamilyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setFamilyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string family_name_kana = 3;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getFamilyNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setFamilyNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name_kana = 4;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getFirstNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setFirstNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string birthday = 12;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Address address = 13;
 * @return {?proto.type.Address}
 */
proto.type.OrdererInfo.prototype.getAddress = function() {
  return /** @type{?proto.type.Address} */ (
    jspb.Message.getWrapperField(this, type_address_pb.Address, 13));
};


/**
 * @param {?proto.type.Address|undefined} value
 * @return {!proto.type.OrdererInfo} returns this
*/
proto.type.OrdererInfo.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.OrdererInfo.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string corporate_name = 14;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getCorporateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setCorporateName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string corporate_name_kana = 15;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getCorporateNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setCorporateNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string delivery_unattended_location = 16;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeliveryUnattendedLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeliveryUnattendedLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.BoolValue is_auto_lock_apartment = 17;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.type.OrdererInfo.prototype.getIsAutoLockApartment = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 17));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.type.OrdererInfo} returns this
*/
proto.type.OrdererInfo.prototype.setIsAutoLockApartment = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.clearIsAutoLockApartment = function() {
  return this.setIsAutoLockApartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.OrdererInfo.prototype.hasIsAutoLockApartment = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string deprecated_postal_code = 6;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string deprecated_prefecture = 7;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string deprecated_address1 = 8;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string deprecated_address2 = 9;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string deprecated_apartment = 10;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedApartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedApartment = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string deprecated_city = 11;
 * @return {string}
 */
proto.type.OrdererInfo.prototype.getDeprecatedCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.OrdererInfo} returns this
 */
proto.type.OrdererInfo.prototype.setDeprecatedCity = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


goog.object.extend(exports, proto.type);
