import { Middleware } from '@nuxt/types'

const AuthMiddleware: Middleware = (context) => {
  const path = context.route.path
  const ignorePaths = [
    /^\/$/,
    /^\/(health|loading|signIn|signUp|firebaseAction|newPassword|recoverEmail|appAccountVerify|appPasswordReset|appEmailChange|app_order_detail)$/,
    /^\/public\//,
  ]
  if (ignorePaths.some((regex) => regex.test(path))) {
    return
  }
  if (process.server || (process.client && !context.app.$session.isSignedIn)) {
    const redirectTo = encodeURIComponent(context.route.fullPath)
    context.redirect(`/loading?redirect_to=${redirectTo}`)
  }
}

export default AuthMiddleware
