// source: type/compensation_entry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_ec_site_pb = require('../type/ec_site_pb.js');
goog.object.extend(proto, type_ec_site_pb);
var type_compensation_claim_pb = require('../type/compensation_claim_pb.js');
goog.object.extend(proto, type_compensation_claim_pb);
goog.exportSymbol('proto.type.CompensationEntry', null, global);
goog.exportSymbol('proto.type.CompensationEntry.PaymentMethod', null, global);
goog.exportSymbol('proto.type.CompensationEntry.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.CompensationEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.CompensationEntry.repeatedFields_, null);
};
goog.inherits(proto.type.CompensationEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.CompensationEntry.displayName = 'proto.type.CompensationEntry';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.CompensationEntry.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.CompensationEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.type.CompensationEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.CompensationEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.CompensationEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 5, 0),
    compensationClaimsList: jspb.Message.toObjectList(msg.getCompensationClaimsList(),
    type_compensation_claim_pb.CompensationClaim.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    staffName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    compensationExecutionDateTime: (f = msg.getCompensationExecutionDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userName: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of CompensationEntry as accepted by the `fromObject` method.
 * @record
 */
proto.type.CompensationEntry.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.userId;

  /** @type {?|undefined} */
  this.orderId;

  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.paymentMethod;

  /** @type {?|undefined} */
  this.compensationClaimsList;

  /** @type {?|undefined} */
  this.status;

  /** @type {?|undefined} */
  this.staffName;

  /** @type {?|undefined} */
  this.compensationExecutionDateTime;

  /** @type {?|undefined} */
  this.createdAt;

  /** @type {?|undefined} */
  this.userName;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.CompensationEntry.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.CompensationEntry}
 */
proto.type.CompensationEntry.fromObject = function(obj) {
  var msg = new proto.type.CompensationEntry();
  obj.id != null && jspb.Message.setField(msg, 1, obj.id);
  obj.userId != null && jspb.Message.setField(msg, 2, obj.userId);
  obj.orderId != null && jspb.Message.setField(msg, 3, obj.orderId);
  obj.siteType != null && jspb.Message.setField(msg, 4, obj.siteType);
  obj.paymentMethod != null && jspb.Message.setField(msg, 5, obj.paymentMethod);
  obj.compensationClaimsList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.compensationClaimsList.map(
          type_compensation_claim_pb.CompensationClaim.fromObject));
  obj.status != null && jspb.Message.setField(msg, 7, obj.status);
  obj.staffName != null && jspb.Message.setField(msg, 8, obj.staffName);
  obj.compensationExecutionDateTime && jspb.Message.setWrapperField(
      msg, 9, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.compensationExecutionDateTime));
  obj.createdAt && jspb.Message.setWrapperField(
      msg, 10, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.createdAt));
  obj.userName != null && jspb.Message.setField(msg, 11, obj.userName);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.CompensationEntry}
 */
proto.type.CompensationEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.CompensationEntry;
  return proto.type.CompensationEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.CompensationEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.CompensationEntry}
 */
proto.type.CompensationEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 4:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 5:
      var value = /** @type {!proto.type.CompensationEntry.PaymentMethod} */ (reader.readEnum());
      msg.setPaymentMethod(value);
      break;
    case 6:
      var value = new type_compensation_claim_pb.CompensationClaim;
      reader.readMessage(value,type_compensation_claim_pb.CompensationClaim.deserializeBinaryFromReader);
      msg.addCompensationClaims(value);
      break;
    case 7:
      var value = /** @type {!proto.type.CompensationEntry.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaffName(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompensationExecutionDateTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.CompensationEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.CompensationEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.CompensationEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.CompensationEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCompensationClaimsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      type_compensation_claim_pb.CompensationClaim.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getStaffName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompensationExecutionDateTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.CompensationEntry.PaymentMethod = {
  PAYMENT_METHOD_UNSPECIFIED: 0,
  CARD: 1,
  BANK_ACCOUNT_TRANSFER: 2
};

/**
 * @enum {number}
 */
proto.type.CompensationEntry.Status = {
  STATUS_UNSPECIFIED: 0,
  READY: 1,
  PROCESS: 2,
  SUCCEEDED: 3,
  FAILED: 4,
  MANUAL: 5
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.type.CompensationEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.type.CompensationEntry.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_id = 3;
 * @return {string}
 */
proto.type.CompensationEntry.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EcSite.SiteType site_type = 4;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.CompensationEntry.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PaymentMethod payment_method = 5;
 * @return {!proto.type.CompensationEntry.PaymentMethod}
 */
proto.type.CompensationEntry.prototype.getPaymentMethod = function() {
  return /** @type {!proto.type.CompensationEntry.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.type.CompensationEntry.PaymentMethod} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated CompensationClaim compensation_claims = 6;
 * @return {!Array<!proto.type.CompensationClaim>}
 */
proto.type.CompensationEntry.prototype.getCompensationClaimsList = function() {
  return /** @type{!Array<!proto.type.CompensationClaim>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_compensation_claim_pb.CompensationClaim, 6));
};


/**
 * @param {!Array<!proto.type.CompensationClaim>} value
 * @return {!proto.type.CompensationEntry} returns this
*/
proto.type.CompensationEntry.prototype.setCompensationClaimsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.CompensationClaim=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.CompensationClaim}
 */
proto.type.CompensationEntry.prototype.addCompensationClaims = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.CompensationClaim, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.clearCompensationClaimsList = function() {
  return this.setCompensationClaimsList([]);
};


/**
 * optional Status status = 7;
 * @return {!proto.type.CompensationEntry.Status}
 */
proto.type.CompensationEntry.prototype.getStatus = function() {
  return /** @type {!proto.type.CompensationEntry.Status} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.type.CompensationEntry.Status} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string staff_name = 8;
 * @return {string}
 */
proto.type.CompensationEntry.prototype.getStaffName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setStaffName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp compensation_execution_date_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.CompensationEntry.prototype.getCompensationExecutionDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.CompensationEntry} returns this
*/
proto.type.CompensationEntry.prototype.setCompensationExecutionDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.clearCompensationExecutionDateTime = function() {
  return this.setCompensationExecutionDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.CompensationEntry.prototype.hasCompensationExecutionDateTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.CompensationEntry.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.CompensationEntry} returns this
*/
proto.type.CompensationEntry.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.CompensationEntry.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string user_name = 11;
 * @return {string}
 */
proto.type.CompensationEntry.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.CompensationEntry} returns this
 */
proto.type.CompensationEntry.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


goog.object.extend(exports, proto.type);
